import React, { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useLocation,
  Navigate
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import useMediaQuery from '@mui/material/useMediaQuery';
import 'react-toastify/dist/ReactToastify.css';

/* pages */
import Menu from './components/Menu';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import CatalogPage from './pages/CatalogPage';
import ProductPage from './pages/ProductPage';
import CartPage from './pages/CartPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ShoppingPolicyPage from './pages/ShoppingPolicyPage';
import ReclamationPage from './pages/ReclamationPage';
import DeliveryPage from './pages/DeliveryPage';
import BlogsPage from './pages/BlogsPage';
import BlogPage from './pages/BlogPage';
import SearchPage from './pages/SearchPage';
import ScrollToTop from './components/ScrollToTop';
import AdminPage from './pages/AdminPage';
import AboutUsPage from './pages/AboutUsPage';
import ProtectedRoutes from './ProtectedRoutes';

function App() {

  const location = useLocation();
  const queries = useMediaQuery('(max-width: 428px)');
  // const getAccInfo = useSelector((state) => state.account);

  useEffect(() => {
    const hasSpecificClass = document.body.querySelector('.video-container') !== null;

    if (hasSpecificClass) {
      document.body.classList.add('body-has-video-container');
    } else {
      document.body.classList.remove('body-has-video-container');
    }
  }, [location]);

  return (
    <div>
      <div className="wrapper">
        <Routes>
          <Route exact path="/" element={<div className="home-page-holder"><Menu /><HomePage /><ScrollToTop /><Footer /></div>} />
          <Route exact path="/shop" element={<><Menu /><CatalogPage /><ScrollToTop /><Footer /></>} />
          <Route exact path="/product/:productId/:name" element={<><Menu /><ProductPage /><ScrollToTop /><Footer /></>} />
          <Route exact path="/cart" element={<><Menu /><CartPage /><ScrollToTop /><Footer /></>} />
          <Route exact path="/reset-password/:token" element={<><Menu /><ForgotPasswordPage /><ScrollToTop /><Footer /></>} />
          <Route exact path="/uslovi-kupovine" element={<><Menu /><ShoppingPolicyPage /><ScrollToTop /><Footer /></>} />
          <Route exact path="/povracaj-i-reklamacije" element={<><Menu /><ReclamationPage /><ScrollToTop /><Footer /></>} />
          <Route exact path="/isporuka" element={<><Menu /><DeliveryPage /><ScrollToTop /><Footer /></>} />
          <Route exact path="/blog" element={<><Menu /><BlogsPage /><ScrollToTop /><Footer /></>} />
          <Route exact path="/:blogId/:blogName" element={<><Menu /><BlogPage /><ScrollToTop /><Footer /></>} />
          <Route exact path="/pretraga" element={<><Menu /><SearchPage /><ScrollToTop /><Footer /></>} />
          <Route exact path="/o-nama" element={<><Menu /><AboutUsPage /><ScrollToTop /><Footer /></>} />
          <Route element={<ProtectedRoutes />}>
            <Route exact path="/panel" element={<AdminPage />} />
          </Route>
          <Route path="*" element={<Navigate to="/"></Navigate>}></Route>
        </Routes>

      </div>
      <ToastContainer autoClose={1000} position={queries ? "bottom-right" : "top-right"} />
    </div>
  );
}

export default App;
