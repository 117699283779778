import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft, faCirclePlay } from '@fortawesome/free-solid-svg-icons'

import CatalogProduct from './CatalogProduct';

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <FontAwesomeIcon
            className={className}
            icon={faAngleRight}
            style={{ ...style, padding: "5px", borderRadius: '50%', backgroundColor: '#F16F32', right: '-25px', color: "#fff" }}
            onClick={onClick}
        />
    );
};

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <FontAwesomeIcon
            className={className}
            icon={faAngleLeft}
            style={{ ...style, padding: "5px", borderRadius: '50%', backgroundColor: '#F16F32', left: '-25px', color: "#fff", zIndex: 1 }}
            onClick={onClick}
        />
    );
};

const ProductSimilarSlider = ({ getProductInfo, smallerSlider, name }) => {

    let responsive = []

    if (smallerSlider) {
        responsive = [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    } else {
        responsive = [
            {
                breakpoint: 1681,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1281,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 431,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    }

    const settings = {
        dotsClass: false,
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: smallerSlider ? 4 : 5,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: responsive,
        // centerMode: true
    };
    return (
        <div className="col-sm-12">
            <div className="similar-products-slider">
                <h4>{name}</h4>
                <Slider
                    {...settings}
                >
                    {getProductInfo.map((item, key) => (
                        <div className="similar-products-slide" key={key}>
                            <CatalogProduct item={item} key={key} />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export default ProductSimilarSlider