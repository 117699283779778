import React, { useState } from 'react'

const QtyInput = ({ functionSetQty }) => {

    const handleFocus = (event) => event.target.select();

    const [qtyVal, setQtyVal] = useState(1);

    const setQtyFunction = (targetQty) => {
        if (parseInt(targetQty) < 1) {
            setQtyVal(1);
            functionSetQty(1)
        } else if (targetQty == "") {
            setQtyVal(1);
            functionSetQty(1)
        } else {
            setQtyVal(targetQty);
            functionSetQty(targetQty);
        }
    };
    return (
        <input type="number" className="input-qty-add-item" value={qtyVal} onChange={(e) => setQtyFunction(e.target.value)} onFocus={handleFocus} />
    )
}

export default QtyInput