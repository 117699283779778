import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { getCategories } from '../redux/backCalls'

const Categories = ({ checked, setChecked, filters, setFilters, setCurrentPage, isOnSale, setIsOnSale, query }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

    const getShopCategories = useSelector((state) => state.category);

    const onChangeFunction = (event, categoryId) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            setChecked((prevChecked) => [...prevChecked, categoryId])
            searchParams.append('kategorija', categoryId);
        } else {
            setChecked((prevValues) => prevValues.filter((value) => value !== categoryId));
            if (searchParams.has("kategorija", categoryId)) {
                searchParams.delete('kategorija', categoryId)
            }
        }

        searchParams.delete('str');
        setCurrentPage(0)
        navigate({ search: `?${searchParams.toString()}` })
    }

    const onFiltersChange = (event, val) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            searchParams.set('naStanju', val);
            setFilters(val);

        } else {
            searchParams.delete('naStanju');
            setFilters(-1);

        }
        searchParams.delete('str');
        setCurrentPage(0)
        navigate({ search: `?${searchParams.toString()}` })
    }

    const onActionChange = (event) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            searchParams.set('naAkciji', true);
            setIsOnSale(true)
        } else {
            searchParams.delete('naAkciji');
            setIsOnSale(false)
        }
        searchParams.delete('str');
        setCurrentPage(0)
        navigate({ search: `?${searchParams.toString()}` })
    }

    useEffect(() => {
        dispatch(getCategories())
    }, [])

    return (
        <div className={`${query ? "col-3" : "col-xl-2"} chekboxes-container`}>
            <div className="categories-container">
                <span className="filters-header">KATEGORIJE</span>
                <div className="filters">
                    {getShopCategories.map((item, key) => (
                        <div className="category-container" key={key}>
                            <div className="checkbox-holder">
                                <input type='checkbox' onChange={(e) => onChangeFunction(e, item.categoryId)} checked={searchParams.has("kategorija", item.categoryId) ? 'checked' : ''} />
                                <span>{item.name}</span>
                            </div>
                            <span>{item.size}</span>
                        </div>
                    ))}
                </div>
                <span className="filters-header">FILTERI</span>
                <div className="filters">
                    <div className="category-container">
                        <div className="checkbox-holder">
                            <input type='checkbox' onChange={(e) => onFiltersChange(e, 1)} checked={filters == 1 ? 'checked' : ''} />
                            <span>Na stanju</span>
                        </div>
                    </div>
                    <div className="category-container">
                        <div className="checkbox-holder">
                            <input type='checkbox' onChange={(e) => onFiltersChange(e, 0)} checked={filters == 0 ? 'checked' : ''} />
                            <span>Nema na stanju</span>
                        </div>
                    </div>
                </div>
                <span className="filters-header">AKCIJA</span>
                <div className="filters">
                    <div className="category-container">
                        <div className="checkbox-holder">
                            <input type='checkbox' onChange={(e) => onActionChange(e)} checked={isOnSale ? 'checked' : ''} />
                            <span>Na akciji</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Categories