import { combineReducers } from "redux";

import {
    account,
    isLogged,
    categories,
    catalog,
    catalogPages,
    catalogTotal,
    product,
    cart,
    homePageNewProducts,
    homePageBestSeller,
    homePageLocalProducts,
    homePageHideNav,
    homePageVideo,
    homePageBannerSlider,
    homePageBlogs,
    blogs,
    searchResult,
    searchQuery,
    shipping,
    loyalty,
    onlinePayment,
    loyaltyUsers,
    adminCategories,
    adminProducts,
    adminBlogs,
    adminHomePageSlider,
} from './slices'

const reducers = combineReducers({
    account: account.reducer,
    isLogged: isLogged.reducer,
    catalog: catalog.reducer,
    pages: catalogPages.reducer,
    catalogSize: catalogTotal.reducer,
    category: categories.reducer,
    productInfo: product.reducer,
    cartInfo: cart.reducer,
    homePageNewProducts: homePageNewProducts.reducer,
    homePageBestSeller: homePageBestSeller.reducer,
    homePageLocalProducts: homePageLocalProducts.reducer,
    homePageHideNav: homePageHideNav.reducer,
    homePageBannerSlider: homePageBannerSlider.reducer,
    homePageBlogs: homePageBlogs.reducer,
    blogs: blogs.reducer,
    searchResult: searchResult.reducer,
    searchQuery: searchQuery.reducer,
    shipping: shipping.reducer,
    loyalty: loyalty.reducer,
    onlinePayment: onlinePayment.reducer,
    loyaltyUsers: loyaltyUsers.reducer,
    adminCategories: adminCategories.reducer,
    adminProducts: adminProducts.reducer,
    adminBlogs: adminBlogs.reducer,
    adminHomePageSlider: adminHomePageSlider.reducer,
})

const rootReducer = (state, action) => {
    return reducers(state, action);
}

export default rootReducer;