import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import Helmet from 'react-helmet'

import { getHomePage } from '../redux/backCalls';
import { homePageHideNav } from '../redux/slices';

import { removeSpecialChar } from '../helperFunctions';

import ProductSimilarSlider from '../components/ProductSimilarSlider';
import HomePageBanner from '../components/HomePageBanner';

const HomePage = () => {

    const dispatch = useDispatch();
    const getHomePageSliderNew = useSelector((state) => state.homePageNewProducts);
    const getHomePageSliderBest = useSelector((state) => state.homePageBestSeller);
    const getHomePageSliderLocal = useSelector((state) => state.homePageLocalProducts);
    const getHomePageBannerSlider = useSelector((state) => state.homePageBannerSlider);
    const getHomePageBlogs = useSelector((state) => state.homePageBlogs);
    const getHomeHideNav = useSelector((state) => state.homePageHideNav);

    useEffect(() => {
        dispatch(getHomePage())
        dispatch(homePageHideNav.actions.homePageHideNav(false));
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;

            if(scrollY > 0 && !getHomeHideNav) {
                dispatch(homePageHideNav.actions.homePageHideNav(true));
            } else if(scrollY === 0) {
                dispatch(homePageHideNav.actions.homePageHideNav(false));
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [getHomeHideNav]);

    return (
        <div className="container-fluid video-container">
            <Helmet>
                <title>Drustvene Igre</title>
                <meta name='description'
                    content='Dobrodosli na epicentar zabave. 
                    Otkrijte beskrajne mogućnosti igre kroz našu široku paletu igara za sve generacije i ukuse. Bez obzira jeste li novi u svetu drustvenih igara ili iskusni igrac, ovo je mesto gde se susreću strast i avantura.
                    Neka nasa stranica postane vaša virtuelna destinacija za sve što je vezano za društvene igre. Pruzite svojim slobodnim trenucima novu dimenziju - dozivite radost igre na način koji nikada pre niste iskusili.' ></meta>
                <meta name='keywords' content='small brother board games drustvene igre social'></meta>
                <meta name='robots' content='index, follow'></meta>
            </Helmet>
            <div className="row mb-3">
                <div className="col-sm-12">
                    <video
                        width="100%"
                        autoPlay={true}
                        loop={true}
                        controls={false}
                        playsInline
                        muted
                    >
                        <source src="https://smallbrother.rs/resource/homevideo.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            <div className="home-contaner">
                <div className="row heading-container">
                    <h1 className="text-center my-5">NAJNOVIJE</h1>
                </div>
                {getHomePageSliderNew.length > 0 ?
                    <div className="row home-page-slider">
                        <ProductSimilarSlider getProductInfo={getHomePageSliderNew} />
                    </div>
                    :
                    null
                }
                <div className="row mt-lg-5">
                    {getHomePageBannerSlider.length > 0 ?
                        <HomePageBanner items={getHomePageBannerSlider} />
                        :
                        null
                    }
                    {getHomePageBlogs.length > 0 ?
                        <div className="col-lg-4 p-3">
                            <div className="blogs-holder">
                                <h3>Najnovije vesti</h3>
                                {getHomePageBlogs.map((i, k) => (
                                    <div className="blog-item" key={k}>
                                        <div className="blog-time">
                                            <span>
                                                <Moment format="DD.MM.">
                                                    {i.createdAt}
                                                </Moment>
                                            </span>
                                        </div>
                                        <div className="blog-content ms-3">
                                            <Link to={`/${i.blogId}/${removeSpecialChar(i.name)}`}>
                                                <h4>{i.name}</h4>
                                            </Link>
                                            <div className="blog-text" dangerouslySetInnerHTML={{ __html: i.text }} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                <div className="row heading-container mt-lg-5">
                    <h1 className="text-center my-5">NAJPRODAVANIJE</h1>
                </div>
                {getHomePageSliderBest.length > 0 ?
                    <div className="row home-page-slider">
                        <ProductSimilarSlider getProductInfo={getHomePageSliderBest} />
                    </div>
                    :
                    null
                }
                <div className="row heading-container mt-lg-5">
                    <h1 className="text-center my-5">LOKALIZACIJE</h1>
                </div>
                {getHomePageSliderLocal.length > 0 ?
                    <div className="row home-page-slider">
                        <ProductSimilarSlider getProductInfo={getHomePageSliderLocal} />
                    </div>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default HomePage