import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet'

import { getBlogs } from '../redux/backCalls'

import { removeSpecialChar } from '../helperFunctions';

const BlogsPage = () => {

    const dispatch = useDispatch();
    const getBlogsItems = useSelector((state) => state.blogs);

    useEffect(() => {
        dispatch(getBlogs())
    }, [])

    return (
        <div className="main-contaner blogs-main">
            <Helmet>
                <title>Blog</title>
                <meta name='description' content='Dobrodošli u naš blog o društvenim igrama! Pronađite najnovije vesti, recenzije, i savete o najpopularnijim društvenim igrama. 
                Naš tim zaljubljenika u igre redovno deli uzbudljive informacije o najnovijim trendovima, strategijama igranja i osvrtima na najnovije igre na tržištu.'></meta>
                <meta name='keywords' content='blog vesti news informacije info small brother novosti'></meta>
                <meta name="robots" content="index, follow"></meta>
            </Helmet>
            <div className="blogs-page-banner">
                <div className="container">
                    <h1>Blog</h1>
                    <p>Dobrodošli u naš svet društvenih igara!</p>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    {getBlogsItems.length > 0 ?
                        <>
                            {getBlogsItems.map((i, k) =>
                                <div className="col-sm-6" key={k}>
                                    <div className="blog-container my-3">
                                        {i.image != "" &&
                                            <Link to={`/${i.blogId}/${removeSpecialChar(i.name)}`}>
                                                <img src={i.image} alt='Small brother blog image' className="img-fluid blog-image" />
                                            </Link>
                                        }
                                        <div className="blog-info">
                                            <Link to={`/${i.blogId}/${removeSpecialChar(i.name)}`}>
                                                <h5>{i.name}</h5>
                                            </Link>
                                            <span dangerouslySetInnerHTML={{ __html: i.text }} />
                                            <Link to={`/${i.blogId}/${removeSpecialChar(i.name)}`}>
                                                <span className='read-more-cta'>Saznaj više</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            )}
                        </>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    )
}

export default BlogsPage