import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { getLoyaltyUsers, getAdminCategories, getAdminProducts, getAdminBlogs } from '../redux/backCalls';

import AdminLoyalty from '../components/AdminLoyalty';
import AdminCategories from '../components/AdminCategories';
import AdminProducts from '../components/AdminProducts';
import AdminBlogs from '../components/AdminBlogs';
import AdminHomePagePic from '../components/AdminHomePagePic';
import AdminVideoModal from '../components/AdminVideoModal';
import { Helmet } from 'react-helmet';

const AdminPage = () => {

    const dispatch = useDispatch()
    const [tab, setTab] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [showModal, setShowModal] = useState(false);

    // let userId = localStorage.getItem('ahjgfff')
    const getAccInfo = useSelector((state) => state.account);

    const searchTable = (val) => {
        tab == 1 && dispatch(getLoyaltyUsers(val))
        tab == 2 && dispatch(getAdminCategories(val))
        tab == 3 && dispatch(getAdminProducts(val))
        tab == 4 && dispatch(getAdminBlogs(val))
        setSearchValue(val)
    }

    const uploadVideo = () => {
        setShowModal(!showModal);
    }

    useEffect(() => {
        setSearchValue("")
    }, [tab])

    return (
        <>
            <div className="container p-5">
                <Helmet>
                    <title>Admin</title>
                </Helmet>
                <div className="row text-center">
                    <button className="btn btn-dark col mx-2" style={{ maxWidth: "fit-content" }} onClick={() => setTab(1)}>Loyalty poeni</button>
                    {getAccInfo.accountId == 1 &&
                        <>
                            <button className="btn btn-dark col mx-2" onClick={() => setTab(2)}>Kategorije</button>
                            <button className="btn btn-dark col mx-2" onClick={() => setTab(3)}>Proizvodi</button>
                            <button className="btn btn-dark col mx-2" onClick={() => setTab(4)}>Blog</button>
                            <button className="btn btn-dark col mx-2" onClick={() => setTab(5)}>Homepage baner slike</button>
                            <button className="btn btn-dark col mx-2" onClick={() => uploadVideo()}>Homepage video</button>
                        </>
                    }
                </div>
                {tab != 5 &&
                    <div className="row mt-5">
                        <div className="col-12">
                            <input type='text' className="search-page-box" autoComplete="off" placeholder='Pretraga...' value={searchValue} onChange={(e) => searchTable(e.target.value)} />
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-12 table-responsive">
                        {tab == 1 &&
                            <AdminLoyalty />
                        }
                        {tab == 2 &&
                            <AdminCategories />
                        }
                        {tab == 3 &&
                            <AdminProducts />
                        }
                        {tab == 4 &&
                            <AdminBlogs />
                        }
                        {tab == 5 &&
                            <AdminHomePagePic />
                        }
                    </div>
                </div>
            </div>
            {showModal &&
                <AdminVideoModal showModal={showModal} handleCloseModal={uploadVideo} />
            }
        </>
    )
}

export default AdminPage