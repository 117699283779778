import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className="footer-container">
            <div className='footer-wrapper'>
                <Link to={"/"}>
                    <img src="/logo-dark.png" alt="Small Brother Games" className='img-fluid' />
                </Link>
                <div className='footer-main'>
                    <div className='footer-info'>
                        <h6>Small Brother D.O.O.</h6>
                        <span>MB: 21873284</span>
                        <span>PIB: 11347242</span>
                    </div>
                    <div className='footer-nav'>
                        <h6>Stranice</h6>
                        <ul>
                            <li>
                                <Link to={"/povracaj-i-reklamacije"}>Povraćaj sredstava i reklamacije</Link>
                            </li>
                            <li>
                                <Link to={"/uslovi-kupovine"}>Uslovi kupovine</Link>
                            </li>
                            <li>
                                <Link to={"/isporuka"}>Isporuka</Link>
                            </li>
                        </ul>
                    </div>
                    <div className='footer-contact'>
                        <h6>Kontakt</h6>
                        <span>Adresa: Prizrenska 4a, 11000 Beograd, Srbija</span>
                        <Link to={"mailto:info@smallbrother.rs"} className='footer-email'><p>E-mail: <span>info@smallbrother.rs</span></p></Link>
                        <Link to={"tel:+381113428260"} className='footer-phone'><p>Telefon: <span>011 / 34 28 260</span></p></Link>
                    </div>
                </div>
                <div className='footer-socials'>
                    <h6>Zapratite nas</h6>
                    <ul>
                        <li>
                            <Link to={"https://www.youtube.com/@SmallBrotherGames"}>
                                <img src="/youtube.svg" alt="youtube icon" className='img-fluid' />
                            </Link>
                        </li>
                        <li>
                            <Link to={"https://www.facebook.com/smallbrothergames"}>
                                <img src="/facebook.svg" alt="facebook icon" className='img-fluid' />
                            </Link>
                        </li>
                        <li>
                            <Link to={"https://www.instagram.com/small_brother_games/"}>
                                <img src="/instagram.svg" alt="instagram icon" className='img-fluid' />
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='footer-copy row'>
                <small className='col-sm-6 mb-2 mb-sm-0'>© Small Brother Games. Sva prava zadržana.</small>
                <div className='col-sm-6 bank-branding-holder'>
                    <div className='bank-branding mx-1'>
                        <img src='/Visa.jpg' alt='Visa' title='Visa' loading='lazy' />
                    </div>
                    <div className='bank-branding mx-1'>
                        <img src='/MasterCard.png' alt='MasterCard' title='MasterCard' loading='lazy' />
                    </div>
                    <div className='bank-branding mx-1'>
                        <img src='/Maestro.png' alt='Maestro' title='Maestro' loading='lazy' />
                    </div>
                    <div className='bank-branding mx-1'>
                        <img src='/DinaCard.jpg' alt='DinaCard' title='DinaCard' loading='lazy' />
                    </div>
                    <div className='bank-branding mx-1'>
                        <a href='https://www.raiffeisenbank.rs/' target="_blank">
                            <img src='/RaiffeisenBank.png' alt='Raiffeisen Bank' title='Raiffeisen Bank' loading='lazy' />
                        </a>
                    </div>
                    <div className='bank-branding mx-1'>
                        <a href='https://rs.visa.com/run-your-business/small-business-tools/payment-technology/visa-secure.html' target="_blank">
                            <img src='/visa-secure.jpg' alt='Visa Secure' title='Visa Secure' loading='lazy' />
                        </a>
                    </div>
                    <div className='bank-branding mx-1'>
                        <a href='https://www.mastercard.rs/sr-rs/korisnici/podrska/sigurnost-i-zastita/identity-check.html' target="_blank">
                            <img src='/mc-identity.png' alt='MasterCard ID Check' title='MasterCard ID Check' loading='lazy' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer