import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';

const ReclamationPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="container">
            <Helmet>
                <title>Reklamacije</title>
                <meta name='description' content='Vaša zadovoljstvo nam je od suštinskog značaja! Ako imate bilo kakve nedoumice, pitanja ili želite izraziti nezadovoljstvo, došli ste na pravo mesto.
                Naša stranica za reklamacije posvećena je rešavanju vaših problema na brz i efikasan način.'></meta>
            </Helmet>
            <div className="row my-4">
                <h1>Povracaj sredstava i reklamacije</h1>
            </div>
            <div className="row">
                <p className="fw-bold">
                    Povraćaj robe i sredstava
                </p>
                <p>
                    U skladu sa Zakonom o zaštiti potrošača, potrošač ima pravo da bez navođenja razloga
                    odustane od ugovora u roku od 14 dana od dana kada Vam je roba isporučena.
                    Odustankom od ugovora oslobađate se svih obaveza osim obaveze plaćanja troškova
                    vezanih za slanje robe koja se vraća usled odustanka od ugovora. Vaša izjava o
                    odustanku od ugovora proizvodi pravno dejstvo od dana kada ste nam je poslali.
                </p>
                <ol className="ms-5">
                    <li style={{ listStyleType: 'auto' }}>Na našu e-mail adresu info@smallbrother.rs možete dostaviti Vašu izjavu o
                        odustanku, ili popuniti i poslati na adresu Prizrenska 4a, 11000 Beograd – naglasiti za
                        internet prodaju. Obrazac za odustanak od ugovora, koji je stigao uz paket možete
                        preuzeti ovde (dodati hiperlink).
                    </li>
                    <li style={{ listStyleType: 'auto' }}>
                        Nakon što primimo Vaš e-mail, prosledićemo Vam Dokumenta za povraćaj robe na
                        Vaš e-mail, što predstavlja potvrdu o prijemu Vaše izjave o odustanku od ugovora.
                    </li>
                    <li style={{ listStyleType: 'auto' }}>
                        Dostavljena dokumenta je potrebno da odštampate, iste popunite, obavezno
                        potpišete (potpišete i pečatirate za pravna lica) i zajedno sa robom i fiskalnim računom,
                        pošaljete na adresu koju Vam Operater iz ONLINE prodavnice bude poslao na Vaš e-
                        mail.
                    </li>
                    <li style={{ listStyleType: 'auto' }}>
                        Paket koji sadrži robu za povraćaj je potrebno čvrsto upakovati i oblepiti
                        samolepljivom trakom.
                    </li>
                    <li style={{ listStyleType: 'auto' }}>
                        Proizvodi koji se vraćaju usled odustanka od ugovora moraju biti nekorišćeni,
                        neoštećeni i zapakovani u originalnu ambalažu (originalnom ambalažom se smatra i
                        streč folija u koju su mnoge društvene igre fabrički zapakovane). Kao kupac ne snosite
                        troškove koji nastaju odustankom od ugovora, osim troškova poštarine koji proisteknu
                        slanjem robe na našu adresu. Sa naše strane se obavezujemo da ćemo, odmah po
                        prijemu robe koja se vraća po osnovu odustanka od ugovora, izvršiti povraćaj sredstava
                        koja su uplaćena za vraćenu robu. Da bi Vam izvršili povraćaj sredstava nepohodno je
                        da roba za koju se vrši povraćaj novca, kao i popunjena i potpisana Dokumenta za
                        povraćaj robe, budu dostavljena na adresu koju Vam operater iz ONLINE prodavnice
                        pošalje na Vaš e-mail. Nakon potvrde o prijemu, sredstva će biti uplaćena na račun koji
                        ste naveli u obrazcu u roku od 10 radna dana. Za ispravnost podataka unetih u
                        Dokumenta za povraćaj robe, garantujete svojim potpisom, tj.pečatom.
                    </li>
                    <li style={{ listStyleType: 'auto' }}>
                        U slučaju vraćanja robe i povraćaja sredstava kupcu koje je prethodno platio platnom
                        karticom (trgovac) je u obavezi da povraćaj vrši isključivo preko Banke odnosno preko
                        kartičnih asocijacija Visa i MasterCard i to storniranjem/delimičnim storniranjem
                        orginalne trasnakcije, banka će na zahtev prodavca obaviti povraćaj sredstava na račun
                        korisnika kartice.
                    </li>
                    <li style={{ listStyleType: 'auto' }}>
                        Troškove vraćanja robe i novca snosi kupac, sem u slučajevima kada kupac dobije
                        neispravan ili pogrešan artikal.
                    </li>
                </ol>
                <p>
                    Ukoliko ste nakon prijema artikla ustanovili da želite da ga zamenite za drugi artikal niže
                    ili više cenovne kategorije, molimo Vas da nas kontaktirate pozivanjem broja telefona
                    011/3428260 ili nam pošaljite e-mail na adresu info@smallbrother.rs. Od informacija je
                    potrebno dostaviti Vaš broj računa/otpremnice, šifru artikla za koji želite da zamenite
                    prvobitno poručeni artikal, kao i Vaš JMBG (opciono) i Vaš broj dinarskog tekućeg
                    računa na koji bismo uplatili eventualnu razliku novca cenovnih kategorija.
                </p>
                <p className="fw-bold mt-5">
                    Reklamacije
                </p>
                <p>
                    Prilikom preuzimanja pošiljke molimo Vas da u prisustvu kurira proverite svoj paket.
                    Ukoliko na njemu ima vidljivih oštećenja (pocepani delovi i ugnječenje) paket ne bi
                    trebalo da preuzmete. U ovom slučaju molimo Vas da nas pozovite telefonom na broj
                    011/3428260 ili nam pošaljite e-mail sa svojim podacima (ime, prezime, telefon) na
                    adresu info@smallbrother.rs i navedete razlog zbog koga ste odbili da preuzmete paket.
                    U najkraćem mogućem roku obavestićemo Vas o daljem postupanju.
                </p>
                <p>
                    Ukoliko ste primili pošiljku i nakon otvaranja kutije ustanovili da isporučena roba ne
                    odgovara naručenoj ili podaci na računu nisu odgovarajući, molimo Vas da nas,
                    najkasnije u roku od 24h od trenutka prijema pošiljke, pozovite telefonom na broj
                    011/3428260, ili pošaljete e-mail sa svojim podacima (ime, prezime, telefon) na adresu
                    info@smallbrother.rs i opišete kakav problem imate. U najkraćem mogućem roku
                    obavestićemo Vas o daljem postupanju.
                </p>
                <p>
                    Ukoliko se na kupljenom proizvodu pojave neusaglašenosti u smislu odredbi Zakona o
                    zaštiti potrošača, molimo Vas da postupite na način definisan pravilnikom o reklamaciji
                    potrošača za robu kupljenu putem internet prodavnice, koji zajedno sa obrascem
                    zahteva za reklamaciju, treba da pošaljete na adresu Prizrenska 4a, 11000 Beograd.
                </p>
                <p>
                    Podaci o firmi:
                </p>
                <p>
                    Small Brother d.o.o.
                </p>
                <p>
                    Prizrenska 4a. 11000 Beograd
                </p>
                <p>
                    Telefon: 011/3428260
                </p>
                <p>
                    Broj računa: 265-1110310006778-35<br />
                    Matični broj 21873284 PIB 113472423
                </p>
                <p>
                    Šifra delatnosti 4690, nespecijalizovana trgovina na veliko
                </p>
                <p>
                    E-mail: info@smallbrother.rs
                </p>
            </div>
        </div>
    )
}

export default ReclamationPage