export const getCookie = (cookieName) => {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(cookieName + '=')) {
            return cookie.substring(cookieName.length + 1);
        }
    }
    return null;
}

export const removeSpecialChar = (val) => {

    let result = ""
    const map = {
        "Ž": "Z",
        "ž": "z",
        "Đ": "DJ",
        "đ": "dj",
        "Š": "S",
        "š": "s",
        "Ć": "C",
        "ć": "c",
        "Č": "C",
        "č": "c",
    }

    result = (val.replace(/[ŽĐŠĆČžđščć]/g, function (match) {
        return map[match];
    }));

    return result.replace(/[^a-zA-Z0-9]/g, "-").replace(/_{2,}/g, '-')
}

export const removeLastCharacterFromKeys = (obj) => {
    const newObj = {};
    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            newObj[key.slice(0, -1)] = obj[key];
        }
    }
    return newObj;
};

export const currencyFormat = (num) => {
    return num == null ? 0 : num.toFixed(0).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};