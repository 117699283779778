import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';

import { getAccountInfo } from './redux/backCalls';

const ProtectedRoutes = () => {

    const dispatch = useDispatch()
    const [auth, setAuth] = useState(false)
    const getAccInfo = useSelector((state) => state.account);

    useEffect(() => {
        if(Object.keys(getAccInfo).length > 0) {
            if(getAccInfo.accountId == 1 || getAccInfo.accountId == 2) {
                setAuth(true)
            } else {
                setAuth(false)
            }
        } else {
            dispatch(getAccountInfo())
        }
    }, [getAccInfo])

    return auth ? <Outlet /> : <div className="alert alert-danger" role="alert">Zabranjen pristup, molimo ulogujte se kao admin!</div>
}

export default ProtectedRoutes