import React, { useEffect } from 'react'
import FormValidation from './FormValidation';

const CartUserData = ({ values, setValues, validInputValue, openMore, setOpenMore, setErrorsFunction, setErrorsMoreFunction, delivery }) => {

    const onChangeFunction = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
        setErrorsFunction(e.target.name, 0)
    }

    const toggle = (e) => {
        setValues({ ...values, [e.target.name]: e.target.checked });
        setOpenMore(!openMore)
    }

    useEffect(() => {
        if (values["taxNumber"] == "") {
            setOpenMore(false)
        }
    }, [delivery])

    return (
        <div className="row d-flex justify-content-center">
            <form className="col-sm-6" noValidate>
                <FormValidation
                    name='name'
                    type='text'
                    label="Ime:"
                    value={values['name']}
                    onChange={(e) => onChangeFunction(e)}
                    validInputValue={validInputValue.name}
                    // errorMessage="*Obavezno polje!"
                    setErrorsFunction={setErrorsFunction}
                />
                <FormValidation
                    name='surname'
                    type='text'
                    label="Prezime:"
                    value={values['surname']}
                    onChange={(e) => onChangeFunction(e)}
                    validInputValue={validInputValue.surname}
                    // errorMessage="*Obavezno polje!"
                    setErrorsFunction={setErrorsFunction}
                />
                {delivery == "home" &&
                    <>
                        <FormValidation
                            name='address'
                            type='text'
                            label="Ulica i broj:"
                            value={values['address']}
                            onChange={(e) => onChangeFunction(e)}
                            validInputValue={validInputValue.address}
                            // errorMessage="*Obavezno polje!"
                            setErrorsFunction={setErrorsFunction}
                        />
                        <div className="row">
                            <div className="col-sm-7">
                                <FormValidation
                                    name='city'
                                    type='text'
                                    label="Grad:"
                                    value={values['city']}
                                    onChange={(e) => onChangeFunction(e)}
                                    validInputValue={validInputValue.city}
                                    // errorMessage="*Obavezno polje!"
                                    setErrorsFunction={setErrorsFunction}
                                />
                            </div>
                            <div className="col-sm-5">
                                <FormValidation
                                    name='postCode'
                                    type='number'
                                    label="Poštanski broj:"
                                    value={values['postCode']}
                                    onChange={(e) => onChangeFunction(e)}
                                    validInputValue={validInputValue.postCode}
                                    // errorMessage="*Obavezno polje!"
                                    setErrorsFunction={setErrorsFunction}
                                />
                            </div>
                        </div>
                    </>
                }
                <FormValidation
                    name='phoneNumber'
                    type='number'
                    label="Telefon:"
                    value={values['phoneNumber']}
                    onChange={(e) => onChangeFunction(e)}
                    validInputValue={validInputValue.phoneNumber}
                    // errorMessage="*Obavezno polje!"
                    setErrorsFunction={setErrorsFunction}
                />
                <FormValidation
                    name='email'
                    type='email'
                    label="E-mail adresa:"
                    value={values['email']}
                    onChange={(e) => onChangeFunction(e)}
                    validInputValue={validInputValue.email}
                    // errorMessage="*E-mail adresa nije validna!"
                    setErrorsFunction={setErrorsFunction}
                />
                <div className="p-2">
                    <input type='checkbox' name="isLegalEntity" onChange={(e) => toggle(e)} checked={openMore ? true : false} /> Pravno lice?
                </div>
                {openMore &&
                    <FormValidation
                        name='taxNumber'
                        type='number'
                        label="PIB firme:"
                        value={values['taxNumber']}
                        onChange={(e) => onChangeFunction(e)}
                        validInputValue={validInputValue.taxNumber}
                        // errorMessage="*Potrebno je uneti tačno 9 cifara!"
                        setErrorsFunction={setErrorsMoreFunction}
                    />
                }
            </form>
        </div>
    )
}

export default CartUserData