import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { getHomePagePictures } from '../redux/backCalls';

import AdminPicModal from './AdminPicModal';

const AdminHomePagePic = () => {

    const dispatch = useDispatch()
    const getAdminPic = useSelector((state) => state.adminHomePageSlider);

    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState("edit");
    const [pic, setPic] = useState();

    const handleCloseModal = (val, cont, id) => {
        setModalContent(cont)
        if (val == true && cont == "delete") {
            setPic(id)
            setShowModal(true);
        } else {
            setShowModal(val)
            setPic()
        }
    };

    useEffect(() => {
        dispatch(getHomePagePictures())
    }, [])

    return (
        <>
            <div className="d-flex justify-content-end m-1 mt-5">
                <button className='btn btn-sm btn-dark' onClick={() => handleCloseModal(true, "create")}>Kreiraj</button>
            </div>
            {getAdminPic.length > 0 ?
                <table className="table table-hover mt-4">
                    <thead>
                        <tr>
                            <th scope="col">Naziv slike</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {getAdminPic.map((i, k) => {
                            const imgPart = i.image.split('/');
                            const imageName = imgPart[imgPart.length - 1];
                            return <tr key={k}>
                                <td>{imageName}</td>
                                <td>
                                    <button className='btn btn-sm btn-dark' onClick={() => handleCloseModal(true, "delete", i.homePictureId)}>Obriši</button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                :
                null
            }
            {showModal &&
                <AdminPicModal showModal={showModal} modalContent={modalContent} handleCloseModal={handleCloseModal} pic={pic} />
            }
        </>
    )
}

export default AdminHomePagePic