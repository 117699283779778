import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'

import { addToCart, removeFromCart } from '../redux/backCalls';

const CartItem = ({ item }) => {
    const dispatch = useDispatch()
    const getShipping = useSelector((state) => state.shipping);
    const getLoyaltyOption = useSelector((state) => state.loyalty);
    const getOnlinePayment = useSelector((state) => state.onlinePayment);

    const addQty = () => {
        dispatch(addToCart(item.productId, 1, getShipping, getLoyaltyOption, getOnlinePayment))
    }

    const subQty = (qty) => {
        dispatch(removeFromCart(item.productId, qty ? qty : 1, getShipping, getLoyaltyOption, getOnlinePayment))
    }

    return (
        <div className="row">
            <div className="col-sm-12 cart-item">
                <div className="cart-img-holder col-6">
                    <img src={item.images} loading='lazy' alt={item.name + 'box cover'} className="cart-img img-fluid" />
                    <div className="cart-item-info">
                        <span className="cart-item-name">{item.name}</span>
                        <div className="cart-item-price">
                            <div>
                                <span className="qty-times">{item.quantity} x </span>
                                <span className="cart-price">{item.isOnSale ? item.salePrice : item.price} RSD</span>
                            </div>
                            {item.isOnSale &&
                                <small>{item.price} RSD</small>
                            }
                        </div>
                    </div>
                </div>
                <div className="cart-price-holder col-6">
                    <div className="cart-qty">
                        <button className="minus-item-qty qty-button" onClick={(e) => subQty()}>-</button>
                        <input type="number" className="input-qty-add-item" value={item.quantity} disabled={true} />
                        <button className="plus-item-qty qty-button" onClick={(e) => addQty()}>+</button>
                    </div>
                    <FontAwesomeIcon icon={faTrashCan} onClick={() => subQty(item.quantity)} className="cart-delete-item" />
                </div>
            </div>
        </div>
    )
}

export default CartItem