import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { getLoyaltyUsers, changeLoyaltyUsers } from '../redux/backCalls';

const AdminLoyalty = () => {

    const dispatch = useDispatch()
    const getLoyaltyInfo = useSelector((state) => state.loyaltyUsers);

    const [points, setPoints] = useState({});

    useEffect(() => {
        dispatch(getLoyaltyUsers())
    }, [])

    const handlePointsChange = (e, email) => {
        setPoints((prevPoints) => ({
            ...prevPoints,
            [email]: e.target.value,
        }));
    };

    const sendData = (email) => {
        dispatch(changeLoyaltyUsers(email, points[email]))
        setPoints({})
    }

    return (
        <>
            {getLoyaltyInfo.length > 0 ?
                <table className="table table-hover mt-4">
                    <thead>
                        <tr>
                            <th scope="col">E-mail</th>
                            <th scope="col">Poeni</th>
                            <th scope="col">Promeni poene</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getLoyaltyInfo.map((i, k) => (
                            <tr key={k}>
                                <td>{i.email}</td>
                                <td>{i.loyaltyPoints}</td>
                                <td>
                                    <input type='text' className="table-input me-4" value={points[i.email] || ''} onChange={(e) => handlePointsChange(e, i.email)} />
                                    <button className='btn btn-sm btn-dark' onClick={() => sendData(i.email)}>Promeni</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                :
                null
            }
        </>
    )
}

export default AdminLoyalty
