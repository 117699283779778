import axios from 'axios';
import {
    account,
    isLogged,
    categories,
    catalog,
    catalogPages,
    catalogTotal,
    product,
    cart,
    homePageNewProducts,
    homePageBannerSlider,
    homePageBlogs,
    homePageBestSeller,
    homePageLocalProducts,
    blogs,
    searchResult,
    loyaltyUsers,
    adminCategories,
    adminProducts,
    adminBlogs,
    adminHomePageSlider,
} from './slices'
import { toast } from 'react-toastify';

import MessageInfoBox from '../components/MessageInfoBox';

axios.defaults.withCredentials = true;

export const getSession = () => async (dispatch) => {
    try {
        axios.get('https://api.smallbrother.rs/session')
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const getAccountInfo = () => async (dispatch) => {
    try {
        axios.get('https://api.smallbrother.rs/accountInfo')
            .then(function (response) {
                if (response.status == 200) {
                    dispatch(account.actions.account(response.data));
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const getCatalogItems = (pageNumber, pageSize, sortDirection, sortByColumn, quantity, categoryId, isOnSale) => async (dispatch) => {

    const attributes = {};
    attributes['pageNumber'] = pageNumber;
    attributes['pageSize'] = pageSize;
    attributes['sortDirection'] = sortDirection;
    attributes['sortByColumn'] = sortByColumn;
    attributes['quantity'] = quantity;
    if (categoryId && categoryId.length > 0) {
        attributes['categoryId'] = categoryId.join(",");
    }
    attributes['isOnSale'] = isOnSale

    try {
        axios.get('https://api.smallbrother.rs/shop/products', { params: attributes })
            .then(function (response) {
                if (response.status == 200) {
                    dispatch(catalog.actions.catalogProducts(response.data.catalogProducts));
                    dispatch(catalogPages.actions.pages(response.data.totalPages));
                    dispatch(catalogTotal.actions.totalProducts(response.data.totalProducts));
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const getCategories = () => async (dispatch) => {
    try {
        axios.get('https://api.smallbrother.rs/shop/categories')
            .then(function (response) {
                if (response.status == 200) {
                    dispatch(categories.actions.categoriesInfo(response.data));
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const getCart = (activateLoyalty, isForShipment, isOnlinePayment) => async (dispatch) => {
    try {
        axios.get('https://api.smallbrother.rs/cartInfo',
            { params: { "activateLoyalty": activateLoyalty ? activateLoyalty : false, "isForShipment": isForShipment == "home" ? true : false, "isOnlinePayment": isOnlinePayment } })
            .then(function (response) {
                if (response.status == 200) {
                    dispatch(cart.actions.cartInfo(response.data));
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const addToCart = (productId, quantity, getShipping, getLoyaltyOption, getOnlinePayment) => async (dispatch) => {

    const attributes = {};
    attributes['productId'] = productId;
    attributes['quantity'] = quantity;

    try {
        axios.post('https://api.smallbrother.rs/addToCart', attributes)
            .then(function (response) {
                if (response.data) {
                    toast(<MessageInfoBox message={response.data.message} type={response.data.isOk} />, {
                        className: response.data.isOk == true ? 'message-success' : 'message-error',
                    });
                    dispatch(getCart(getLoyaltyOption, getShipping, getOnlinePayment))
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const removeFromCart = (productId, quantity, getShipping, getLoyaltyOption, getOnlinePayment) => async (dispatch) => {

    const attributes = {};
    attributes['productId'] = productId;
    attributes['quantity'] = quantity;

    try {
        axios.post('https://api.smallbrother.rs/removeFromCart', attributes)
            .then(function (response) {
                if (response.data) {
                    toast(<MessageInfoBox message={response.data.message} type={response.data.isOk} />, {
                        className: response.data.isOk == true ? 'message-success' : 'message-error',
                    });
                    dispatch(getCart(getLoyaltyOption, getShipping, getOnlinePayment))
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const getProduct = (productId) => async (dispatch) => {
    try {
        axios.get('https://api.smallbrother.rs/shop/product', { params: { "productId": productId } })
            .then(function (response) {
                if (response.status == 200) {
                    dispatch(product.actions.productInfo(response.data));
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const getHomePage = () => async (dispatch) => {
    try {
        axios.get('https://api.smallbrother.rs/homepage')
            .then(function (response) {
                if (response.status == 200) {
                    dispatch(homePageBannerSlider.actions.homePageBannerSlider(response.data.homePictures));
                    dispatch(homePageBlogs.actions.homePageBlogs(response.data.homeBlog));
                    dispatch(homePageNewProducts.actions.homePageNewProducts(response.data.newestProducts));
                    dispatch(homePageBestSeller.actions.homePageBestSeller(response.data.bestSellerProducts));
                    dispatch(homePageLocalProducts.actions.homePageLocalProducts(response.data.localProducts));
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const getHomePageProducts = () => async (dispatch) => {
    try {
        axios.get('https://api.smallbrother.rs/shop/newestProducts')
            .then(function (response) {
                if (response.status == 200) {
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const login = (values) => async (dispatch) => {
    try {
        axios.get('https://api.smallbrother.rs/login', { headers: values })
            .then(function (response) {
                if (response.status == 200) {
                    toast(<MessageInfoBox message={response.data.message} type={response.data.isOk} />, {
                        className: response.data.isOk == true ? 'message-success' : 'message-error',
                    });
                    dispatch(isLogged.actions.isLogged(true));
                    localStorage.setItem("isLogged", true)
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const logoutFunction = (param) => async (dispatch) => {
    try {
        axios.get('https://api.smallbrother.rs/logout')
            .then(function (response) {
                if (response.status == 200) {
                    dispatch(isLogged.actions.isLogged(false));
                    localStorage.removeItem("isLogged")
                    dispatch(account.actions.account({}));
                    if (!param) {
                        toast(<MessageInfoBox message={response.data.message} type={response.data.isOk} />, {
                            className: response.data.isOk == true ? 'message-success' : 'message-error',
                        });
                    }
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const registration = (values) => async (dispatch) => {

    const attributes = {};
    attributes['email'] = values.email;
    attributes['password'] = values.password;
    attributes['name'] = values.name ? values.name : "";
    attributes['surname'] = values.surname ? values.surname : "";
    attributes['phoneNumber'] = values.phoneNumber ? values.phoneNumber : "";
    attributes['country'] = values.country ? values.country : "";
    attributes['city'] = values.city ? values.city : "";
    attributes['address'] = values.address ? values.address : "";
    attributes['postCode'] = values.postCode ? values.postCode : "";
    attributes['wantsNewsletter'] = values.wantsNewsletter ? values.wantsNewsletter : false;

    try {
        axios.post('https://api.smallbrother.rs/account', attributes)
            .then(function (response) {
                if (response.status == 200) {
                    toast(<MessageInfoBox message={response.data.message} type={response.data.isOk} />, {
                        className: response.data.isOk == true ? 'message-success' : 'message-error',
                    });
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const forgotPass = (email) => {
    return (dispatch, state) => {
        return axios.get('https://api.smallbrother.rs/forgotPassword', { headers: email })
            .then(res => {
                if (res.status == 200) {
                    toast(<MessageInfoBox message={res.data.message} type={res.data.isOk} />, {
                        className: res.data.isOk == true ? 'message-success' : 'message-error',
                    });
                    return res.data.isOk
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

export const changePass = (newPassword, resetToken) => {
    return (dispatch, state) => {
        return axios.post('https://api.smallbrother.rs/changePassword', null, { headers: { "newPassword": newPassword, "resetToken": resetToken } })
            .then(res => {
                if (res.status == 200) {
                    toast(<MessageInfoBox message={res.data.message} type={res.data.isOk} />, {
                        className: res.data.isOk == true ? 'message-success' : 'message-error',
                    });
                    return res.data.isOk
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

export const finishShopping = (values, isForShipment, isPayed, totalPrice, purchaseProducts, loyaltyPoints, shipmentPrice) => {
    return (dispatch, state) => {

        const attributes = {};
        attributes['email'] = values.email;
        attributes['name'] = values.name;
        attributes['surname'] = values.surname;
        attributes['phoneNumber'] = values.phoneNumber;
        attributes['country'] = "Srbija";
        attributes['city'] = values.city;
        attributes['address'] = values.address;
        attributes['postCode'] = values.postCode;
        attributes['isLegalEntity'] = values.isLegalEntity;
        attributes['taxNumber'] = values.taxNumber;
        attributes['isForShipment'] = isForShipment == "shop" ? false : true;
        attributes['isPayed'] = isPayed == "cashOption" ? false : true;
        attributes['purchaseProducts'] = purchaseProducts;
        attributes['totalPrice'] = totalPrice;
        attributes['loyaltyPoints'] = loyaltyPoints;
        attributes['shipmentPrice'] = shipmentPrice;

        return axios.post('https://api.smallbrother.rs/finishCart', attributes)
            .then(res => {
                if (res.status == 200) {
                    toast(<MessageInfoBox message={res.data.message} type={res.data.isOk} />, {
                        className: res.data.isOk == true ? 'message-success' : 'message-error',
                    });
                    return res.data.isOk
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    }
}

export const finishCardPayment = (values, isForShipment, isPayed, totalPrice, purchaseProducts, loyaltyPoints, shipmentPrice, orderId) => {
    return (dispatch, state) => {

        const attributes = {};
        attributes['email'] = values.email;
        attributes['name'] = values.name;
        attributes['surname'] = values.surname;
        attributes['phoneNumber'] = values.phoneNumber;
        attributes['country'] = "Srbija";
        attributes['city'] = values.city;
        attributes['address'] = values.address;
        attributes['postCode'] = values.postCode;
        attributes['isLegalEntity'] = values.isLegalEntity;
        attributes['taxNumber'] = values.taxNumber;
        attributes['isForShipment'] = isForShipment == "shop" ? false : true;
        attributes['isPayed'] = isPayed == "cashOption" ? false : true;
        attributes['purchaseProducts'] = purchaseProducts;
        attributes['totalPrice'] = totalPrice;
        attributes['loyaltyPoints'] = loyaltyPoints;
        attributes['shipmentPrice'] = shipmentPrice;
        attributes['raffeisenId'] = orderId;

        return axios.post('https://api.smallbrother.rs/finishRaffeisen', attributes)
            .then(res => {
                if (res.status == 200) {
                    return res.data
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    }
}

export const getBlogs = () => async (dispatch) => {
    try {
        axios.get('https://api.smallbrother.rs/blogs')
            .then(function (response) {
                if (response.status == 200) {
                    dispatch(blogs.actions.blogs(response.data));
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const getBlog = (blogId) => {

    return (dispatch, state) => {
        return axios.get('https://api.smallbrother.rs/blog', { params: { "blogId": blogId } })
            .then(res => {
                if (res.status == 200) {
                    return res.data
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    }
}

export const search = (searchTerm) => async (dispatch) => {
    try {
        axios.get('https://api.smallbrother.rs/shop/searchShop', { params: { "searchTerm": searchTerm } })
            .then(function (response) {
                if (response.status == 200) {
                    dispatch(searchResult.actions.searchResult(response.data));
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const getLoyaltyUsers = (search) => async (dispatch) => {
    try {
        axios.get('https://api.smallbrother.rs/accounts', { params: search && { "search": search } })
            .then(function (response) {
                if (response.status == 200) {
                    dispatch(loyaltyUsers.actions.loyaltyUsers(response.data));
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const changeLoyaltyUsers = (email, points) => async (dispatch) => {
    try {
        axios.post('https://api.smallbrother.rs/loyaltyPoints', null, { headers: { "email": email }, params: { "points": points } })
            .then(function (response) {
                if (response.status == 200) {
                    toast(<MessageInfoBox message={response.data.message} type={response.data.isOk} />, {
                        className: response.data.isOk == true ? 'message-success' : 'message-error',
                    });
                    dispatch(getLoyaltyUsers())
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const getAdminCategories = (searchTerm) => async (dispatch) => {
    try {
        axios.get('https://api.smallbrother.rs/shop/adminCategories', { params: searchTerm && { "searchTerm": searchTerm } })
            .then(function (response) {
                if (response.status == 200) {
                    dispatch(adminCategories.actions.adminCategories(response.data));
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const crudCategories = (values) => async (dispatch) => {

    const attributes = {};
    attributes['name'] = values.name;
    attributes['categoryId'] = values.categoryId;

    try {
        axios.post('https://api.smallbrother.rs/shop/category', attributes)
            .then(function (response) {
                if (response.status == 200) {
                    toast(<MessageInfoBox message={response.data.message} type={response.data.isOk} />, {
                        className: response.data.isOk == true ? 'message-success' : 'message-error',
                    });
                    dispatch(getAdminCategories())
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const deleteCategories = (id) => async (dispatch) => {

    try {
        axios.delete(`https://api.smallbrother.rs/shop/category/${id}`)
            .then(function (response) {
                if (response.status == 200) {
                    toast(<MessageInfoBox message={response.data.message} type={response.data.isOk} />, {
                        className: response.data.isOk == true ? 'message-success' : 'message-error',
                    });
                    dispatch(getAdminCategories())
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const getAdminProducts = (searchTerm) => async (dispatch) => {
    try {
        axios.get('https://api.smallbrother.rs/shop/adminProducts', { params: searchTerm && { "searchTerm": searchTerm } })
            .then(function (response) {
                if (response.status == 200) {
                    dispatch(adminProducts.actions.adminProducts(response.data));
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const getAdminProduct = (productId) => {
    return (dispatch, state) => {
        return axios.get('https://api.smallbrother.rs/shop/adminProduct', { params: { "productId": productId } })
            .then(res => {
                if (res.status == 200) {
                    return res.data
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    }
}

export const crudProducts = (values, attr, externalId) => async (dispatch) => {

    const attributes = {};
    const similars = values["similars"].map(item => item.productId);
    const accessories = values["accessories"].map(item => item.productId);

    attributes['productId'] = values.productId;
    attributes['externalId'] = externalId;
    attributes['name'] = values.name;
    attributes['isForPromotion'] = values.isForPromotion;
    attributes['isBestSeller'] = values.isBestSeller;
    attributes['code'] = values.code;
    attributes['hasBanner'] = values.hasBanner;
    attributes['banner'] = values.banner;
    attributes['isNew'] = values.isNew;
    attributes['isSoon'] = values.isSoon;
    attributes['productAttributes'] = attr;
    attributes['images'] = values.images;
    attributes['videos'] = values.videos;
    attributes['quantity'] = values.quantity;
    attributes['price'] = values.price;
    attributes['isOnSale'] = values.isOnSale;
    attributes['saleProcent'] = values.saleProcent;
    attributes['description'] = values.description;
    attributes['seoDescription'] = values.seoDescription;
    attributes['declaration'] = values.declaration;
    attributes['categories'] = values.categories;
    attributes['similars'] = similars;
    attributes['accessories'] = accessories;
    attributes['isActive'] = values.isActive;

    // console.log(attributes)

    try {
        axios.post('https://api.smallbrother.rs/shop/product', attributes)
            .then(function (response) {
                if (response.status == 200) {
                    toast(<MessageInfoBox message={response.data.message} type={response.data.isOk} />, {
                        className: response.data.isOk == true ? 'message-success' : 'message-error',
                    });
                    dispatch(getAdminProducts())
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const deleteProducts = (id) => async (dispatch) => {

    try {
        axios.delete(`https://api.smallbrother.rs/shop/product/${id}`)
            .then(function (response) {
                if (response.status == 200) {
                    toast(<MessageInfoBox message={response.data.message} type={response.data.isOk} />, {
                        className: response.data.isOk == true ? 'message-success' : 'message-error',
                    });
                    dispatch(getAdminProducts())
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const uploadFile = (file) => {
    return (dispatch, state) => {
        return axios.post('https://api.smallbrother.rs/file', file)
            .then(res => {
                if (res.status == 200) {
                    return res.data
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    }
}

export const uploadHomeVideo = (file) => {
    return (dispatch, state) => {
        return axios.post('https://api.smallbrother.rs/homevideo', file)
            .then(res => {
                if (res.status == 200) {
                    return res.data
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    }
}

export const getAdminBlogs = (searchTerm) => async (dispatch) => {
    try {
        axios.get('https://api.smallbrother.rs/adminBlogs', { params: searchTerm && { "searchTerm": searchTerm } })
            .then(function (response) {
                if (response.status == 200) {
                    dispatch(adminBlogs.actions.adminBlogs(response.data));
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const crudBlogs = (values, attr) => async (dispatch) => {

    const attributes = {};

    attributes['blogId'] = values.blogId;
    attributes['name'] = values.name;
    attributes['banner'] = values.banner;
    attributes['image'] = values.image;
    attributes['blogContents'] = attr;

    try {
        axios.post('https://api.smallbrother.rs/blog', attributes)
            .then(function (response) {
                if (response.status == 200) {
                    toast(<MessageInfoBox message={response.data.message} type={response.data.isOk} />, {
                        className: response.data.isOk == true ? 'message-success' : 'message-error',
                    });
                    dispatch(getAdminBlogs())
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const deleteBlogs = (id) => async (dispatch) => {

    try {
        axios.delete(`https://api.smallbrother.rs/blog/${id}`)
            .then(function (response) {
                if (response.status == 200) {
                    toast(<MessageInfoBox message={response.data.message} type={response.data.isOk} />, {
                        className: response.data.isOk == true ? 'message-success' : 'message-error',
                    });
                    dispatch(getAdminBlogs())
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const getHomePagePictures = () => async (dispatch) => {
    try {
        axios.get('https://api.smallbrother.rs/homePicture')
            .then(function (response) {
                if (response.status == 200) {
                    dispatch(adminHomePageSlider.actions.adminHomePageSlider(response.data));
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const createHomePagePic = (values) => async (dispatch) => {
    const attributes = {};

    attributes['homePictureId'] = values.homePictureId;
    attributes['href'] = values.href;
    attributes['image'] = values.image;

    try {
        axios.post('https://api.smallbrother.rs/homePicture', attributes)
            .then(function (response) {
                if (response.status == 200) {
                    toast(<MessageInfoBox message={response.data.message} type={response.data.isOk} />, {
                        className: response.data.isOk == true ? 'message-success' : 'message-error',
                    });
                    dispatch(getHomePagePictures())
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const deleteHomePagePic = (id) => async (dispatch) => {

    try {
        axios.delete(`https://api.smallbrother.rs/homePicture/${id}`)
            .then(function (response) {
                if (response.status == 200) {
                    toast(<MessageInfoBox message={response.data.message} type={response.data.isOk} />, {
                        className: response.data.isOk == true ? 'message-success' : 'message-error',
                    });
                    dispatch(getHomePagePictures())
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    } catch (error) {
        console.log(error)
    }
}

export const changePassword = (password, newPassword) => {
    return (dispatch, state) => {
        return axios.post('https://api.smallbrother.rs/passwordChange', null, { headers: { "password": password, "newPassword": newPassword } })
            .then(res => {
                if (res.status == 200) {
                    toast(<MessageInfoBox message={res.data.message} type={res.data.isOk} />, {
                        className: res.data.isOk == true ? 'message-success' : 'message-error',
                    });
                    return res.data.isOk
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export const changeAccountInfo = (values) => {
    const attributes = {};

    attributes['name'] = values.name;
    attributes['surname'] = values.surname;
    attributes['phoneNumber'] = values.phoneNumber;
    attributes['country'] = values.country;
    attributes['city'] = values.city;
    attributes['postCode'] = values.postCode;
    attributes['address'] = values.address;

    return (dispatch, state) => {
        return axios.post('https://api.smallbrother.rs/accountInfo', attributes)
            .then(res => {
                if (res.status == 200) {
                    toast(<MessageInfoBox message={res.data.message} type={res.data.isOk} />, {
                        className: res.data.isOk == true ? 'message-success' : 'message-error',
                    });
                    return res.data.isOk
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}