import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { getAdminCategories } from '../redux/backCalls';

import CategoriesModal from './CategoriesModal';

const AdminCategories = () => {

    const dispatch = useDispatch()
    const getAdminCat = useSelector((state) => state.adminCategories);

    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState("edit");
    const [category, setCategory] = useState({});

    const handleCloseModal = (val, cont, cat) => {
        setShowModal(val);
        setModalContent(cont)
        setCategory(cat)
    };

    useEffect(() => {
        dispatch(getAdminCategories())
    }, [])

    return (
        <>
            <div className="d-flex justify-content-end m-1">
                <button className='btn btn-sm btn-dark' onClick={() => handleCloseModal(true, "create")}>Kreiraj</button>
            </div>
            {getAdminCat.length > 0 ?
                <table className="table table-hover mt-4">
                    <thead>
                        <tr>
                            <th scope="col">Naziv kategorije</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {getAdminCat.map((i, k) => (
                            <tr key={k}>
                                <td>{i.name}</td>
                                <td>
                                    <button className='btn btn-sm btn-dark' onClick={() => handleCloseModal(true, "edit", i)}>Promeni</button>
                                </td>
                                <td>
                                    <button className='btn btn-sm btn-dark' onClick={() => handleCloseModal(true, "delete", i)}>Obriši</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                :
                null
            }
            {showModal &&
                <CategoriesModal showModal={showModal} modalContent={modalContent} handleCloseModal={handleCloseModal} category={category} />
            }
        </>
    )
}

export default AdminCategories