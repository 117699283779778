import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

import { search } from '../redux/backCalls';
import { searchResult, searchQuery } from '../redux/slices';

import CatalogProduct from '../components/CatalogProduct';
import { Helmet } from 'react-helmet';

const SearchPage = () => {

    const dispatch = useDispatch()

    const getSearchResults = useSelector((state) => state.searchResult);
    const getSearchQuery = useSelector((state) => state.searchQuery);
    const [value, setValue] = useState(getSearchQuery)

    const searchShop = (val) => {
        if(val.length >= 1) {
            dispatch(search(val))
            setValue(val)
            dispatch(searchQuery.actions.searchQuery(val))
            localStorage.setItem('searchQ', val)
        } else {
            dispatch(searchResult.actions.searchResult([]));
            setValue("")
            dispatch(searchQuery.actions.searchQuery(""))
            localStorage.removeItem('searchQ')
        }
    }

    return (
        <div className="container-fluid main-contaner">
            <Helmet>
                <title>Pretraga</title>
                <meta name='description' content='Dobrodošli na stranicu pretrage društvenih igara! Naš alat za pretragu vam omogućava brz i jednostavan pristup širokom asortimanu igara koje tražite.
                Pronađite omiljene igre, istražite najnovije dodatke kolekciji ili otkrijte neki skriveni dragulj - sve na jednom mestu!'></meta>
            </Helmet>
            <div className="row mb-3 px-lg-5 d-flex align-items-center">
                <div className="col-6">
                    <h3 className="search-page-header p-1 pb-lg-2">Rezultati pretrage</h3>
                </div>
                <div className="col-6 d-flex justify-content-end align-items-center">
                    <input type='text' className="search-page-box p-1 pb-lg-2" autoComplete="off" placeholder='Pretraga...' onChange={(e) => searchShop(e.target.value)}
                        value={value} />
                    <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon" />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-sm-12">
                    <div className="products-container py-4">
                        {getSearchResults.length > 0 ? (
                            <>
                                {getSearchResults.map((item, key) => (
                                    <CatalogProduct item={item} key={key} />
                                ))}
                            </>
                        ) :
                            <h5>Pretraži...</h5>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchPage