import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartPlus } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet';

import { getProduct, addToCart } from '../redux/backCalls'
import { currencyFormat } from '../helperFunctions';

import ProductSlider from '../components/ProductSlider';
import ProductDescription from '../components/ProductDescription';
import ProductSimilarSlider from '../components/ProductSimilarSlider';
import QtyInput from '../components/QtyInput';

const ProductPage = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const { productId } = params;
    const getProductInfo = useSelector((state) => state.productInfo);
    const getShipping = useSelector((state) => state.shipping);
    const getLoyaltyOption = useSelector((state) => state.loyalty);
    const getOnlinePayment = useSelector((state) => state.onlinePayment);

    const [qtyProduct, setQtyProduct] = useState(1);

    const functionQtyValue = (qty) => {
        setQtyProduct(qty);
    }

    const addToCartItem = (productId, qty) => {
        dispatch(addToCart(productId, qty, getShipping, getLoyaltyOption, getOnlinePayment))
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getProduct(productId))
    }, [productId])

    return (
        <div className="main-contaner">
            <Helmet>
                <title>{getProductInfo.name}</title>
                <meta name='description' content={getProductInfo.seoDescription}></meta>
            </Helmet>
            {Object.keys(getProductInfo).length > 0 ?
                <>
                    {getProductInfo.hasBanner &&
                        <div className="container-fluid mb-5">
                            <div className="row">
                                <div className="col-sm-12 product-page-banner-holder">
                                    <img src={getProductInfo.banner} alt={getProductInfo.name + 'banner'} />
                                </div>
                            </div>
                        </div>
                    }
                    <div className="container pt-5">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column justify-content-center">
                                <ProductSlider getProductInfo={getProductInfo} />
                            </div>
                            <div className="col-lg-6 product-info-holder">
                                <h1 className="product-page-name">{getProductInfo.name}</h1>
                                <div className="product-price mt-2">
                                    <p>{getProductInfo.isOnSale ? currencyFormat(getProductInfo.salePrice) : currencyFormat(getProductInfo.price)} rsd</p>
                                    {getProductInfo.isOnSale &&
                                        <span className="product-old-price">{currencyFormat(getProductInfo.price)} RSD</span>
                                    }
                                </div>
                                <div className="product-qty-buy mt-4">
                                    {getProductInfo.isAvailable ?
                                        <>
                                            <QtyInput functionSetQty={functionQtyValue} />
                                            <button type="button" className="btn btn-info" onClick={() => addToCartItem(getProductInfo.productId, qtyProduct)}>
                                                <FontAwesomeIcon icon={faCartPlus} /> Dodaj u korpu
                                            </button>
                                        </>
                                        :
                                        <button type="button" className="btn btn-outline-info">
                                            Nema na stanju
                                        </button>
                                    }
                                </div>
                                <div className="product-basic-info mt-4">
                                    <ul>
                                        <li>
                                            <span>Kategorija:</span>
                                            {getProductInfo.categories.map((item, key) => (
                                                <Link to={`/shop?kategorija=${item.categoryId}`} key={key}>
                                                    <span className="product-category">{item.name}</span>
                                                    {getProductInfo.categories[getProductInfo.categories.length - 1].categoryId == item.categoryId ? "" : "/"}
                                                </Link>
                                            ))}
                                        </li>
                                        <li>
                                            <span>Šifra artikla: </span>
                                            <small>{getProductInfo.code}</small>
                                        </li>
                                        <li>
                                            <span>Stanje: </span>
                                            {getProductInfo.isAvailable ?
                                                <span className='product-availability-true'>Na stanju</span>
                                                :
                                                <span className='product-availability-false'>Nije na stanju</span>
                                            }
                                        </li>
                                    </ul>
                                </div>
                                {getProductInfo.productAttributes.length > 0 &&
                                    <div className="product-page-details">
                                        <div className="tab-links">
                                            <div className="tab-link">
                                                Opšte informacije
                                            </div>
                                        </div>
                                        {getProductInfo.productAttributes.map((item, key) => (
                                            <div className="product-page-info">
                                                <img src={item.icon} alt="small brother game info icon" className="img-fluid" />
                                                <span title={item.text}>{item.text}</span>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                        <ProductDescription getProductInfo={getProductInfo} />
                        <div className="row">
                            <ProductSimilarSlider getProductInfo={getProductInfo.similarProducts} smallerSlider={1} name={"SLIČNI PROIZVODI"} />
                        </div>
                        {getProductInfo.accessories.length > 0 &&
                            <div className="row">
                                <ProductSimilarSlider getProductInfo={getProductInfo.accessories} smallerSlider={1} name={"DODACI"} />
                            </div>
                        }
                    </div>
                </>
                :
                null
            }
        </div>
    )
}

export default ProductPage