import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';

const ShoppingPolicyPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="container">
            <Helmet>
                <title>Uslovi kupovine</title>
                <meta name='description' content='Dobrodošli na stranicu Uslova kupovine - gde transparentnost i vaše poverenje dolaze na prvo mesto.
                Ovde možete pronaći sve informacije koje su vam potrebne kako biste bezbedno i sa sigurnošću uživali u kupovini na našem sajtu.'></meta>
            </Helmet>
            <div className="row my-4">
                <h1>Uslovi kupovine</h1>
            </div>
            <div className="row">
                <p>
                    Na osnovu odredbe čl.13. i čl.27. Zakona o zaštiti potrošača (Sl.glasnik RS 62/2014)
                    Small Brother d.o.o. svoje potrošače koji robu kupuju putem internet sajta
                    https://smallbrother.rs
                </p>
                <p>O B A V E Š T A V A</p>
                <p>
                    da se prodaja robe putem internet sajta https://smallbrother.rs obavlja u okviru
                    registrovane delatnosti privrednog društva Small Brother do.o. sa sedištem na adresi
                    Prizrenska 4a, 11000 Beograd, matični broj: 21873284 PIB: 113472423
                </p>
                <p>
                    da roba koja se prodaje putem internet sajta https://smallbrother.rs poseduje
                    svojstva potrebna za redovnu upotrebu te vrste robe u skladu sa njenom namenom
                    koja je definisana u Deklaraciji;
                </p>
                <p>
                    da se saobraznost robe ugovoru ISKLJUČIVO utvrđuje prema svojstvima i nameni
                    robe kako je to definisano pripadajućom Deklaracijom;
                </p>
                <p>
                    da je prodajna cena robe naznačena uz svaki artikal;
                </p>
                <p>
                    da je cena dostave za sve pošiljke vrednosti do 7.000,00 dinara 350,00 dinara,
                    odnosno da je usluga isporuke robe besplatna za sve iznose iznad 7.000,00 dinara
                    na teritoriji Srbiji. Dodatno, roba može biti preuzeta i u našoj prodavnici na adresi
                    Prizrenska 4a, Beoegrad;<br />
                    Sva plaćanja biće izvršena u lokalnoj valuti Republike Srbije – dinar (RSD).<br />
                    Za informativni prikaz cena u drugim valutama koristi se srednji kurs Narodne Banke
                    Srbije. Iznos za koji će biti zadužena Vaša platna kartica biće izražen u Vašoj
                    lokalnoj valuti kroz konverziju u istu po kursu koji koriste kartičarske organizacije, a
                    koji nama u trenutku transakcije ne može biti poznat. Kao rezultat ove konverzije
                    postoji mogućnost neznatne razlike od originalne cene navedene na našem sajtu;
                </p>
                <p>
                    da se mogućnost kupovine robe uz posebne cenovne pogodnosti i/ili uz posebne
                    prodajne podsticaje (akcijska prodaja), obavlja u skladu sa uslovima koji su
                    objavljeni na internet sajtu https://smallbrother.rs;
                </p>
                <p>
                    da potrošač robu koju kupuje putem internet sajta https://smallbrother.rs može platiti
                    pouzećem gotovinski u trenutku isporuke, uplatnicom preko računa banke, kao i
                    istaknutim platnim karticama u trenutku kupovine na sajtu,
                </p>
                <p>
                    da potrošač prilikom kreiranja porudžbenice pritiskom na taster POTVRDI preuzima
                    obavezu plaćanja naručene robe;
                </p>
                <p>
                    da u slučaju prijema robe potrošač ustanovi da artikal želi da zameni za drugi artikal,
                    koji može biti veće, manje ili iste cenovne kategorije od prethodno poručenog,
                    potrošač može zameniti artikal na način koji je definisan za robu kupljenu putem
                    ONLINE prodavnice ovde dodati hiperlink.
                </p>
                <p>
                    da u slučaju prijema robe potrošač isti ne želi da zadrži, ima pravo na raskid ugovora
                    i povraćaj sredstava u visini plaćene kupoprodajne cene za predmetnu robu, na
                    način koji je definisan za robu kupljenu putem ONLINE prodavnice, koji se može
                    pronaći ovde dodati hiperlink.
                </p>
                <p>
                    U slučaju vraćanja robe i povraćaja sredstava kupcu koji je prethodno platio nekom
                    od platnih kartica, delimično ili u celosti, a bez obzira na razlog vraćanja, Small
                    Brother d.o.o. je u obavezi da povraćaj vrši isključivo preko VISA, EC/MC i Maestro
                    metoda plaćanja, što znači da će banka na zahtev prodavca obaviti povraćaj
                    sredstava na račun korisnika kartici.
                </p>
                <p>
                    da za robu koja je poručena putem ONLINE prodavnice potrošač ima pravo da uz
                    račun/otpremnicu priloži reklamaciju na već korišćen proizvod, na način koji je
                    definisan za robu kupljenu putem ONLINE prodavnice, koji se može pronaći ovde
                    dodati hiperlink.<br />
                    da su sve iskazane cena na našem web sajtu izražene sa uračunatim PDV-om i da
                    nema skrivenih troškova.
                </p>
                <p className="mt-5">
                    Izjava o zaštiti privatnosti
                </p>
                <p>
                    U ime privrednog društva Small Brother d.o.o. (u daljem tekstu „Small Brother“)
                    obavezujemo se da ćemo čuvati privatnost svih naših kupaca. Prikupljamo samo
                    neophodne, osnovne podatke o kupcima/korisnicima i podatke neophodne za
                    poslovanje i informisanje korisnika u skladu sa dobrim poslovnim običajima i u cilju
                    pružanja kvalitetne usluge. Dajemo kupcima mogućnost izbora uključujući
                    mogućnost odluke da li žele ili ne da se odjave sa mailing lista koje se koriste za
                    marketinške kampanje. Svi podaci o korisnicima/kupcima se strogo čuvaju i dostupni
                    su samo zaposlenima kojima su ti podaci nužni za obavljanje posla. Svi zaposleni u
                    Small Brother-u su odgovorni za poštovanje načela zaštite privatnosti.
                </p>
                <p>
                    Small Brother se zalaže za očuvanje principa privatnosti u prikupljanju, korišćenju i
                    zaštiti korisničkih informacija. U skladu sa ovim načelima:
                </p>
                <ul className="ms-5">
                    <li style={{ listStyleType: "circle" }}>Dostavlja obaveštenja korisnicima po ustaljenoj praksi.</li>
                    <li style={{ listStyleType: "circle" }}>Omogućava izbor na koji način će se podaci koristiti u marketinške svrhe.</li>
                    <li style={{ listStyleType: "circle" }}>Obezbeđuje mogućnost ažuriranja ili promene podataka.</li>
                    <li style={{ listStyleType: "circle" }}>Ograničava dostupnost vaših informacija.</li>
                    <li style={{ listStyleType: "circle" }}>Omogućava kontakt u vezi pitanja koja su vezana za zaštitu privatnosti.</li>
                </ul>
                <p>
                    Small Brother veoma ceni odnos koji ima sa svojim kupcima i odgovorno je
                    posvećen upravljanju informacijama o korisnicima. Velika pažnja je usmerena ka
                    čuvanju ličnih informacija, u skladu sa postojećim zakonima o privatnosti i u skladu
                    sa internim standardima i praksom.
                </p>
                <p className="mt-5">
                    Polisa zaštite privatnosti
                </p>
                <p>
                    Ova polisa opisuje informacije i praksu u zaštiti privatnosti koja se primenjuje u
                    maloprodajnim objektima Small Brother, Web prodavnici https://smallbrother.rs i
                    svim drugim lokacijama, prilikama i dešavanjima na kojima se prikupljaju informacije
                    od strane Small Brother-a. Kada nam prosledite Vaše lične informacije, pristajete na
                    praksu korišćenja informacija na način kako je to opisano u ovoj polisi.
                </p>
                <p className="mt-5">
                    Tipovi obaveštenja korisnicima
                </p>
                <ul className="ms-5">
                    <li style={{ listStyleType: "circle" }}>Oglasi</li>
                    <li style={{ listStyleType: "circle" }}>Lifleti</li>
                    <li style={{ listStyleType: "circle" }}>Newsletteri</li>
                    <li style={{ listStyleType: "circle" }}>Katalozi</li>
                </ul>
                <p>
                    Tipovi prikupljanih informacija
                </p>
                <p>
                    Informacije koje Small Brother može prikupljati su:
                </p>
                <ul className="ms-5">
                    <li style={{ listStyleType: "circle" }}>
                        Vaše kontakt informacije (ime, prezime, poštanska i e-mail adresa, broj
                        telefona),
                    </li>
                    <li style={{ listStyleType: "circle" }}>Informacije za naplatu i isporuku (broj kreditne kartice, adresa za isporuku),</li>
                    <li style={{ listStyleType: "circle" }}>
                        Informacije ličnog izbora (lista željenih proizvoda, komentari o ponuđenim
                        proizvodima).
                    </li>
                </ul>
                <p>
                    Prikupljanje informacija
                </p>
                <p>
                    U toku kupovine ili tokom korišćenja naših servisa ili usluga, možemo Vam zatražiti
                    Vaše lične informacije. To se može desiti u sledećim slučajevima:
                </p>
                <ul className="ms-5">
                    <li style={{ listStyleType: "circle" }}>prilikom online registracije korisničkog naloga</li>
                    <li style={{ listStyleType: "circle" }}>pri kupovini proizvoda</li>
                    <li style={{ listStyleType: "circle" }}>korišćenjem nekog servisa ili usluge</li>
                    <li style={{ listStyleType: "circle" }}>pri dostavi proizvoda ili servisa</li>
                    <li style={{ listStyleType: "circle" }}>učestvovanjem u nagradnim igrama, poklonima, anketama i istraživanjima </li>
                    <li style={{ listStyleType: "circle" }}>uspostavljanjem kontakta preko Web stranica ili direktno u Small Brother prodavnicama</li>
                    <li style={{ listStyleType: "circle" }}>učestvovanjem u drugim aktivnostima</li>
                </ul>
                <p>
                    Korišćenje informacija
                </p>
                <p>
                    Prikupljene informacije se koriste u sledećim slučajevima:
                </p>
                <ul className="ms-5">
                    <li style={{ listStyleType: "circle" }}>popunjavanje formi u zahtevima za proizvodima, servisima ili informacijama</li>
                    <li style={{ listStyleType: "circle" }}>obezbeđivanje i pružanje korisničkih servisa</li>
                    <li style={{ listStyleType: "circle" }}>organizacija i administracija nagradnih igara, poklona, anketa i istraživanja</li>
                    <li style={{ listStyleType: "circle" }}>ponuda novih proizvoda i servisa</li>
                    <li style={{ listStyleType: "circle" }}>poboljšanje efektivnosti naše Web stranice, marketinških aktivnosti, servisa i ponude </li>
                    <li style={{ listStyleType: "circle" }}>sprovođenje istraživanja i analiza</li>
                    <li style={{ listStyleType: "circle" }}>slanje informacija u marketinške svrhe</li>
                    <li style={{ listStyleType: "circle" }}>obavljanje drugih poslovnih aktivnosti</li>
                </ul>
                <p className="mt-5">
                    Prikupljanje informacije o drugim osobama ili od drugih osoba
                </p>
                <p>
                    Ukoliko nam dostavite informaciju o drugim osobama ili ako druge osobe dostave
                    informacije o Vama, iste će biti korišćene striktno u svrhe za koje su i dostavljene.
                    Kao primer ovog tipa informacija možemo da navedemo adresu prijatelja na koju će
                    biti dostavljena porudžbina (poklon npr.) ili preko nekih drugih opcija.
                </p>
                <p>
                    Prosleđivanje ličnih informacija
                </p>
                <p>
                    Small Brother ne prodaje i ne iznajmljuje Vaše lične podatke trećim licima.
                </p>
                <p>
                    Small Brother može imati potrebu da prosledi informacije određenim trećim licima,
                    kao što su naš call centar, prodavnice, ovlašćeni servis, kurirska služba, ili drugi
                    predstavnici koji nastupaju u naše ime. Ova akcija može biti preduzeta u sledećim
                    slučajevima:
                </p>
                <ul className="ms-5">
                    <li style={{ listStyleType: "circle" }}>ispunjavanje korisničkih porudžbina</li>
                    <li style={{ listStyleType: "circle" }}>slanje marketinških obaveštenja</li>
                    <li style={{ listStyleType: "circle" }}>dostavljanje paketa</li>
                    <li style={{ listStyleType: "circle" }}>održavanje članskog kluba</li>
                    <li style={{ listStyleType: "circle" }}>sprovođenje istraživanja, analiza ili drugih upita </li>
                    <li style={{ listStyleType: "circle" }}>slanje regularnih poštanskih ili elektronskih poruka</li>
                    <li style={{ listStyleType: "circle" }}>procesiranje plaćanja putem kreditnih kartica</li>
                </ul>
                <p>
                    Treća lica, sa kojima Small Brother ima ugovor su ovlašćena da prosleđene
                    informacije koriste isključivo u svrhe za koje su unajmljena. Kao deo ugovora sa
                    njima, u obavezi su da slede pravila privatnosti koja smo im prosledili i da preduzmu
                    razumne mere predostrožnosti kako bi obezbedili sigurnost Vaših ličnih podataka.
                    Small Brother će eventualno biti prinuđena da prosledi lične podatke validnim
                    sudskim vlastima, po sudskom pozivu, sudskim istražiteljima ili drugačije kako je
                    propisano zakonom. Takođe, zadržavamo pravo da prijavimo istražnim organima
                    aktivnosti za koje smatramo da su nezakonske. Small Brother može proslediti
                    određene lične podatke u slučaju da verujemo da je taj postupak razumno potreban
                    kako bi se zaštitila prava, svojina i sigurnost drugih i nas samih. Isto tako lični podaci
                    se mogu proslediti u slučaju korporacijske prodaje, integrisanja, akvizicije,
                    raspuštanja ili nekog sličnog događaja.
                </p>
                <p>
                    Mogućnost neprihvatanja marketinških aktivnosti
                </p>
                <p>
                    Jednom kada prikupimo vaše podatke, možemo Vam poslati marketinška
                    obaveštenja koja sadrže povoljne ponude, promocije, prodajne akcije i ostale
                    sadržaje. U svakom trenutku imate opciju neprihvatanja budućih marketinških
                    obaveštenja.
                </p>
                <p>
                    Obezbeđivanje sigurnosti za lične podatke
                </p>
                <p>
                    Bilo da kupujete putem Interneta ili u našim prodavnicama, primenjuju se mere
                    bezbednosti i razumne predostrožnosti kako bi se sprečio gubitak, zloupotreba i
                    neovlašćeni pristup Vašim ličnim podacima koji su pod našom kontrolom. Small
                    Brother ne može da osigura ili garantuje sigurnost bilo kog podatka koji se
                    prosleđuje putem elektronske pošte, tako da to radite na sopstvenu odgovornost.
                </p>
                <p>
                    S obzirom da je sigurnost Vaših ličnih podataka visok prioritet, preduzet je određen
                    broj akcija kako bi omogućio pouzdan, tačan i siguran transfer podataka. Web
                    stranice https://smallbrother.rs koriste tehnologiju enkripcije, kao što je Secure
                    Sockets Layer (SSL), kako bi se zaštitili Vaši lični podaci tokom transfera podataka.
                    SSL vrši enkripciju podataka iz porudžbine, kao što su Vaše ime, prezime, adresa i
                    broj kreditne kartice.
                </p>
                <p>
                    Kako bi podigli nivo sigurnosti, prilikom kreiranja korisničkog naloga na našim Web
                    stranicama, potrebno je da odaberete ličnu šifru koja bi trebalo da sadrži od 6 do 30
                    slovnih karaktera, uključujući kombinaciju i slova i brojeva. Lično ste odgovorni za
                    očuvanje tajnovitosti vaše šifre ili podataka o korisničkom nalogu.
                </p>
                <p>
                    Korišćenje Internet kolačića (Cookies), ostalih tehnologija i trećih lica za
                    prikupljanje podataka
                </p>
                <p>
                    Internet kolačić je mala datoteka koju Web stranica ili e-mail poruka šalje Vašem
                    Internet čitaču, koji ga onda može sačuvati na Vašem hard disku. Naše Web stranice
                    i e-mail poruke koriste Internet kolačiće kako bi maksimalno personalizovali
                    proceduru kupovine na našim Web stranicama. Kolačić simulira neprekidnu
                    konekciju. Oni omogućavaju čuvanje informacija o Vašim podešavanjima i sesijama i
                    dozvoljava pristup Web stranicama bez ponovne personalizacije.
                </p>
                <p>
                    Web čitač (browser) se može podesiti da ne prihvata Internet kolačiće. U tom slučaju
                    ćete biti u mogućnosti da gledate naše Web stranice, ali nećete moći da kreirate
                    korisnički nalog ili da napravite porudžbinu.
                </p>
                <p>
                    Naš Web server automatski sakuplja informacije kao što su adresa (URL) Web
                    stranice sa koje ste došli, koje stranice ste posetili na našoj Internet prezentaciji, koji
                    Web čitač koristite za pregled naših Web stranica i ostale statističke podatke koji
                    nam pomažu da znamo kako posetioci koriste naše Web stranice.
                </p>
                <p>
                    Web stranica https://smallbrother.rs koristi servise koje obezbeđuju treća lica i koja u
                    naše ime, putem Interneta, analiziraju saobraćaj i posećenost naših Web stranica.
                    Na taj način mogu biti prikupljane anonimne informacije o Vašim posetama našim
                    Web stranicama i Vašoj interakciji sa našim oglašavanjima. Za te potrebe se mogu
                    koristiti tehnologije slične onima koje smo opisali u prethodnim pasusima, kako bi se
                    izmerila efikasnost oglašavanja i e-mailova.
                </p>
                <p>
                    Ažuriranje ličnih podataka
                </p>
                <p>
                    Small Brother želi da Vaši lični podaci budu tačni i kompletni. Obezbedili smo
                    nekoliko različitih metoda za ažuriranje istih.
                </p>
                <p>
                    Ako ste kreirali korisnički nalog na https://smallbrother.rs web stranici, možete
                    ažurirati Vaše podatke nakon prijave.
                </p>
                <p>
                    Sakupljanje informacija o deci
                </p>
                <p>
                    Small Brother neće svesno ili namerno prikupljati lične podatke putem Interneta od
                    dece koja su mlađa od 13 godina. Zalažemo se za zaštitu prava dece!
                </p>
                <p className="mt-5">
                    Kontakt
                </p>
                <p>
                    Ukoliko imate pitanja ili pritužbe koje se tiču privatnosti i sigurnosti Vaših podataka, ili
                    ukoliko imate saznanja ili sumnju o narušavanju privatnosti ili sigurnosti, molimo Vas
                    da nas kontaktirate.
                </p>
                <p>
                    Kontakt podaci
                </p>
                <p>
                    Small Brother d.o.o. Beograd
                </p>
                <p>
                    Adresa: Prizrenska 4a, 11000 Beograd
                </p>
                <p>
                    E-mail: info@smallbrother.rs
                </p>
                <p>
                    Telefon: 011/3428260
                </p>
                <p>
                    PIB: 113472423
                </p>
                <p>
                    Matični broj: 21873284
                </p>
                <p>
                    Tekući račun: 265-1110310006778-35
                </p>
                <p className="mt-5">
                    Zaštita poverljivih podataka o transakciji
                </p>
                <p>
                    Prilikom unošenja podataka o platnoj kartici, poverljive informacija se prenose putem
                    javne mreže u zaštićenoj (kriptovanoj) formi upotrebom SSL protokola i PKI sistema,
                    kao trenutno najsavremenije kriptografske tehnologije.
                </p>
                <p>
                    Sigurnost podataka prilikom kupovine, garantuje procesor platnih kartica, Raiffeisen
                    banka ad Beograd, pa se tako kompletni proces naplate obavlja na stranicama
                    banke. Niti jednog trenutka podaci o platnoj kartici nisu dostupni našem sistemu.
                </p>
            </div>
        </div>
    )
}

export default ShoppingPolicyPage