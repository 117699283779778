import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faUser, faCreditCard, faCheckDouble, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { UpcPayment, UpcPaymentMode } from 'upc-payment-js';
import { toast } from 'react-toastify';


import { finishShopping, getCart, finishCardPayment } from '../redux/backCalls';
import { shipping, loyalty, onlinePayment } from '../redux/slices';

import MessageInfoBox from '../components/MessageInfoBox';
import CartItem from '../components/CartItem';
import CartUserData from '../components/CartUserData';
import CartPayment from '../components/CartPayment';
import CartCheck from '../components/CartCheck';
import { Helmet } from 'react-helmet';

const CartPage = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const getCartInfo = useSelector((state) => state.cartInfo);
    const getAccInfo = useSelector((state) => state.account);
    const getisLogged = useSelector((state) => state.isLogged);
    const getOnlinePayment = useSelector((state) => state.onlinePayment);

    //vratiti na 1 posle testa
    const [tab, setTab] = useState(1);
    const [openMore, setOpenMore] = useState(false);
    const [values, setValues] = useState({})
    const [validInputValue, setValidInputValue] = useState({})
    const [errors, setErrors] = useState({})
    const [errorsMore, setErrorsMore] = useState({})
    const [paymentOption, setPaymentOption] = useState("cardOption")
    const [delivery, setDelivery] = useState("shop")
    const [checkedLoyalty, setCheckedLoyalty] = useState(false)

    const changeTab = (val) => {
        setTab(tab + val)
    }

    const setErrorsFunction = (name, val) => {
        setErrors({ ...errors, [name]: val });
    };

    const setErrorsMoreFunction = (name, val) => {
        setErrorsMore({ ...errorsMore, [name]: val });
    };

    const addLoyaltyPoints = () => {
        dispatch(getCart(!checkedLoyalty, delivery, getOnlinePayment))
        setCheckedLoyalty(!checkedLoyalty)
        dispatch(loyalty.actions.loyalty(!checkedLoyalty))
    };

    const setShipping = (val) => {
        setDelivery(val)
        dispatch(getCart(checkedLoyalty, val, getOnlinePayment))
        dispatch(shipping.actions.shipping(val))
    };

    const setOnlinePayment = (val) => {
        setPaymentOption(val)
        if(val == "cardOption") {
            dispatch(getCart(checkedLoyalty, val, true))
            dispatch(onlinePayment.actions.onlinePayment(true))
        } else {
            dispatch(getCart(checkedLoyalty, val, false))
            dispatch(onlinePayment.actions.onlinePayment(false))
        }
    };

    const submitButton = (e) => {
        setValidInputValue({
            ...validInputValue,
            name: values.name == "" ? 1 : 2,
            surname: values.surname == "" ? 1 : 2,
            address: values.address == "" ? 1 : 2,
            city: values.city == "" ? 1 : 2,
            postCode: values.postCode == "" ? 1 : 2,
            phoneNumber: values.phoneNumber == "" ? 1 : 2,
            email: values.email == "" ? 1 : 2,
            taxNumber: openMore && values.taxNumber == "" ? 1 : 2
        })

        const err = Object.values(errors);
        const errMore = Object.values(errorsMore);
        err.every(value => value === 0) && errMore.every(value => value === 0) && setTab(tab + 1)
        window.scrollTo(0, 0);
    }

    const sendData = () => {

        if(paymentOption == "cardOption") {
            const shopTime = getCartInfo.raffeisenInfo.time;
            const sign = getCartInfo.raffeisenInfo.signature;
            const order = getCartInfo.raffeisenInfo.orderId;
            const amount = getCartInfo.raffeisenInfo.paymentPrice;

            dispatch(finishCardPayment(values, delivery, paymentOption, getCartInfo.paymentPrice, getCartInfo.cartProducts, getCartInfo.loyaltyPoints, getCartInfo.shippingPrice, order))
                .then(res => {
                    if(res.isOk) {
                        const payment = new UpcPayment({
                            commonProps: {
                                merchantId: '218732847716FFC',
                                terminalId: '7716001C',
                                signature: sign,
                                currency: 941,
                                paymentUrl: 'https://ecommerce.raiffeisenbank.rs/rbrs/enter',
                                locale: 'rs',
                                mode: UpcPaymentMode.PaymentPage,
                            }
                        });

                        payment.pay({
                            orderId: order,
                            description: 'Kupovina',
                            purchaseTime: shopTime,
                            totalAmount: amount,
                        })

                        navigate("/")
                    } else {
                        toast(<MessageInfoBox message={res.message} type={res.isOk} />, {
                            className: res.isOk == true ? 'message-success' : 'message-error',
                        });
                    }
                }
                )
        } else {
            dispatch(finishShopping(values, delivery, paymentOption, getCartInfo.paymentPrice, getCartInfo.cartProducts, getCartInfo.loyaltyPoints, getCartInfo.shippingPrice))
                .then(res => {
                    res &&
                        setTimeout(() => {
                            navigate("/")
                        }, 1500)
                }
                )
        }
    }

    useEffect(() => {
        setValues({
            name: Object.keys(getAccInfo).length > 0 ? getAccInfo.name : "",
            surname: Object.keys(getAccInfo).length > 0 ? getAccInfo.surname : "",
            address: Object.keys(getAccInfo).length > 0 ? getAccInfo.address : "",
            city: Object.keys(getAccInfo).length > 0 ? getAccInfo.city : "",
            postCode: Object.keys(getAccInfo).length > 0 ? getAccInfo.postCode : "",
            phoneNumber: Object.keys(getAccInfo).length > 0 ? getAccInfo.phoneNumber : "",
            email: Object.keys(getAccInfo).length > 0 ? getAccInfo.email : "",
            taxNumber: "",
            isLegalEntity: false
        })

        setValidInputValue({
            name: 0,
            surname: 0,
            address: 0,
            city: 0,
            postCode: 0,
            phoneNumber: 0,
            email: 0,
            taxNumber: 0
        })

        if(delivery == "home") {
            setErrors({
                name: Object.keys(getAccInfo).length > 0 ? 0 : 1,
                surname: Object.keys(getAccInfo).length > 0 ? 0 : 1,
                address: Object.keys(getAccInfo).length > 0 ? 0 : 1,
                city: Object.keys(getAccInfo).length > 0 ? 0 : 1,
                postCode: Object.keys(getAccInfo).length > 0 ? 0 : 1,
                phoneNumber: Object.keys(getAccInfo).length > 0 ? 0 : 1,
                email: Object.keys(getAccInfo).length > 0 ? 0 : 1
            })
        } else {
            setErrors({
                name: Object.keys(getAccInfo).length > 0 ? 0 : 1,
                surname: Object.keys(getAccInfo).length > 0 ? 0 : 1,
                address: 0,
                city: 0,
                postCode: 0,
                phoneNumber: Object.keys(getAccInfo).length > 0 ? 0 : 1,
                email: Object.keys(getAccInfo).length > 0 ? 0 : 1,
            })
        }
    }, [delivery, getAccInfo])

    useEffect(() => {
        if(openMore) {
            setErrorsMore({
                taxNumber: 1
            })
        } else {
            setErrorsMore({
                taxNumber: 0
            })
        }
    }, [openMore])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [tab])

    return (
        <>
            {Object.keys(getCartInfo).length > 0 && getCartInfo.cartProducts.length > 0 ?

                <div className="container">
                    <Helmet>
                        <title>Korpa</title>
                    </Helmet>
                    <div className="navigation-holder">
                        <Link to={"/"}>Početna</Link>&nbsp; / &nbsp;<p>Pregled kupovine</p>
                    </div>
                    <div className="row">
                        <h1 className="text-center cart-header">MOJA KORPA</h1>
                    </div>
                    <div className="row my-4">
                        <div className='col-3'>
                            <div className={`${tab == 1 ? "active-cart-tab" : ""} big-cart-info p-3`}>
                                <FontAwesomeIcon icon={faCartShopping} />
                                <span>Sadržaj korpe</span>
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className={`${tab == 2 ? "active-cart-tab" : ""} big-cart-info p-3`}>
                                <FontAwesomeIcon icon={faCreditCard} />
                                <span>Plaćanje i preuzimanje</span>
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className={`${tab == 3 ? "active-cart-tab" : ""} big-cart-info p-3`}>
                                <FontAwesomeIcon icon={faUser} />
                                <span>Podaci</span>
                            </div>

                        </div>
                        <div className='col-3'>
                            <div className={`${tab == 4 ? "active-cart-tab" : ""} big-cart-info p-3`}>
                                <FontAwesomeIcon icon={faCheckDouble} />
                                <span>Potvrda kupovine</span>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-8 p-lg-4 mt-lg-3 big-cart-items">
                            {tab == 1 &&
                                getCartInfo.cartProducts.map((item, key) => (
                                    <CartItem item={item} key={key} />
                                ))
                            }
                            {tab == 2 &&
                                <CartPayment paymentOption={paymentOption} setOnlinePayment={setOnlinePayment} delivery={delivery} setShipping={setShipping} />
                            }
                            {tab == 3 &&
                                <CartUserData
                                    values={values}
                                    setValues={setValues}
                                    validInputValue={validInputValue}
                                    openMore={openMore}
                                    setOpenMore={setOpenMore}
                                    setErrorsFunction={setErrorsFunction}
                                    setErrorsMoreFunction={setErrorsMoreFunction}
                                    delivery={delivery}
                                />
                            }
                            {tab == 4 &&
                                <CartCheck getCartInfo={getCartInfo} setTab={setTab} values={values} openMore={openMore} paymentOption={paymentOption} delivery={delivery} />
                            }
                        </div>
                        <div className="col-sm-4 d-flex align-items-center align-items-lg-end flex-column">
                            <div className="big-cart-sum p-3 mt-4">
                                <h2 className="text-center">Vaša<br />porudžbina</h2>
                                <div className="price-sum-item">
                                    POPUST: <span className="big-cart-price">{getCartInfo.discountPrice} RSD</span>
                                </div>
                                {getisLogged &&
                                    <div className="price-sum-item">
                                        LOYALTY POPUST: <span className="big-cart-price">{getCartInfo.loyaltyDiscountPrice} RSD</span>
                                    </div>
                                }
                                <div className="price-sum-item">
                                    CENA DOSTAVE: <span className="big-cart-price">{getCartInfo.shippingPrice} RSD</span>
                                </div>
                                <div className="text-center price-sum">
                                    UKUPNA CENA:
                                    <div className="big-cart-price fs-3">{getCartInfo.paymentPrice} RSD</div>
                                </div>
                                <div className={`${tab > 1 ? "justify-content-evenly" : "justify-content-center"} d-flex w-100`}>
                                    {tab > 1 &&
                                        <div className="cart-back-button cart-button" onClick={() => changeTab(-1)}>
                                            <FontAwesomeIcon icon={faAngleLeft} />
                                        </div>
                                    }
                                    <button className="cart-button" onClick={(e) => tab == 3 ? submitButton(e) : tab == 4 ? sendData() : changeTab(1)}>
                                        <p>{tab == 4 ? "Kupi" : "Nastavi"}</p>
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </button>
                                </div>
                            </div>
                            {getisLogged &&
                                <div className="big-cart-sum p-3 mt-3">
                                    <div className="loyalty-points">
                                        <input type='checkbox' onChange={() => addLoyaltyPoints()} /> &nbsp;
                                        {checkedLoyalty ? `Potrošićete ${getCartInfo.loyaltyPoints} ${getCartInfo.loyaltyPoints == 1 ? "poen" : "poena"}` : "Potroši loyalty poene?"}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                :
                <div className="container main-contaner">
                    <h3 className="text-center">Korpa je prazna</h3>
                </div>
            }
        </>
    )
}

export default CartPage