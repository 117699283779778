import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDetectClickOutside } from 'react-detect-click-outside';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Helmet } from 'react-helmet';

import { getCatalogItems } from '../redux/backCalls';

import CatalogProduct from '../components/CatalogProduct'
import Categories from '../components/Categories';
import CatalogPageSize from '../components/CatalogPageSize';
import CatalogSort from '../components/CatalogSort';
import PaginationMui from '../components/PaginationMui';

const CatalogPage = () => {
    const isDesktop = window.innerWidth > 992;
    const query = useMediaQuery('(max-width: 1366px)');
    const dispatch = useDispatch();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const getCatalog = useSelector((state) => state.catalog);

    const [filters, setFilters] = useState(searchParams.get('naStanju') || -1)
    const [isOnSale, setIsOnSale] = useState(searchParams.get('naAkciji') || false)
    const [checked, setChecked] = useState(searchParams.getAll('kategorije') || [])
    const [pageSize, setPageSize] = useState(24)
    const [currentPage, setCurrentPage] = useState(0);
    const [sort, setSort] = useState("isForPromotion");
    const [sortDirection, setSortDirection] = useState("");
    const [toggleFilters, setToggleFilters] = useState(false);

    const closeFilters = () => {
        setToggleFilters(false)
    }

    const ref = useDetectClickOutside({ onTriggered: closeFilters });

    useEffect(() => {
        setFilters(searchParams.get('naStanju') || -1);
        setIsOnSale(searchParams.get('naAkciji') || false);
        setChecked(searchParams.getAll('kategorija') || [])
        setPageSize(searchParams.get('prikazi') || 24)
        setCurrentPage(searchParams.get('str') || 0)
        setSort(searchParams.get('sort') || "isForPromotion")
        setSortDirection(searchParams.get('sortDir') || "")
    }, [location.search])

    useEffect(() => {
        window.scrollTo(0, 0);

        setTimeout(() => {
            dispatch(getCatalogItems(currentPage, pageSize, sortDirection, sort, filters, checked, isOnSale));
        }, 500);

    }, [currentPage, pageSize, sort, sortDirection, filters, checked, isOnSale]);

    return (
        <div className="container-fluid main-contaner">
            <Helmet>
                <title>Prodavnica</title>
                <meta name='description' content='Dobrodošli u našu široku ponudu društvenih igara! Pregledajte našu kolekciju 
                    vrhunskih društvenih igara za sve uzraste i ukuse. Od klasičnih do najnovijih hitova, 
                    nudimo širok spektar igara koje će oživeti vaše društvene trenutke. Kvalitetni materijali, inovativni dizajn i raznovrsni žanrovi čine našu kolekciju jedinstvenom'></meta>
                <meta name='keywords' content='drustvene igre board games prodavnica shop'></meta>
                <meta name='robots' content='index, follow'></meta>
            </Helmet>
            <div className="row dropdown-holder">
                <div className="col-sm-12">
                    <div className="show-articles-container">
                        <CatalogSort sort={sort} setSort={setSort} sortDirection={sortDirection} setSortDirection={setSortDirection} setCurrentPage={setCurrentPage} />
                        <CatalogPageSize pageSize={pageSize} setPageSize={setPageSize} setFilters={setFilters} setCurrentPage={setCurrentPage} />
                        {!isDesktop &&
                            <div className="d-flex" ref={ref}>
                                <div className="sort-holder">
                                    <span className="btn btn-dark" onClick={() => setToggleFilters(!toggleFilters)}>Filteri</span>
                                </div>
                                <div className={`${toggleFilters ? "show-filters" : ""} collapsable-filters`}>
                                    <Categories
                                        checked={checked}
                                        setChecked={setChecked}
                                        filters={filters}
                                        setFilters={setFilters}
                                        setCurrentPage={setCurrentPage}
                                        isOnSale={isOnSale}
                                        setIsOnSale={setIsOnSale}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                {isDesktop &&
                    <Categories
                        checked={checked}
                        setChecked={setChecked}
                        filters={filters}
                        setFilters={setFilters}
                        setCurrentPage={setCurrentPage}
                        isOnSale={isOnSale}
                        setIsOnSale={setIsOnSale}
                        query={query}
                    />
                }
                <div className={`${isDesktop ? query ? "col-9" : "col-10" : "col-sm-12"}`}>
                    <div className="products-container py-4">
                        {getCatalog.length > 0 ? (
                            <>
                                {getCatalog.map((item, key) => (
                                    <CatalogProduct item={item} key={key} />
                                ))}
                            </>
                        ) :
                            null
                        }
                    </div>
                    <PaginationMui currentPage={currentPage} setCurrentPage={setCurrentPage} />
                </div>
            </div>
        </div>
    )
}

export default CatalogPage