import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartPlus, faCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

import { addToCart } from '../redux/backCalls';
import { removeSpecialChar, currencyFormat } from '../helperFunctions';

import QtyInput from './QtyInput'
import CatalogBadges from './CatalogBadges'

const CatalogProduct = ({ item }) => {

    //za slanje beku
    const dispatch = useDispatch()
    const [qtyProduct, setQtyProduct] = useState(1);

    const getShipping = useSelector((state) => state.shipping);
    const getLoyaltyOption = useSelector((state) => state.loyalty);
    const getOnlinePayment = useSelector((state) => state.onlinePayment);

    const functionQtyValue = (qty) => {
        setQtyProduct(qty);
    }

    const addToCartItem = (productId, qty) => {
        dispatch(addToCart(productId, qty, getShipping, getLoyaltyOption, getOnlinePayment))
    }

    return (
        <div className="catalog-product">
            {(item.isNew || item.isOnSale || item.isSoon) && <CatalogBadges item={item} />}
            <Link to={`/product/${item.productId}/${removeSpecialChar(item.name)}`}>
                <div className="catalog-image">
                    <img src={item.images[0]} loading='lazy' alt={item.name + 'box cover'} className="product-img img-fluid original-img" />
                    <img src={item.images[1] != null ? item.images[1] : item.images[0]} loading='lazy' alt={item.name + 'components'} className="product-img img-fluid hover-img" />
                </div>
            </Link>
            <div className="catalog-info">
                <Link to={`/product/${item.productId}/${removeSpecialChar(item.name)}`}>
                    <div className="product-name-holder">
                        <p className="product-name">{item.name}</p>
                    </div>
                </Link>
                <div className="sale-price row text-center ms-2">
                    <div className="col-4 px-0">
                        {item.isOnSale &&
                            <span className="old-price">{currencyFormat(item.price)} RSD</span>
                        }
                    </div>
                    <div className="col-4 px-0">
                        <p>{item.isOnSale ? currencyFormat(item.salePrice) : currencyFormat(item.price)} RSD</p>
                    </div>
                    <div className="catalog-product-loyalty col-4 px-0" title={`Kupovinom ovog proizvoda ostvarujete ${item.loyaltyPoints} poena`}>
                        {item.loyaltyPoints > 0 &&
                            <>
                                <FontAwesomeIcon className="catalog-product-loyalty-num p-0" icon={faCircle} />
                                <span className="catalog-product-loyalty-num">{item.loyaltyPoints}</span>
                            </>
                        }
                    </div>
                </div>
                <div className="catalog-shop">
                    {item.isAvailable ?
                        <>
                            <QtyInput functionSetQty={functionQtyValue} />
                            <button type="button" className="btn btn-dark shop-button" onClick={() => addToCartItem(item.productId, qtyProduct)}>
                                <FontAwesomeIcon icon={faCartPlus} />
                            </button>
                        </>
                        :
                        <button type="button" className="btn btn-outline-dark not-available">
                            Nema na stanju
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default CatalogProduct