import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { getAdminCategories, uploadFile, crudProducts, deleteProducts } from '../redux/backCalls';

const ProductsModal = ({ showModal, modalContent, handleCloseModal, product }) => {

    const dispatch = useDispatch()

    const getAdminCat = useSelector((state) => state.adminCategories);
    const getAdminProd = useSelector((state) => state.adminProducts);

    const [values, setValues] = useState({
        productId: Object.keys(product).length > 0 ? product.productId : 0,
        externalId: Object.keys(product).length > 0 ? product.externalId : "",
        name: Object.keys(product).length > 0 ? product.name : "",
        isForPromotion: Object.keys(product).length > 0 ? product.isForPromotion : false,
        isBestSeller: Object.keys(product).length > 0 ? product.isBestSeller : false,
        code: Object.keys(product).length > 0 ? product.code : "",
        hasBanner: Object.keys(product).length > 0 ? product.hasBanner : false,
        banner: Object.keys(product).length > 0 ? product.banner : "",
        isNew: Object.keys(product).length > 0 ? product.isNew : false,
        isSoon: Object.keys(product).length > 0 ? product.isSoon : false,
        videos: Object.keys(product).length > 0 ? product.videos : [],
        images: Object.keys(product).length > 0 ? product.images : [],
        quantity: Object.keys(product).length > 0 ? product.quantity : "",
        price: Object.keys(product).length > 0 ? product.price : "",
        isOnSale: Object.keys(product).length > 0 ? product.isOnSale : false,
        saleProcent: Object.keys(product).length > 0 ? product.saleProcent : "",
        description: Object.keys(product).length > 0 ? product.description : "",
        seoDescription: Object.keys(product).length > 0 ? product.seoDescription : "",
        declaration: Object.keys(product).length > 0 ? product.declaration : "",
        categories: Object.keys(product).length > 0 ? product.categories : [],
        similars: Object.keys(product).length > 0 ? product.similars : [],
        accessories: Object.keys(product).length > 0 ? product.accessories : [],
        isActive: Object.keys(product).length > 0 ? product.isActive : false,
    })
    const [showMoreSelect, setShowMoreSelect] = useState(Object.keys(product).length > 0 && product.productAttributes.length > 0 ? 0 : 1)
    const [prodAttr, setProdAttr] = useState(Object.keys(product).length > 0 && product.productAttributes.length > 0 ? product.productAttributes : [])
    const [icons, setIcons] = useState(Object.keys(product).length > 0 && product.productAttributes.length > 0 ? [] : [""])
    const [iconsText, setIconsText] = useState(Object.keys(product).length > 0 && product.productAttributes.length > 0 ? [] : [""])

    const banPart = values.banner.split('/');
    const bannerName = banPart[banPart.length - 1];

    const onChangeFunction = (e) => {
        if (e.target.name == "videos") {
            setValues({ ...values, [e.target.name]: [e.target.value] });
        } else {
            setValues({ ...values, [e.target.name]: e.target.value });
        }
    }

    const handleEditorDataChange = (event, editor, name) => {
        const data = editor.getData();
        setValues({ ...values, [name]: data });
    }

    const onChangeCheckbox = (e) => {
        setValues({ ...values, [e.target.name]: e.target.checked })
    }

    const onChangeAutocomplete = (name, val) => {
        setValues({ ...values, [name]: val })
    }

    const onChangeIcon = (e, index) => {
        if (e.target.name == "text") {
            const newTextValues = [...iconsText];
            newTextValues[index] = e.target.value;
            setIconsText(newTextValues);
        } else {
            const newTextValues = [...icons];
            newTextValues[index] = e.target.value;
            setIcons(newTextValues);
        }
    }

    const handleFileInput = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        const formData = new FormData();
        Object.values(e.target.files).map(i => {
            formData.append('file', i);
        })

        dispatch(uploadFile(formData))
            .then(res => {
                if (e.target.name == "images") {
                    setValues(prevValues => ({
                        ...prevValues,
                        images: [...prevValues.images, ...res]
                    }));
                } else {
                    setValues({ ...values, [e.target.name]: res.toString() })
                }
            })
    }

    const resetFileValue = (e) => {
        e.target.value = null
    }

    const deleteImg = (key) => {
        setValues(prevValues => ({
            ...prevValues,
            images: prevValues.images.filter((item, index) => index !== key)
        }));
    }

    const showMore = () => {
        setShowMoreSelect(showMoreSelect + 1)
        setIcons(prevValues => [...prevValues, '']);
        setIconsText(prevValues => [...prevValues, '']);
    }

    const showLess = (val, index) => {
        if (val) {
            setProdAttr((prevValues) => prevValues.filter((value, k) => k !== index));
            console.log("1")
        } else {
            console.log("tu si")
            setShowMoreSelect(showMoreSelect - 1)
            setIcons((prevValues) => prevValues.filter((value, k) => k !== index));
            setIconsText(prevValues => prevValues.filter((_, k) => k !== index));
        }
    }

    const submitButton = (e) => {
        e.preventDefault();

        const elements = document.querySelectorAll('#text, #icon');
        const objects = [];
        const combinedArray = [];

        elements.forEach(element => {
            let obj = new Object()
            obj[element.name] = element.value
            objects.push(obj);
        });

        for (let i = 0; i < objects.length; i += 2) {
            const combinedObject = { ...objects[i], ...objects[i + 1] };
            combinedArray.push(combinedObject);
        }

        const externalId = product.externalId != undefined ? product.externalId : "";

        dispatch(crudProducts(values, combinedArray, externalId))
        handleCloseModal(false)
    }

    const deleteProd = () => {
        dispatch(deleteProducts(product.productId))
        handleCloseModal(false)
    }

    useEffect(() => {
        if (getAdminCat.length == 0) {
            dispatch(getAdminCategories())
        }
    }, [])

    return (
        <Modal
            show={showModal}
            onHide={() => handleCloseModal(false)}
            keyboard={false}
            dialogClassName={modalContent == "delete" ? 'modal-md' : 'modal-lg'}
            aria-labelledby=""
            centered
            enforceFocus={false}
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="text-center">
                        {modalContent == "edit" ? "PROMENI PROIZVOD" : modalContent == "delete" ? "OBRIŠI PROIZVOD" : "KREIRAJ PROIZVOD"}
                    </div>
                </Modal.Title>
                <FontAwesomeIcon icon={faXmark} onClick={() => handleCloseModal(false)} className="close-modal" />
            </Modal.Header>
            <Modal.Body>
                {modalContent == "delete" ?
                    <div className="text-center">
                        <p>Da li ste sigurni da želite da obrišete proizvod?</p>
                        <div className="row d-flex justify-content-center mt-2">
                            <button type='button' className="btn btn-sm btn-success col-5 m-2" onClick={() => deleteProd()}>Da</button>
                            <button type='button' className="btn btn-sm btn-danger col-5 m-2" onClick={() => handleCloseModal(false)}>Ne</button>
                        </div>
                    </div>
                    :
                    <div className="row d-flex justify-content-center">
                        <form className="col-sm-12" noValidate onSubmit={submitButton}>
                            <Form.Group className="p-2">
                                <Form.Label>Naziv proizvoda:</Form.Label>
                                <Form.Control
                                    name='name'
                                    type='text'
                                    value={values['name']}
                                    onChange={(e) => onChangeFunction(e)}
                                />
                            </Form.Group>
                            <div className="d-flex p-2">
                                <Form.Check
                                    className="me-2"
                                    name='isForPromotion'
                                    onChange={(e) => onChangeCheckbox(e)}
                                    checked={values.isForPromotion == true ? true : false}
                                />Na promociji?
                            </div>
                            <div className="d-flex p-2">
                                <Form.Check
                                    className="me-2"
                                    name='isBestSeller'
                                    onChange={(e) => onChangeCheckbox(e)}
                                    checked={values.isBestSeller == true ? true : false}
                                />Najprodavanije?
                            </div>
                            <Form.Group className="p-2">
                                <Form.Label>Šifra proizvoda:</Form.Label>
                                <Form.Control
                                    name='code'
                                    type='text'
                                    value={values['code']}
                                    onChange={(e) => onChangeFunction(e)}
                                />
                            </Form.Group>
                            <div className="d-flex p-2">
                                <Form.Check
                                    className="me-2"
                                    name='hasBanner'
                                    onChange={(e) => onChangeCheckbox(e)}
                                    checked={values.hasBanner == true ? true : false}
                                />Ima baner?
                            </div>
                            {values.hasBanner == true &&
                                <>
                                    <Form.Group className="p-2">
                                        <Form.Label>Baner:</Form.Label>
                                        <Form.Control
                                            name='banner'
                                            type='file'
                                            onChange={(e) => handleFileInput(e)}
                                            onClick={resetFileValue}
                                        />
                                    </Form.Group>
                                    <span className='m-2'>{bannerName}</span>
                                </>
                            }
                            <div className="d-flex p-2">
                                <Form.Check
                                    className="me-2"
                                    name='isNew'
                                    onChange={(e) => onChangeCheckbox(e)}
                                    checked={values.isNew == true ? true : false}
                                    disabled={values.isSoon == true || values.isOnSale == true ? true : false}
                                />Novo?
                            </div>
                            <div className="d-flex p-2">
                                <Form.Check
                                    className="me-2"
                                    name='isSoon'
                                    onChange={(e) => onChangeCheckbox(e)}
                                    checked={values.isSoon == true ? true : false}
                                    disabled={values.isNew == true || values.isOnSale == true ? true : false}
                                />Uskoro?
                            </div>
                            <div className="d-flex p-2">
                                <Form.Check
                                    className="me-2"
                                    name='isOnSale'
                                    onChange={(e) => onChangeCheckbox(e)}
                                    checked={values.isOnSale == true ? true : false}
                                    disabled={values.isSoon == true || values.isNew == true ? true : false}
                                />Na akciji?
                            </div>
                            {values.isOnSale == true &&
                                <Form.Group className="p-2">
                                    <Form.Label>Procenat:</Form.Label>
                                    <Form.Control
                                        name='saleProcent'
                                        type='number'
                                        value={values['saleProcent']}
                                        onChange={(e) => onChangeFunction(e)}
                                    />
                                </Form.Group>
                            }
                            {prodAttr.map((i, k) => (
                                <div className="d-flex p-2" key={k}>
                                    <Form.Group className="col-4 me-1">
                                        <Form.Label>Ikonica:</Form.Label>
                                        <Form.Select
                                            id="icon"
                                            name='icon'
                                            defaultValue={i.icon}
                                        >
                                            <option>Izaberi</option>
                                            <option value="https://smallbrother.rs/resource/autor.png">Autor</option>
                                            <option value="https://smallbrother.rs/resource/uzrast.png">Uzrast</option>
                                            <option value="https://smallbrother.rs/resource/tezina.png">Težina</option>
                                            <option value="https://smallbrother.rs/resource/izdavac.png">Izdavač</option>
                                            <option value="https://smallbrother.rs/resource/vreme-trajanja.png">Vreme trajanja</option>
                                            <option value="https://smallbrother.rs/resource/jezik-uputstvo.png">Jezik uputstvo</option>
                                            <option value="https://smallbrother.rs/resource/kompleksnost.png">Kompleksnost</option>
                                            <option value="https://smallbrother.rs/resource/broj-igraca.png">Broj igrača</option>
                                            <option value="https://smallbrother.rs/resource/dimenzije-sliv.png">Dimenzija slivova</option>
                                            <option value="https://smallbrother.rs/resource/debljina-sliv.png">Debljina sliva</option>
                                            <option value="https://smallbrother.rs/resource/broj-sliv.png">Broj slivova</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="col-6">
                                        <Form.Label>Opis:</Form.Label>
                                        <Form.Control
                                            name='text'
                                            type='text'
                                            id="text"
                                            value={i.text}
                                        />
                                    </Form.Group>
                                    <div className="col-2 d-flex justify-content-center align-items-center admin-minus-holder">
                                        <FontAwesomeIcon icon={faMinus} onClick={() => showLess(i, k)} className="admin-minus" />
                                    </div>
                                </div>
                            ))}
                            {[...Array(showMoreSelect)].map((_, index) => (
                                <div className="d-flex p-2" key={index}>
                                    <Form.Group className="col-4 me-1">
                                        <Form.Label>Ikonica:</Form.Label>
                                        <Form.Select
                                            id="icon"
                                            name={`icon`}
                                            value={icons[index]}
                                            onChange={(e) => onChangeIcon(e, index)}
                                        >
                                            <option>Izaberi</option>
                                            <option value="https://smallbrother.rs/resource/autor.png">Autor</option>
                                            <option value="https://smallbrother.rs/resource/uzrast.png">Uzrast</option>
                                            <option value="https://smallbrother.rs/resource/tezina.png">Težina</option>
                                            <option value="https://smallbrother.rs/resource/izdavac.png">Izdavač</option>
                                            <option value="https://smallbrother.rs/resource/vreme-trajanja.png">Vreme trajanja</option>
                                            <option value="https://smallbrother.rs/resource/jezik-uputstvo.png">Jezik uputstvo</option>
                                            <option value="https://smallbrother.rs/resource/kompleksnost.png">Kompleksnost</option>
                                            <option value="https://smallbrother.rs/resource/broj-igraca.png">Broj igrača</option>
                                            <option value="https://smallbrother.rs/resource/dimenzije-sliv.png">Dimenzija slivova</option>
                                            <option value="https://smallbrother.rs/resource/debljina-sliv.png">Debljina sliva</option>
                                            <option value="https://smallbrother.rs/resource/broj-sliv.png">Broj slivova</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="col-6">
                                        <Form.Label>Opis:</Form.Label>
                                        <Form.Control
                                            name="text"
                                            type='text'
                                            id="text"
                                            value={iconsText[index]}
                                            onChange={(e) => onChangeIcon(e, index)}
                                        />
                                    </Form.Group>
                                    <div className="col-2 d-flex justify-content-center align-items-center admin-minus-holder">
                                        <FontAwesomeIcon icon={faMinus} onClick={() => showLess(null, index)} className="admin-minus" />
                                    </div>
                                </div>
                            ))}
                            <div className='d-flex align-items-center p-2' style={{ cursor: "pointer", width: "fit-content" }} onClick={() => showMore()}>
                                <FontAwesomeIcon icon={faPlus} className="me-2" />Dodaj još
                            </div>
                            <Form.Group className="p-2">
                                <Form.Label>Slike:</Form.Label>
                                <Form.Control
                                    name='images'
                                    type='file'
                                    multiple
                                    onChange={(e) => handleFileInput(e)}
                                    onClick={resetFileValue}
                                />
                                <div className="d-flex">
                                    {values.images.length > 0 &&
                                        values.images.map((i, k) => {
                                            const urlParts = i.split('/');
                                            const imageName = urlParts[urlParts.length - 1];
                                            return <div className="d-flex justify-content-center align-items-center m-2">
                                                <span className='me-2'>{imageName}</span>
                                                <FontAwesomeIcon icon={faMinus} onClick={() => deleteImg(k)} />
                                            </div>
                                        })
                                    }
                                </div>
                            </Form.Group>
                            <Form.Group className="p-2">
                                <Form.Label>Video:</Form.Label>
                                <Form.Control
                                    name='videos'
                                    type='text'
                                    value={values['videos']}
                                    onChange={(e) => onChangeFunction(e)}
                                />
                            </Form.Group>
                            <Form.Group className="p-2">
                                <Form.Label>Količina:</Form.Label>
                                <Form.Control
                                    name='quantity'
                                    type='number'
                                    value={values['quantity']}
                                    onChange={(e) => onChangeFunction(e)}
                                />
                            </Form.Group>
                            <Form.Group className="p-2">
                                <Form.Label>Cena:</Form.Label>
                                <Form.Control
                                    name='price'
                                    type='number'
                                    value={values['price']}
                                    onChange={(e) => onChangeFunction(e)}
                                />
                            </Form.Group>
                            <Form.Group className="p-2">
                                <Form.Label>Opis:</Form.Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={values['description']}
                                    onChange={(event, editor) => handleEditorDataChange(event, editor, "description")}
                                />
                            </Form.Group>
                            <Form.Group className="p-2">
                                <Form.Label>Seo opis proizvoda:</Form.Label>
                                <Form.Control
                                    name='seoDescription'
                                    type='text'
                                    value={values['seoDescription']}
                                    onChange={(e) => onChangeFunction(e)}
                                />
                            </Form.Group>
                            <Form.Group className="p-2">
                                <Form.Label>Deklaracija:</Form.Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={values['declaration']}
                                    onChange={(event, editor) => handleEditorDataChange(event, editor, "declaration")}
                                />
                            </Form.Group>
                            {getAdminCat.length > 0 &&
                                <div className="p-2">
                                    <Autocomplete
                                        multiple
                                        options={getAdminCat}
                                        getOptionLabel={(option) => option.name}
                                        value={values["categories"]}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Izaberite kategorije"
                                            />
                                        )}
                                        onChange={(event, val) => {
                                            onChangeAutocomplete("categories", val)
                                        }}
                                    />
                                </div>
                            }
                            {getAdminProd.length > 0 &&
                                <>
                                    <div className="p-2">
                                        <Autocomplete
                                            multiple
                                            options={getAdminProd}
                                            getOptionLabel={(option) => option.name}
                                            value={values["similars"]}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Izaberite slične proizvode"
                                                />
                                            )}
                                            onChange={(event, val) => {
                                                onChangeAutocomplete("similars", val)
                                            }}
                                        />
                                    </div>
                                    <div className="p-2">
                                        <Autocomplete
                                            multiple
                                            options={getAdminProd}
                                            getOptionLabel={(option) => option.name}
                                            value={values["accessories"]}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Izaberite dodatke"
                                                />
                                            )}
                                            onChange={(event, val) => {
                                                onChangeAutocomplete("accessories", val)
                                            }}
                                        />
                                    </div>
                                </>
                            }
                            <div className="d-flex p-2">
                                <Form.Check
                                    className="me-2"
                                    name='isActive'
                                    onChange={(e) => onChangeCheckbox(e)}
                                    checked={values.isActive == true ? true : false}
                                />Aktivan?
                            </div>
                            <div className="mt-2 text-center">
                                <button type='submit' className="btn btn-sm btn-success col-5 m-2" onClick={(e) => submitButton(e)}>
                                    {modalContent == 'edit' ? "PROMENI" : modalContent == "create" && "KREIRAJ"}
                                </button>
                                <button type='button' className="btn btn-sm btn-danger col-5 m-2" onClick={() => handleCloseModal(false)}>ODUSTANI</button>
                            </div>
                        </form>
                    </div>
                }
            </Modal.Body>
        </Modal>
    )
}

export default ProductsModal