import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import FormValidation from './FormValidation';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha'

import { login, registration, forgotPass } from '../redux/backCalls';

const UserModal = ({ showModal, handleCloseModal }) => {

    const dispatch = useDispatch()
    const recaptcha = useRef()
    const [modalContent, setModalContent] = useState("login")
    const [validPassword, setValidPassword] = useState(0)
    const [validCaptcha, setValidCaptcha] = useState(0)
    const [values, setValues] = useState({})
    const [validInputValue, setValidInputValue] = useState({})
    const [errors, setErrors] = useState({})

    const setErrorsFunction = (name, val) => {
        setErrors({ ...errors, [name]: val });
    };

    const onChangeFunction = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });

        if (Object.keys(errors).includes(e.target.name)) {
            setErrorsFunction(e.target.name, 0)
        }

        if ([e.target.name] == "password_repeat") {
            if (e.target.value !== values.password) {
                setValidPassword(1)
                setErrorsFunction(e.target.name, 1)
            } else {
                setValidPassword(2)
                setErrorsFunction(e.target.name, 0)
            }
        }
        if (modalContent == "registration" && [e.target.name] == "password") {
            if (e.target.value !== values.password_repeat) {
                setErrorsFunction("password_repeat", 1)
            } else {
                setErrorsFunction("password_repeat", 0)
            }
        }
    }

    const onChangeCheckbox = (e) => {
        setValues({ ...values, [e.target.name]: e.target.checked })

        if (e.target.name == "agree_check") {
            if (e.target.checked) {
                setErrorsFunction(e.target.name, 0)
                setValidInputValue({ ...validInputValue, [e.target.name]: 2 })
            } else {
                setErrorsFunction(e.target.name, 1)
                setValidInputValue({ ...validInputValue, [e.target.name]: 1 })
            }
        }
    }

    const submitButton = (e) => {
        e.preventDefault();

        if (modalContent == "registration") {
            if (values.password_repeat != "") {
                setValidPassword(values.password_repeat != values.password ? 1 : 2)
            } else {
                setValidPassword(1)
            }

            setValidInputValue({
                ...validInputValue,
                email: values.email == "" || !values["email"].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? 1 : 2,
                password: values.password == "" ? 1 : 2,
                agree_check: values.agree_check == false ? 1 : 2
            })
        } else if (modalContent == "login") {
            setValidInputValue({
                ...validInputValue,
                email: values.email == "" || !values["email"].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? 1 : 2,
                password: values.password == "" ? 1 : 2,
            })
        } else {
            setValidInputValue({
                ...validInputValue,
                email: values.email == "" || !values["email"].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? 1 : 2,
            })
        }

        const err = Object.values(errors);

        if (modalContent == "login") {
            err.every(value => value === 0) && dispatch(login(values)) && handleCloseModal(false)
        } else if (modalContent == "registration") {
            const captchaValue = recaptcha.current.getValue()
            if (!captchaValue) {
                setValidCaptcha(1)
            } else {
                setValidCaptcha(2)
                err.every(value => value === 0) && dispatch(registration(values)) && handleCloseModal(false)
            }
        } else {
            err.every(value => value === 0) && dispatch(forgotPass(values)).then(res => { res && handleCloseModal(false) })
        }
    }

    useEffect(() => {
        if (modalContent == "login") {
            setValues({
                email: "",
                password: ""
            })

            setValidInputValue({
                email: 0,
                password: 0,
            })

            setErrors({
                email: 1,
                password: 1,
            })
        }

        if (modalContent == "registration") {
            setValues({
                email: "",
                password: "",
                password_repeat: "",
                agree_check: false
            })

            setValidInputValue({
                email: 0,
                password: 0,
                agree_check: 0
            })

            setErrors({
                email: 1,
                password: 1,
                password_repeat: 1,
                agree_check: 1
            })

            setValidPassword(0)
        }

        if (modalContent == "password") {
            setValues({
                email: ""
            })

            setValidInputValue({
                email: 0
            })

            setErrors({
                email: 1
            })
        }
    }, [modalContent])

    return (
        <Modal
            show={showModal}
            onHide={() => handleCloseModal(false)}
            keyboard={false}
            dialogClassName='modal-md'
            aria-labelledby=""
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="text-center">
                        {modalContent == "login" ? "PRIJAVA" : modalContent == "registration" ? "REGISTRACIJA" : modalContent == "password" ? "ZABORAVLJENA LOZINKA" : ""}
                    </div>
                </Modal.Title>
                <FontAwesomeIcon icon={faXmark} onClick={() => handleCloseModal(false)} className="close-modal" />
            </Modal.Header>
            <Modal.Body>
                <div className="row d-flex justify-content-center">
                    <form className="col-sm-12" noValidate onSubmit={submitButton}>
                        <FormValidation
                            name='email'
                            type='email'
                            label={modalContent == "registration" ? "*Email adresa:" : "Email adresa:"}
                            nameClass="fw-bold"
                            value={values['email']}
                            onChange={(e) => onChangeFunction(e)}
                            validInputValue={validInputValue.email}
                            // errorMessage="*E-mail adresa nije validna!"
                            setErrorsFunction={setErrorsFunction}
                            modalContent={modalContent}
                        />
                        {modalContent == "login" || modalContent == "registration" ?
                            <FormValidation
                                name='password'
                                type='password'
                                label={modalContent == "registration" ? "*Lozinka:" : "Lozinka:"}
                                nameClass="fw-bold"
                                value={values['password']}
                                onChange={(e) => onChangeFunction(e)}
                                validInputValue={validInputValue.password}
                                // errorMessage="*Obavezno polje!"
                                setErrorsFunction={setErrorsFunction}
                                modalContent={modalContent}
                            />
                            :
                            null
                        }
                        {modalContent == "registration" &&
                            <>
                                <div className="p-1">
                                    <label htmlFor="name" className="form-label fw-bold">*Ponovite lozinku:</label>
                                    <Form.Control
                                        name='password_repeat'
                                        type='password'
                                        id="password_repeat"
                                        value={values['password_repeat']}
                                        onChange={(e) => onChangeFunction(e)}
                                        isInvalid={validPassword == 1}
                                    // isValid={validPassword == 2}
                                    />
                                    {/* {validPassword == 1 &&
                                        <div className="invalid-feedback d-block">
                                            Lozinka nije ista!
                                        </div>
                                    } */}
                                </div>
                                <div className="row p-1">
                                    <div className="col-sm-6">
                                        <label htmlFor="name" className="form-label fw-bold">Ime:</label>
                                        <Form.Control
                                            name='name'
                                            type='text'
                                            id="name"
                                            value={values['name']}
                                            onChange={(e) => onChangeFunction(e)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="surname" className="form-label fw-bold">Prezime:</label>
                                        <Form.Control
                                            name='surname'
                                            type='text'
                                            id="surname"
                                            value={values['surname']}
                                            onChange={(e) => onChangeFunction(e)}
                                        />
                                    </div>
                                </div>
                                <label htmlFor="phoneNumber" className="form-label fw-bold p-1">Telefon:</label>
                                <Form.Control
                                    name='phoneNumber'
                                    type='number'
                                    id="phoneNumber"
                                    value={values['phoneNumber']}
                                    className="p-1"
                                    onChange={(e) => onChangeFunction(e)}
                                />
                                <label htmlFor="country" className="form-label fw-bold p-1">Država:</label>
                                <Form.Control
                                    name='country'
                                    type='text'
                                    id="country"
                                    value={values['country']}
                                    className="p-1"
                                    onChange={(e) => onChangeFunction(e)}
                                />
                                <div className="row p-1">
                                    <div className="col-sm-8">
                                        <label htmlFor="city" className="form-label fw-bold">Grad:</label>
                                        <Form.Control
                                            name='city'
                                            type='text'
                                            id="city"
                                            value={values['city']}
                                            onChange={(e) => onChangeFunction(e)}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <label htmlFor="postCode" className="form-label fw-bold">Poštanski broj:</label>
                                        <Form.Control
                                            name='postCode'
                                            type='number'
                                            id="postCode"
                                            value={values['postCode']}
                                            onChange={(e) => onChangeFunction(e)}
                                        />
                                    </div>
                                </div>
                                <label htmlFor="address" className="form-label p-1 fw-bold">Ulica i broj:</label>
                                <Form.Control
                                    name='address'
                                    type='text'
                                    id="address"
                                    value={values['address']}
                                    className="p-1"
                                    onChange={(e) => onChangeFunction(e)}
                                />
                                <div className="row p-2">
                                    <div className={`p-1 d-flex fw-bold policy-check ${validInputValue.agree_check == 1 ? "not-valid-policy-check" : ""}`}>
                                        <Form.Check
                                            required
                                            className="me-2"
                                            name='agree_check'
                                            onChange={(e) => onChangeCheckbox(e)}
                                            isInvalid={validInputValue.agree_check == 1}
                                            // // isValid={validInputValue.agree_check == 2}
                                            checked={values.agree_check == true ? true : false}
                                        />*Slažem se sa&nbsp;<Link to={"/uslovi-kupovine"} onClick={() => handleCloseModal(false)}>uslovima korišćenja</Link>
                                        {/* {validInputValue.agree_check == 1 &&
                                            <div className="invalid-feedback d-block">
                                                *Obavezno polje!
                                            </div>
                                        } */}
                                    </div>
                                    <div className="p-1">
                                        <Form.Check
                                            required
                                            className="me-2 fw-bold"
                                            name='wantsNewsletter'
                                            label="Želim da se prijavim na newsletter"
                                            onChange={(e) => onChangeCheckbox(e)}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        {modalContent == 'login' &&
                            <div className="col-sm-12 mt-3">
                                <span className="p-2 forgot-pass" onClick={() => setModalContent("password")}>Zaboravili ste lozinku?</span>
                            </div>
                        }
                        {modalContent == 'registration' &&
                            <>
                                <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_CAPTCHA_KEY} />
                                {validCaptcha == 1 &&
                                    <div className="invalid-feedback d-block">
                                        Potrebna reCAPTCHA verifikacija!
                                    </div>
                                }
                            </>
                        }
                        <div className="col-sm-12 mt-2 p-3">
                            <button type='submit' className="btn btn-dark login-button" onClick={(e) => submitButton(e)}>
                                {modalContent == 'login' ? "PRIJAVA" : modalContent == "registration" ? "REGISTRACIJA" : modalContent == "password" ? "Pošalji novu lozinku" : ""}
                            </button>
                        </div>
                    </form>
                    {modalContent == 'login' &&
                        <div className="col-sm-12 p-3 text-center">
                            <button type='button' className="btn login-button registration-button mt-2" onClick={() => setModalContent("registration")}>
                                REGISTRUJTE SE
                            </button>
                        </div>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default UserModal