import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlay, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import YouTube from 'react-youtube';
import ReactImageMagnify from 'react-image-magnify';
import useMediaQuery from '@mui/material/useMediaQuery';

const ProductSlider = ({ getProductInfo }) => {

    const [youtubeImage, setYoutubeImage] = useState([]);
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    const productImages = Object.keys(getProductInfo).length > 0 && getProductInfo.images
    const productVideo = Object.keys(getProductInfo).length > 0 ? getProductInfo.videos : []

    const queryMobile = useMediaQuery('(max-width: 430px)');

    let youtubeKey = process.env.REACT_APP_YT_KEY;

    const youtubeApiFunction = () => {
        let youtubeCode = productVideo.length > 0 ? productVideo.map(i => i.split("=")[1]) : [];


        youtubeCode.map(item => {
            let youtubeApi = 'https://www.googleapis.com/youtube/v3/videos?key=' + youtubeKey + '&part=snippet&id=' + item + '';

            axios.get(youtubeApi, { withCredentials: false }).then((response) => {
                if(response.data.items.length > 0) {
                    setYoutubeImage([response.data.items[0].snippet.thumbnails.high.url]);
                }
            });
        })
    }

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <FontAwesomeIcon
                icon={faChevronRight}
                className={className}
                style={{ ...style, right: '-40px', top: '45%', color: "#000" }}
                onClick={onClick}
            />
        );
    };

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <FontAwesomeIcon
                icon={faChevronLeft}
                className={className}
                style={{ ...style, left: '-40px', top: '45%', color: "#000" }}
                onClick={onClick}
            />

        );
    };

    useEffect(() => {
        if(productVideo.length > 0) {
            youtubeApiFunction();
        } else {
            setYoutubeImage([])
        }
    }, [productVideo])

    const settings = {
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        className: "text-center",
        centerMode: false,
        centerPadding: "60px",
        slidesToShow: 1,
        arrows: false,
    };

    const settings2 = {
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const opts = {
        height: queryMobile ? '320' : '450',
        width: queryMobile ? '320' : '450',
        playerVars: {
            autoplay: 0,
        },
    };

    const containerSize = queryMobile ? 320 : 450;
    const aspectRatio = 5 / 4;

    const calculateDimensions = () => {
        const width = containerSize;
        const height = containerSize / aspectRatio;
        return { width, height };
    };

    const { width, height } = calculateDimensions();

    return (
        <>
            {productImages.length > 1 ?
                <>
                    <Slider
                        {...settings}
                        asNavFor={nav2}
                        ref={(slider1) => setNav1(slider1)}
                    >
                        {productImages.map((item, key) => (
                            <div key={key} className="product-page-image">
                                <ReactImageMagnify
                                    {...{
                                        smallImage: {
                                            alt: 'Small Brother product images',
                                            src: item,
                                            width: width,
                                            height: height,
                                        },
                                        largeImage: {
                                            src: item,
                                            width: width * 3,
                                            height: height * 3,
                                        },
                                        lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' },
                                        enlargedImagePosition: 'over',
                                    }}
                                />
                            </div>
                        ))}
                        {productVideo.length > 0 && productVideo.map((item, key) => (
                            <div key={key}>
                                <YouTube videoId={item.split("=")[1]} opts={opts} />
                            </div>
                        ))}
                    </Slider>
                    <Slider
                        {...settings2}
                        asNavFor={nav1}
                        ref={(slider2) => setNav2(slider2)}
                        slidesToShow={productImages.length + youtubeImage.length > 3 ? 3 : productImages.length + youtubeImage.length}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        className={`second-mini-slider second-mini-slider-${productImages.length + youtubeImage.length > 3 ? 3 : productImages.length + youtubeImage.length}`}
                    >
                        {productImages.map((item, key) => (
                            <div className="item-list-slider-product" key={key}>
                                <div className="item-mini-images-product">
                                    <img src={item} loading='lazy' alt={item.name + 'box cover'} className="mini-images-slider-product" />
                                </div>
                            </div>
                        ))}
                        {youtubeImage.length > 0 && youtubeImage.map((item, key) => (
                            <div className="item-list-slider-product" key={key}>
                                <div className="item-mini-images-product">
                                    <img src={item} loading='lazy' alt={item.name + 'box cover'} className="mini-images-slider-product" />
                                    <FontAwesomeIcon icon={faCirclePlay} className="small-play-icon" />
                                </div>
                            </div>
                        ))}
                    </Slider>
                </>
                :
                <img src={productImages[0]} loading='lazy' alt='Small Brother games' className="product-page-image img-fluid" />
            }
        </>
    )
}

export default ProductSlider