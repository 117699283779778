import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { getAdminBlogs, getBlog } from '../redux/backCalls';

import CategoriesModal from './CategoriesModal';
import BlogsModal from './BlogsModal';

const AdminBlogs = () => {

    const dispatch = useDispatch()
    const getAdminBlo = useSelector((state) => state.adminBlogs);

    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState("edit");
    const [blog, setBlog] = useState({});

    const handleCloseModal = (val, cont, blo) => {
        setModalContent(cont)
        if (val == true && (cont == "edit" || cont == "delete")) {
            dispatch(getBlog(blo.blogId))
                .then(res => {
                    setBlog(res)
                    setShowModal(true);
                })
        } else {
            setShowModal(val)
            setBlog({})
        }
    };

    useEffect(() => {
        dispatch(getAdminBlogs())
    }, [])

    return (
        <>
            <div className="d-flex justify-content-end m-1">
                <button className='btn btn-sm btn-dark' onClick={() => handleCloseModal(true, "create")}>Kreiraj</button>
            </div>
            {getAdminBlo.length > 0 ?
                <table className="table table-hover mt-4">
                    <thead>
                        <tr>
                            <th scope="col">Naziv bloga</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {getAdminBlo.map((i, k) => (
                            <tr key={k}>
                                <td>{i.name}</td>
                                <td>
                                    <button className='btn btn-sm btn-dark' onClick={() => handleCloseModal(true, "edit", i)}>Promeni</button>
                                </td>
                                <td>
                                    <button className='btn btn-sm btn-dark' onClick={() => handleCloseModal(true, "delete", i)}>Obriši</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                :
                null
            }
            {showModal &&
                <BlogsModal showModal={showModal} modalContent={modalContent} handleCloseModal={handleCloseModal} blog={blog} />
            }
        </>
    )
}

export default AdminBlogs