import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';

import MessageInfoBox from './MessageInfoBox';

import { uploadHomeVideo } from '../redux/backCalls';

const AdminVideoModal = ({ showModal, handleCloseModal }) => {

    const dispatch = useDispatch()
    const formData = new FormData();

    const handleFileInput = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        Object.values(e.target.files).map(i => {
            formData.append('file', i);
        })
    }

    const resetFileValue = (e) => {
        e.target.value = null
    }

    const submitButton = (e) => {
        e.preventDefault();

        dispatch(uploadHomeVideo(formData))
            .then(res => {
                toast(<MessageInfoBox message={res.message} type={res.isOk} />, {
                    className: res.isOk == true ? 'message-success' : 'message-error',
                });
                setTimeout(() => {
                    handleCloseModal()
                }, 1500)
            })
    }

    return (
        <Modal
            show={showModal}
            onHide={() => handleCloseModal()}
            keyboard={false}
            dialogClassName='modal-lg'
            aria-labelledby=""
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="text-center">
                        PROMENI VIDEO
                    </div>
                </Modal.Title>
                <FontAwesomeIcon icon={faXmark} onClick={() => handleCloseModal()} className="close-modal" />
            </Modal.Header>
            <Modal.Body>
                <div className="row d-flex justify-content-center">
                    <form className="col-sm-12" noValidate onSubmit={submitButton}>
                        <Form.Group className="p-2">
                            <Form.Label>Video:</Form.Label>
                            <Form.Control
                                name='image'
                                type='file'
                                onChange={(e) => handleFileInput(e)}
                                onClick={resetFileValue}
                            />
                        </Form.Group>
                        <div className="mt-2 text-center">
                            <button type='submit' className="btn btn-sm btn-success col-5 m-2" onClick={(e) => submitButton(e)}>
                                PROMENI
                            </button>
                            <button type='button' className="btn btn-sm btn-danger col-5 m-2" onClick={() => handleCloseModal(false)}>ODUSTANI</button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AdminVideoModal