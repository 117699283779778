import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Helmet from 'react-helmet'

import { getBlog } from '../redux/backCalls'

const BlogPage = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const { blogId } = params;

    const [blog, setBlog] = useState({});

    useEffect(() => {
        if(isNaN(+blogId)) {
            navigate("/");
        }
        dispatch(getBlog(blogId))
            .then(res => {
                setBlog(res)
            })
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {Object.keys(blog).length > 0 ?
                <div className="container-fluid main-contaner">
                    <Helmet>
                        <title>{blog.name}</title>
                        <meta name='keywords' content='blog informacije vesti novosti news info'></meta>
                    </Helmet>
                    {blog.banner != "" &&
                        <div className="row mb-lg-5">
                            <div className="col-sm-12 blog-page-banner-holder">
                                <img src={blog.banner} alt="Blog banner" />
                            </div>
                        </div>
                    }
                    <div className='container'>
                        <div className="row text-center mt-3 mb-1">
                            <h3>{blog.name}</h3>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                {blog.blogContents.map((item, key) => (
                                    item.type == 1 ?
                                        <p className="mt-3 mt-lg-5 mb-3" dangerouslySetInnerHTML={{ __html: item.value }} />
                                        : item.type == 2 &&
                                        <div className="mt-3 mt-lg-5 mb-3 text-center">
                                            <img src={item.value} alt='Blog image' className="img-fluid blog-item-image" />
                                        </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
        </>
    )
}

export default BlogPage