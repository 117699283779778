import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import FormValidation from '../components/FormValidation';

import { changePass } from '../redux/backCalls';
import { Helmet } from 'react-helmet';

const ForgotPasswordPage = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams();

    const [validPassword, setValidPassword] = useState(0)
    const [values, setValues] = useState({
        newPassword: "",
        password_repeat: ""
    })
    const [validInputValue, setValidInputValue] = useState({
        newPassword: 0
    })
    const [errors, setErrors] = useState({
        newPassword: 1,
        password_repeat: 1
    })

    const { token } = params;

    const setErrorsFunction = (name, val) => {
        setErrors({ ...errors, [name]: val });
    };

    const onChangeFunction = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });

        if(Object.keys(errors).includes(e.target.name)) {
            setErrorsFunction(e.target.name, 0)
        }

        if([e.target.name] == "password_repeat") {
            if(e.target.value !== values.newPassword) {
                setValidPassword(1)
                setErrorsFunction(e.target.name, 1)
            } else {
                setValidPassword(2)
                setErrorsFunction(e.target.name, 0)
            }
        }

        if([e.target.name] == "newPassword") {
            if(e.target.value !== values.password_repeat) {
                setErrorsFunction("password_repeat", 1)
            } else {
                setErrorsFunction("password_repeat", 0)
            }
        }
    }

    const submitButton = (e) => {
        e.preventDefault();

        if(values.password_repeat != "") {
            setValidPassword(values.password_repeat !== values.newPassword ? 1 : 2)
        } else {
            setValidPassword(1)
        }

        setValidInputValue({
            ...validInputValue,
            newPassword: values.newPassword == "" ? 1 : 2,
        })

        const err = Object.values(errors);

        err.every(value => value === 0) && dispatch(changePass(values.newPassword, token)).then(res => { res && navigate("/") })
    }

    return (
        <div className="container change-pass-holder">
            <Helmet>
                <title>Zaboravljena lozika</title>
            </Helmet>
            <div className="row vertical-aligment">
                {/* <div className="row d-flex justify-content-center"> */}
                <form className="col-sm-12" noValidate autoComplete='off'>
                    <FormValidation
                        name='newPassword'
                        type='password'
                        label="Nova lozinka:"
                        nameClass="fw-bold"
                        value={values['newPassword']}
                        onChange={(e) => onChangeFunction(e)}
                        validInputValue={validInputValue.newPassword}
                        setErrorsFunction={setErrorsFunction}
                    />
                    <div className="p-1">
                        <label htmlFor="name" className="form-label fw-bold">Ponovite lozinku:</label>
                        <Form.Control
                            name='password_repeat'
                            type='password'
                            id="password_repeat"
                            value={values['password_repeat']}
                            onChange={(e) => onChangeFunction(e)}
                            isInvalid={validPassword == 1}
                        />
                    </div>
                </form>
                {/* </div> */}
                {/* <div className="row d-flex justify-content-center"> */}
                <div className="col-sm-12 mt-2 p-3 w-50" style={{ margin: "0 auto" }}>
                    <button type='button' className="btn btn-dark login-button" onClick={(e) => submitButton(e)}>
                        Promeni lozinku
                    </button>
                </div>
                {/* </div> */}
            </div>
        </div>
    )
}

export default ForgotPasswordPage