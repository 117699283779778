import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const CatalogPageSize = ({ pageSize, setPageSize, setCurrentPage }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

    const onChangeFunction = (val) => {
        setPageSize(val)
        if (val == 24) {
            searchParams.delete('prikazi');
        } else {
            searchParams.set('prikazi', val);
        }
        searchParams.delete('str');
        setCurrentPage(0)
        navigate({ search: `?${searchParams.toString()}` })
    }

    return (
        <div className="sort-holder">
            <span>Prikaži:</span>
            <select className="form-select form-select-sm show-articles"
                value={pageSize}
                onChange={(e) => onChangeFunction(e.target.value)}
            >
                <option value={24}>24</option>
                <option value={48}>48</option>
                <option value={72}>72</option>
            </select>
        </div>
    )
}

export default CatalogPageSize