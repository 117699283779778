import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import { uploadFile, createHomePagePic, deleteHomePagePic } from '../redux/backCalls';

const AdminPicModal = ({ showModal, modalContent, handleCloseModal, pic }) => {

    const dispatch = useDispatch()
    const [values, setValues] = useState({
        homePictureId: 0,
        image: "",
        href: "",
    })

    const imgPart = values.image.split('/');
    const imageName = imgPart[imgPart.length - 1];

    const onChangeFunction = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    const handleFileInput = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        const formData = new FormData();
        Object.values(e.target.files).map(i => {
            formData.append('file', i);
        })

        dispatch(uploadFile(formData))
            .then(res => {
                setValues({ ...values, [e.target.name]: res.toString() })
            })
    }

    const resetFileValue = (e) => {
        e.target.value = null
    }

    const submitButton = (e) => {
        e.preventDefault();

        dispatch(createHomePagePic(values))
        handleCloseModal(false)
    }

    const deleteImg = () => {
        dispatch(deleteHomePagePic(pic))
        handleCloseModal(false)
    }

    return (
        <Modal
            show={showModal}
            onHide={() => handleCloseModal(false)}
            keyboard={false}
            dialogClassName={modalContent == "delete" ? 'modal-md' : 'modal-lg'}
            aria-labelledby=""
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="text-center">
                        {modalContent == "edit" ? "PROMENI SLIKU" : modalContent == "delete" ? "OBRIŠI SLIKU" : "KREIRAJ SLIKU"}
                    </div>
                </Modal.Title>
                <FontAwesomeIcon icon={faXmark} onClick={() => handleCloseModal(false)} className="close-modal" />
            </Modal.Header>
            <Modal.Body>
                {modalContent == "delete" ?
                    <div className="text-center">
                        <p>Da li ste sigurni da želite da obrišete sliku?</p>
                        <div className="row d-flex justify-content-center mt-2">
                            <button type='button' className="btn btn-sm btn-success col-5 m-2" onClick={() => deleteImg()}>Da</button>
                            <button type='button' className="btn btn-sm btn-danger col-5 m-2" onClick={() => handleCloseModal(false)}>Ne</button>
                        </div>
                    </div>
                    :
                    <div className="row d-flex justify-content-center">
                        <form className="col-sm-12" noValidate onSubmit={submitButton}>
                            <Form.Group className="p-2">
                                <Form.Label>Slika:</Form.Label>
                                <Form.Control
                                    name='image'
                                    type='file'
                                    onChange={(e) => handleFileInput(e)}
                                    onClick={resetFileValue}
                                />
                            </Form.Group>
                            {values.image != "" &&
                                <span className='m-2'>{imageName}</span>
                            }
                            <Form.Group className="p-2">
                                <Form.Label>Link:</Form.Label>
                                <Form.Control
                                    name='href'
                                    type='text'
                                    value={values['href']}
                                    onChange={(e) => onChangeFunction(e)}
                                />
                            </Form.Group>
                            <div className="mt-2 text-center">
                                <button type='submit' className="btn btn-sm btn-success col-5 m-2" onClick={(e) => submitButton(e)}>
                                    {modalContent == 'edit' ? "PROMENI" : modalContent == "create" && "KREIRAJ"}
                                </button>
                                <button type='button' className="btn btn-sm btn-danger col-5 m-2" onClick={() => handleCloseModal(false)}>ODUSTANI</button>
                            </div>
                        </form>
                    </div>
                }
            </Modal.Body>
        </Modal>
    )
}

export default AdminPicModal