import React from 'react'
import Helmet from 'react-helmet'

const AboutUsPage = () => {
  return (
    <div className='main-contaner about-container'>
      <Helmet>
        <title>O nama</title>
        <meta name='description' content='Grupa zaljubljenika u društvene igre se skuplja oko svog zajedničkog hobija već godinama unazad.
                    Nakon izlaska iz perioda Covid hibernacije, 2022. godine su se sve kockice složile te
                    dolazimo do realizacije ideje da „zagrebemo“ više ovaj svet i osnujemo Small Borther zajednicu.'></meta>
        <meta name='keywords' content='small brother about us o nama informacije info radno vreme working hours lokacija location'></meta>
      </Helmet>
      <section className='about-banner'>
        <div className='container'>
          <div className='about-title'>
            <h1>O nama</h1>
            <p>Upoznajmo se, ko smo mi?</p>
          </div>
        </div>
      </section>
      <div className='about-main'>
        <section className='section-1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-5'>
                <div className='about-text'>
                  <h2>Small Brother Games</h2>
                  <p>
                    Grupa zaljubljenika u društvene igre se skuplja oko svog zajedničkog hobija već godinama unazad.
                    Nakon izlaska iz perioda Covid hibernacije, 2022. godine su se sve kockice složile te
                    dolazimo do realizacije ideje da „zagrebemo“ više ovaj svet i osnujemo Small Borther zajednicu.
                  </p>
                  <p>
                    Danas nam je cilj da se intenzivnije i sveobuhvatnije uložimo u ono što nas pokreće, te društvene
                    igre predstavimo većem broju ljudi i učinimo ih lako dostupnim.
                  </p>
                </div>
              </div>
              <div className='col-lg-7'>
                <div className='d-flex align-items-center justify-content-end'>
                  <img src="/img-about-1.png" alt="Small Brother about us" className='img-fluid about-img' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='section-2'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-7'>
                <img src="/img-about-2.png" alt="Small Brother about us " className='img-fluid float-left about-img' />
              </div>
              <div className='col-lg-5'>
                <div className='about-text'>
                  <h2>Naši ciljevi</h2>
                  <p>
                    Obezbeđivanje novih i atraktivnih naslova, adaptacija i prilagođavanje postojećih naslova lokalnim
                    uslovima, kao i kreiranje sopstvenih projekata u svetu igara su primarni pokretači koji su nas
                    doveli ovde gde smo danas.
                  </p>
                  <p>
                    Bilo da tek otkrivate ovaj svet, ili ste već pasionirani zaljubljenik, tražite neki specifičan naslov,
                    imate ideju za svoj projekat… svratite u naše prostorije u Prizrenskoj 4a da pričamo o igrama!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='section-3'>
          <div className='container'>
            <div className='contact-title'>
              <h2>
                Kontaktirajte nas
              </h2>
              <p>Ukoliko imate nekih nedoumica ili bilo kakvih pitanja, tu smo za Vas. Pošaljite nam e-mail ili nas
                kontaktirajte na broj telefona.</p>
            </div>
            <div className='contact-text'>
              <div className='row'>
                <div className='col-lg-4'>
                  <h3>KONTAKT</h3>
                  <p>E-mail: info@smallbrother.rs</p>
                  <p>Telefon: 011 / 34 28 260</p>
                </div>
                <div className='col-lg-4'>
                  <h3>RADNO VREME</h3>
                  <p>Radnim Danima: 11:00 - 20:00</p>
                  <p>Subotom: 11:00 - 16:00</p>
                </div>
                <div className='col-lg-4'>
                  <h3>ADRESA PRODAVNICE</h3>
                  <p>Prizrenska 4a,</p>
                  <p>Beograd 1000, Srbija</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className='section-4'>
        <div className='google-map'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2830.3806794140696!2d20.45692017667657!3d44.813808776597725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7aadec2d3bc1%3A0xad918572bbed82dd!2sPrizrenska%204a%2C%20Beograd%2C%20Serbia!5e0!3m2!1sen!2sus!4v1709031228579!5m2!1sen!2sus"
            width="100%"
            height="520"
            allowfullscreen=""
            referrerpolicy="no-referrer-when-downgrade">

          </iframe>
        </div>
      </section>
    </div>
  )
}

export default AboutUsPage