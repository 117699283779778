import { createSlice } from '@reduxjs/toolkit';

export const account = createSlice({
    name: 'account',
    initialState: {},
    reducers: {
        account: (state, action) => action.payload,
    },
});

export const isLogged = createSlice({
    name: 'isLogged',
    initialState: localStorage.getItem('isLogged') ? localStorage.getItem('isLogged') : false,
    reducers: {
        isLogged: (state, action) => action.payload,
    },
});

export const catalog = createSlice({
    name: 'catalog',
    initialState: [],
    reducers: {
        catalogProducts: (state, action) => action.payload,
    },
});

export const catalogPages = createSlice({
    name: 'catalogPages',
    initialState: "",
    reducers: {
        pages: (state, action) => action.payload,
    },
});

export const catalogTotal = createSlice({
    name: 'catalogTotal',
    initialState: "",
    reducers: {
        totalProducts: (state, action) => action.payload,
    },
});

export const categories = createSlice({
    name: 'categories',
    initialState: [],
    reducers: {
        categoriesInfo: (state, action) => action.payload,
    },
});

export const product = createSlice({
    name: 'product',
    initialState: {},
    reducers: {
        productInfo: (state, action) => action.payload,
    },
});

export const cart = createSlice({
    name: 'cart',
    initialState: {},
    reducers: {
        cartInfo: (state, action) => action.payload,
    },
});

export const homePageNewProducts = createSlice({
    name: 'homePageNewProducts',
    initialState: [],
    reducers: {
        homePageNewProducts: (state, action) => action.payload,
    },
});

export const homePageBestSeller = createSlice({
    name: 'homePageBestSeller',
    initialState: [],
    reducers: {
        homePageBestSeller: (state, action) => action.payload,
    },
});

export const homePageLocalProducts = createSlice({
    name: 'homePageLocalProducts',
    initialState: [],
    reducers: {
        homePageLocalProducts: (state, action) => action.payload,
    },
});

export const homePageBannerSlider = createSlice({
    name: 'homePageBannerSlider',
    initialState: "",
    reducers: {
        homePageBannerSlider: (state, action) => action.payload,
    },
});

export const homePageBlogs = createSlice({
    name: 'homePageBlogs',
    initialState: [],
    reducers: {
        homePageBlogs: (state, action) => action.payload,
    },
});

export const homePageHideNav = createSlice({
    name: 'homePageHideNav',
    initialState: false,
    reducers: {
        homePageHideNav: (state, action) => action.payload,
    },
});

export const blogs = createSlice({
    name: 'blogs',
    initialState: [],
    reducers: {
        blogs: (state, action) => action.payload,
    },
});

export const searchResult = createSlice({
    name: 'searchResult',
    initialState: [],
    reducers: {
        searchResult: (state, action) => action.payload,
    },
});

export const searchQuery = createSlice({
    name: 'searchQuery',
    initialState: localStorage.getItem('searchQ') ? localStorage.getItem('searchQ') : "",
    reducers: {
        searchQuery: (state, action) => action.payload,
    },
});

export const shipping = createSlice({
    name: 'shipping',
    initialState: false,
    reducers: {
        shipping: (state, action) => action.payload,
    },
});

export const loyalty = createSlice({
    name: 'loyalty',
    initialState: false,
    reducers: {
        loyalty: (state, action) => action.payload,
    },
});

export const onlinePayment = createSlice({
    name: 'onlinePayment',
    initialState: true,
    reducers: {
        onlinePayment: (state, action) => action.payload,
    },
});

export const loyaltyUsers = createSlice({
    name: 'loyaltyUsers',
    initialState: [],
    reducers: {
        loyaltyUsers: (state, action) => action.payload,
    },
});

export const adminCategories = createSlice({
    name: 'adminCategories',
    initialState: [],
    reducers: {
        adminCategories: (state, action) => action.payload,
    },
});

export const adminProducts = createSlice({
    name: 'adminProducts',
    initialState: [],
    reducers: {
        adminProducts: (state, action) => action.payload,
    },
});

export const adminBlogs = createSlice({
    name: 'adminBlogs',
    initialState: [],
    reducers: {
        adminBlogs: (state, action) => action.payload,
    },
});

export const adminHomePageSlider = createSlice({
    name: 'adminHomePageSlider',
    initialState: [],
    reducers: {
        adminHomePageSlider: (state, action) => action.payload,
    },
});