import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";

const HomePageBanner = ({ items }) => {

    const navigate = useNavigate()
    const [mouseMoved, setMouseMoved] = useState(false);

    const handleClick = (val) => {
        if(!mouseMoved) {
            navigate(val);
        }
    }

    const settingsSlider = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        adaptiveHeight: false,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false
    };

    return (
        <div className="col-lg-8 text-center p-3">
            <div className="home-page-banner-holder">
                <Slider {...settingsSlider}>
                    {items.map((item, key) => (
                        <Link
                            onMouseMove={() => setMouseMoved(true)}
                            onMouseDown={() => setMouseMoved(false)}
                            onMouseUp={() => handleClick(item.href)}
                            key={key}
                        >
                            <img src={item.image} alt='Small Brother games homepage banner' className="img-fluid" />
                        </Link>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default HomePageBanner