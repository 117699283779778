import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faUser, faMagnifyingGlass, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { useDetectClickOutside } from 'react-detect-click-outside';

import { logoutFunction, search, getSession, getCart, getAccountInfo } from '../redux/backCalls'
import { searchQuery } from '../redux/slices';
import { getCookie } from '../helperFunctions';

import CartSmall from './CartSmall';
import UserModal from './UserModal';
import UserChange from './UserChange';

const Menu = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();

    const sessionCookie = getCookie('st');

    const [toggle, setToggle] = useState(false);
    const [searchMobile, setSearchMobile] = useState(false);
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showUserChange, setShowUserChange] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseModal = (val) => {
        setShowModal(val);
    };

    const handleCloseChange = (val) => {
        setShowUserChange(val);
    };

    const getHomePageHideNav = useSelector((state) => state.homePageHideNav);
    const getSearchQuery = useSelector((state) => state.searchQuery);
    const getisLogged = useSelector((state) => state.isLogged);
    const getAccInfo = useSelector((state) => state.account);
    const getCartInfo = useSelector((state) => state.cartInfo);

    const isDesktop = window.innerWidth > 992;

    const searchShop = (val) => {
        if(val.length >= 1) {
            dispatch(search(val))
            localStorage.setItem('searchQ', val)
            dispatch(searchQuery.actions.searchQuery(val))
        }
    }

    const onSubmitSearch = (e) => {
        e.preventDefault();

        // getSearchQuery != "" && 
        navigate("/pretraga");
    }

    const hideDropdown = () => {
        toggle && setToggle(false)
        // searchMobile && setSearchMobile(false)
    }

    const closeMenu = () => {
        if(toggle) {
            setSearchMobile(true)
            setToggle(false)
        } else {
            setSearchMobile(!searchMobile)
        }
    }

    const ref = useDetectClickOutside({ onTriggered: hideDropdown });

    useEffect(() => {
        if(sessionCookie == null) {
            dispatch(getSession())
        }
        dispatch(searchQuery.actions.searchQuery(""))
    }, [])

    useEffect(() => {
        if(sessionCookie !== null) {
            dispatch(getCart())
        }
    }, [sessionCookie])

    const userCookie = getCookie('isLogged');

    useEffect(() => {

        if(userCookie !== null && Object.keys(getAccInfo).length == 0 && getisLogged) {
            dispatch(getAccountInfo())
        }

        if(userCookie == null && getisLogged) {
            dispatch(logoutFunction(1))
        }
    }, [getisLogged, userCookie])

    return (
        <>
            <nav className={`navbar ${isDesktop ? "navbar-expand-lg" : ""} ${getHomePageHideNav ? "video-show-nav" : "video-hide-nav"}`}>
                <div className={`container-fluid ${isDesktop ? "navbar-container" : ""}`}>
                    <Link className="navbar-brand" to={"/"}>
                        <img src={`${isDesktop ? "/logo-dark.png" : "/logo.png"}`} alt="Small Brother Logo" className="img-fluid" />
                    </Link>
                    <div className="menu-container" ref={ref}>
                        <div className={`${toggle && !isDesktop ? "show-nav" : ""} ${!isDesktop ? "collapsable-navbar" : "desktop-navbar"}`}>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link" to={"/shop"} onClick={() => !isDesktop && setToggle(!toggle)}>
                                        Prodavnica
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={`/shop?naAkciji=true`} onClick={() => !isDesktop && setToggle(!toggle)}>Akcija</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={`/shop?kategorija=1`} onClick={() => !isDesktop && setToggle(!toggle)}>Lokalizacije</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/blog" onClick={() => !isDesktop && setToggle(!toggle)}>Blog</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/o-nama" onClick={() => !isDesktop && setToggle(!toggle)}>O nama</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="navbar-user">
                            <FontAwesomeIcon icon={faUser} className="navbar-icon" onClick={() =>
                                !getisLogged ?
                                    setShowModal(true)
                                    : setShowUserChange(true)
                            }
                            />
                            <div className="cart-icon-container">
                                <FontAwesomeIcon icon={faCartShopping} className="navbar-icon" onClick={handleShow} />
                                <span className="cart-size">{Object.keys(getCartInfo).length > 0 ? parseInt(getCartInfo.cartSize) : 0}</span>
                            </div>
                            {location.pathname !== "/pretraga" &&
                                <div className="search-container">
                                    {/*<form className={`${!isDesktop ? "mobile-search-form" : "search-form me-2"} 
                                                    ${!isDesktop && searchMobile ? "mobile-search-form-show" : ""}`}
                                        onSubmit={(e) => onSubmitSearch(e)}
                                    >
                                        <input type='text' className="search-box" autoComplete="off" placeholder='Pretraga...' onChange={(e) => searchShop(e.target.value)} />
                                    </form>*/}
                                    <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon"
                                        onClick={(e) => onSubmitSearch(e)}
                                    // !isDesktop ? closeMenu() : 
                                    />
                                </div>
                            }
                        </div>
                        {!isDesktop && <button className="navbar-toggler" type="button" onClick={() => setToggle(!toggle)}>
                            <span className="navbar-toggler-icon"></span>
                        </button>}
                    </div>
                </div>
            </nav>
            <CartSmall handleClose={handleClose} show={show} />
            {showModal &&
                <UserModal showModal={showModal} handleCloseModal={handleCloseModal} />
            }
            {showUserChange &&
                <UserChange showUserChange={showUserChange} handleCloseChange={handleCloseChange} />
            }
        </>
    )
}

export default Menu