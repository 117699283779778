import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CartItem from './CartItem'

import { getCart } from '../redux/backCalls';

const CartCheck = ({ getCartInfo, setTab, values, openMore, paymentOption, delivery }) => {

    const dispatch = useDispatch()

    const getShipping = useSelector((state) => state.shipping);
    const getLoyaltyOption = useSelector((state) => state.loyalty);
    const getOnlinePayment = useSelector((state) => state.onlinePayment);

    useEffect(() => {
        dispatch(getCart(getLoyaltyOption, getShipping, getOnlinePayment))
    }, [])

    return (
        <>
            <div className="row">
                <h1>Sadržaj korpe</h1>
            </div>
            <div className="cart-check">
                <div className="row payment-notice d-flex align-content-center p-1 mt-2 p-lg-3 mt-lg-3 ms-lg-1">
                    <p className="m-0">Ako želite da izmenite sadržaj korpe, vratite se na <span onClick={() => setTab(1)}>SADRŽAJ KORPE</span></p>
                </div>
                {Object.keys(getCartInfo).length > 0 ?
                    getCartInfo.cartProducts.map((item, key) => (
                        <CartItem item={item} key={key} />
                    ))
                    :
                    null
                }
            </div>
            <div className="row mt-3 mt-lg-5">
                <h1>Način plaćanja i dostava</h1>
            </div>
            <div className="cart-check">
                <div className="row payment-notice d-flex align-content-center p-1 mt-2 p-lg-3 mt-lg-3 ms-lg-1">
                    <p className="m-0">Ako želite izmeniti izabrani način plaćanja, vratite se na <span onClick={() => setTab(2)}>NAČIN PLAĆANJA</span></p>
                </div>
                <div className="row p-2 p-lg-4">
                    <div className="py-3 cart-check-data">
                        <p className="mb-1 fw-bold">Način plaćanja:</p>
                        <p className="mb-1">{paymentOption == "cardOption" ? "Platnom karticom" : "Plaćanje pouzećem"}</p>
                    </div>
                    <div className="py-3 cart-check-data">
                        <p className="mb-1 fw-bold">Dostava:</p>
                        <p className="mb-1">{delivery == "shop" ? "Preuzimanje u radnji" : "Slanje na kućnu adresu"}</p>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <h1>Podaci o kupcu</h1>
            </div>
            <div className="cart-check">
                <div className="row payment-notice d-flex align-content-center p-1 mt-2 p-lg-3 mt-lg-3 ms-lg-1">
                    <p className="m-0">Molimo, proverite još jednom da li ste ispravno upisali svoje podatke. U slučaju da želite izmeniti podatke o kupcu, vratite se na <span onClick={() => setTab(3)}>LIČNI PODACI</span></p>
                </div>
                {Object.keys(values).length > 0 ?
                    <div className="row p-lg-4">
                        {values.isLegalEntity &&
                            <div className="py-3 cart-check-data">
                                <p className="mb-1 fw-bold"> PIB firme:</p>
                                <p className="mb-1">{values.taxNumber}</p>
                            </div>
                        }
                        <div className="py-3 cart-check-data">
                            <p className="mb-1 fw-bold">Ime:</p>
                            <p className="mb-1">{values.name}</p>
                        </div>
                        <div className="py-3 cart-check-data">
                            <p className="mb-1 fw-bold">Prezime:</p>
                            <p className="mb-1">{values.surname}</p>
                        </div>
                        {delivery == "home" &&
                            <>
                                <div className="py-3 cart-check-data">
                                    <p className="mb-1 fw-bold"> Ulica i broj:</p>
                                    <p className="mb-1">{values.address}</p>
                                </div>
                                <div className="py-3 cart-check-data">
                                    <p className="mb-1 fw-bold">Grad:</p>
                                    <p className="mb-1">{values.city}</p>
                                </div>
                                <div className="py-3 cart-check-data">
                                    <p className="mb-1 fw-bold">Poštanski broj:</p>
                                    <p className="mb-1">{values.postCode}</p>
                                </div>
                            </>
                        }
                        <div className="py-3 cart-check-data">
                            <p className="mb-1 fw-bold">Telefon:</p>
                            <p className="mb-1">{values.phoneNumber}</p>
                        </div>
                        <div className="py-3 cart-check-data">
                            <p className="mb-1 fw-bold">E-mail adresa:</p>
                            <p className="mb-1">{values.email}</p>
                        </div>
                    </div>
                    :
                    null
                }
            </div>

        </>
    )
}

export default CartCheck