import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import { crudCategories, deleteCategories } from '../redux/backCalls';

const CategoriesModal = ({ showModal, modalContent, handleCloseModal, category }) => {

    const dispatch = useDispatch()
    const [values, setValues] = useState({
        name: category ? category.name : "",
        categoryId: category ? category.categoryId : 0
    })

    const onChangeFunction = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    const deleteCat = () => {
        dispatch(deleteCategories(category.categoryId))
        handleCloseModal(false)
    }

    const submitButton = (e) => {
        e.preventDefault();
        dispatch(crudCategories(values))
        handleCloseModal(false)
    }

    return (
        <Modal
            show={showModal}
            onHide={() => handleCloseModal(false)}
            keyboard={false}
            dialogClassName='modal-md'
            aria-labelledby=""
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="text-center">
                        {modalContent == "edit" ? "PROMENI KATEGORIJU" : modalContent == "delete" ? "OBRIŠI KATEGORIJU" : "KREIRAJ KATEGORIJU"}
                    </div>
                </Modal.Title>
                <FontAwesomeIcon icon={faXmark} onClick={() => handleCloseModal(false)} className="close-modal" />
            </Modal.Header>
            <Modal.Body>
                {modalContent == "delete" ?
                    <div className="text-center">
                        <p>Da li ste sigurni da želite da obrišete kategoriju?</p>
                        <div className="row d-flex justify-content-center mt-2">
                            <button type='button' className="btn btn-sm btn-success col-5 m-2" onClick={() => deleteCat()}>Da</button>
                            <button type='button' className="btn btn-sm btn-danger col-5 m-2" onClick={() => handleCloseModal(false)}>Ne</button>
                        </div>
                    </div>
                    :
                    <div className="row d-flex justify-content-center">
                        <form className="col-sm-12" noValidate onSubmit={submitButton}>
                            <label htmlFor="name" className="form-label">Naziv kategorije:</label>
                            <Form.Control
                                name='name'
                                type='text'
                                id="name"
                                value={values['name']}
                                onChange={(e) => onChangeFunction(e)}
                            />
                            <div className="mt-2 text-center">
                                <button type='submit' className="btn btn-sm btn-success col-5 m-2" onClick={(e) => submitButton(e)}>
                                    {modalContent == 'edit' ? "PROMENI" : modalContent == "create" && "KREIRAJ"}
                                </button>
                                <button type='button' className="btn btn-sm btn-danger col-5 m-2" onClick={() => handleCloseModal(false)}>ODUSTANI</button>
                            </div>
                        </form>
                    </div>
                }
            </Modal.Body>
        </Modal>
    )
}

export default CategoriesModal