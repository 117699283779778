import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';

import { onlinePayment } from '../redux/slices';

const CartPayment = ({ paymentOption, setOnlinePayment, delivery, setShipping }) => {

    const dispatch = useDispatch()

    useEffect(() => {
        if (paymentOption == "cardOption") {
            dispatch(onlinePayment.actions.onlinePayment(true))
        } else {
            dispatch(onlinePayment.actions.onlinePayment(false))
        }
    }, [])

    return (
        <>
            <div className="row">
                <h1>NAČIN PLAĆANJA</h1>
                <div className="col-sm-12 payment-notice d-flex align-content-center p-3 mt-3">
                    Molimo izaberite željeni način plaćanja.
                </div>
                <div className="payment-options mt-4" style={{ position: 'relative', display: 'inline-block' }}>
                    <div className="col-sm-12 payment-option p-3">
                        <div className="form-check payment-check">
                            <input className="form-check-input me-4" type="radio" name="cardOption" id="cardOption" checked={paymentOption == "cardOption" ? true : false} onChange={(e) => setOnlinePayment(e.target.name)} />
                            <label className="form-check-label" htmlFor="cardOption">
                                <span>Platnom karticom</span> <br />
                                <small>Siguran način kupovine – platite karticama, Visa, Mastercard ili Maestro</small>
                            </label>
                        </div>
                        <div className='ms-5 mt-2 bank-branding-holder'>
                            <div className='bank-branding mx-1'>
                                <img src='/Visa.jpg' alt='Visa' title='Visa' loading='lazy' />
                            </div>
                            <div className='bank-branding mx-1'>
                                <img src='/MasterCard.png' alt='MasterCard' title='MasterCard' loading='lazy' />
                            </div>
                            <div className='bank-branding mx-1'>
                                <img src='/Maestro.png' alt='Maestro' title='Maestro' loading='lazy' />
                            </div>
                            <div className='bank-branding mx-1'>
                                <img src='/DinaCard.jpg' alt='DinaCard' title='DinaCard' loading='lazy' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 p-3">
                        <div className="form-check payment-check">
                            <input className="form-check-input me-4" type="radio" name="cashOption" id="cashOption" checked={paymentOption == "cashOption" ? true : false} onChange={(e) => setOnlinePayment(e.target.name)} />
                            <label className="form-check-label" htmlFor="cashOption">
                                <span>Plaćanje pouzećem</span> <br />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <h1>PREUZIMANJE</h1>
                <div className="col-sm-12 payment-notice d-flex align-content-center p-3 mt-3">
                    Molimo izaberite željeni način preuzimanja proizvoda.
                </div>
                <div className="payment-options mt-4">
                    <div className="col-sm-12 payment-option p-3">
                        <div className="form-check payment-check">
                            <input className="form-check-input me-4" type="radio" name="shop" id="shop" checked={delivery == "shop" ? true : false} onChange={(e) => setShipping(e.target.name)} />
                            <label className="form-check-label" htmlFor="shop">
                                <span>Preuzimanje u radnji</span> <br />
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-12 p-3">
                        <div className="form-check payment-check">
                            <input className="form-check-input me-4" type="radio" name="home" id="home" checked={delivery == "home" ? true : false} onChange={(e) => setShipping(e.target.name)} />
                            <label className="form-check-label" htmlFor="home">
                                <span>Slanje na kućnu adresu</span> <br />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CartPayment