import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faCircle } from '@fortawesome/free-solid-svg-icons'
import FormValidation from './FormValidation';

import { changeAccountInfo, changePassword, logoutFunction, getAccountInfo } from '../redux/backCalls';

const UserChange = ({ showUserChange, handleCloseChange }) => {

    const dispatch = useDispatch()
    const getAccInfo = useSelector((state) => state.account);

    const [modalContent, setModalContent] = useState("accountInfo")
    const [validPassword, setValidPassword] = useState(0)
    const [values, setValues] = useState({})
    const [validInputValue, setValidInputValue] = useState({})
    const [errors, setErrors] = useState({})

    const setErrorsFunction = (name, val) => {
        setErrors({ ...errors, [name]: val });
    };

    const onPasswordChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });

        if (e.target.name == "repeatNewPassword") {
            if (e.target.value !== values.newPassword) {
                setValidPassword(1)
            } else {
                setValidPassword(2)
            }
        } else if (e.target.name == "newPassword" && values.repeatNewPassword != undefined) {
            if (e.target.value !== values.repeatNewPassword) {
                setValidPassword(1)
            } else {
                setValidPassword(2)
            }
        }
    }

    const onChangeInfo = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    const submitAccountInfo = () => {
        dispatch(changeAccountInfo(values)).then(res => {
            res && setTimeout(() => {
                dispatch(getAccountInfo())
                handleCloseChange(false)
            }, 1000)
        })
    }

    const passwordChangeConfirm = () => {
        if (values['password'] === undefined) {
            setValidInputValue({
                password: 1
            })
        } else if (values['newPassword'] === values['repeatNewPassword'] && values['newPassword'] != undefined) {
            dispatch(changePassword(values['password'], values['newPassword'])).then(res => {
                res && setTimeout(() => {
                    handleCloseChange(false)
                }, 1000)
            })
        } else {
            setValidPassword(1);
        }
    }

    const signoutFunction = () => {
        dispatch(logoutFunction())
        handleCloseChange(false)
    }

    useEffect(() => {
        if (modalContent == "accountInfo") {
            setValues({
                name: getAccInfo.name,
                surname: getAccInfo.surname,
                phoneNumber: getAccInfo.phoneNumber,
                country: getAccInfo.country,
                city: getAccInfo.city,
                postCode: getAccInfo.postCode,
                address: getAccInfo.address,
            })
        }

        if (modalContent == "passwordChange") {
            setValidInputValue({
                password: 0
            })
        }
    }, [modalContent])

    return (

        <Modal
            show={showUserChange}
            onHide={() => handleCloseChange(false)}
            keyboard={false}
            dialogClassName='modal-md'
            aria-labelledby=""
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="text-center">
                        {modalContent == "accountInfo" ? "INFORMACIJE NALOGA" : modalContent == "passwordChange" ? "PROMENA LOZINKE" : ""}
                    </div>
                </Modal.Title>
                <FontAwesomeIcon icon={faXmark} onClick={() => handleCloseChange(false)} className="close-modal" />
            </Modal.Header>
            <Modal.Body>
                <div className="row d-flex justify-content-center">
                    <form className="col-sm-12" noValidate onSubmit={submitAccountInfo}>
                        {modalContent == "accountInfo" &&
                            <>
                                <div className="row p-1">
                                    <div className="col-sm-6">
                                        <label htmlFor="name" className="form-label fw-bold">Ime:</label>
                                        <Form.Control
                                            name='name'
                                            type='text'
                                            id="name"
                                            value={values['name']}
                                            onChange={(e) => onChangeInfo(e)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="surname" className="form-label fw-bold">Prezime:</label>
                                        <Form.Control
                                            name='surname'
                                            type='text'
                                            id="surname"
                                            value={values['surname']}
                                            onChange={(e) => onChangeInfo(e)}
                                        />
                                    </div>
                                </div>
                                <label htmlFor="phoneNumber" className="form-label fw-bold p-1">Telefon:</label>
                                <Form.Control
                                    name='phoneNumber'
                                    type='number'
                                    id="phoneNumber"
                                    value={values['phoneNumber']}
                                    className="p-1"
                                    onChange={(e) => onChangeInfo(e)}
                                />
                                <label htmlFor="country" className="form-label fw-bold p-1">Država:</label>
                                <Form.Control
                                    name='country'
                                    type='text'
                                    id="country"
                                    value={values['country']}
                                    className="p-1"
                                    onChange={(e) => onChangeInfo(e)}
                                />
                                <div className="row p-1">
                                    <div className="col-sm-8">
                                        <label htmlFor="city" className="form-label fw-bold">Grad:</label>
                                        <Form.Control
                                            name='city'
                                            type='text'
                                            id="city"
                                            value={values['city']}
                                            onChange={(e) => onChangeInfo(e)}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <label htmlFor="postCode" className="form-label fw-bold">Poštanski broj:</label>
                                        <Form.Control
                                            name='postCode'
                                            type='number'
                                            id="postCode"
                                            value={values['postCode']}
                                            onChange={(e) => onChangeInfo(e)}
                                        />
                                    </div>
                                </div>
                                <label htmlFor="address" className="form-label p-1 fw-bold">Ulica i broj:</label>
                                <Form.Control
                                    name='address'
                                    type='text'
                                    id="address"
                                    value={values['address']}
                                    className="p-1"
                                    onChange={(e) => onChangeInfo(e)}
                                />
                                <div className="catalog-product-loyalty user-loyalty-info px-0">
                                    <span className='loyalty-info-span m-2'>Loyalty poeni:</span>
                                    <FontAwesomeIcon className="catalog-product-loyalty-num p-0" icon={faCircle} />
                                    <span className="catalog-product-loyalty-num">{getAccInfo.loyaltyPoints}</span>
                                </div>
                            </>
                        }
                        {modalContent == "passwordChange" ?
                            <>
                                <div className="p-1">
                                    <label htmlFor="password" className="form-label fw-bold">*Stara lozinka:</label>
                                    <FormValidation
                                        name='password'
                                        type='password'
                                        id="password"
                                        value={values['password']}
                                        onChange={(e) => onPasswordChange(e)}
                                        validInputValue={validInputValue.password}
                                        setErrorsFunction={setErrorsFunction}
                                    />
                                </div>
                                <div className="p-1">
                                    <label htmlFor="newPassword" className="form-label fw-bold">*Nova lozinku:</label>
                                    <Form.Control
                                        name='newPassword'
                                        type='password'
                                        id="newPassword"
                                        value={values['newPassword']}
                                        onChange={(e) => onPasswordChange(e)}
                                        isInvalid={validPassword == 1}
                                    />
                                </div>
                                <div className="p-1">
                                    <label htmlFor="repeatNewPassword" className="form-label fw-bold">*Ponovite novu lozinku:</label>
                                    <Form.Control
                                        name='repeatNewPassword'
                                        type='password'
                                        id="repeatNewPassword"
                                        value={values['repeatNewPassword']}
                                        onChange={(e) => onPasswordChange(e)}
                                        isInvalid={validPassword == 1}
                                    />
                                </div>
                            </>
                            :
                            null
                        }
                    </form>
                    {modalContent == 'accountInfo' &&
                        <>
                            <div className="col-sm-12 mt-2 p-3">
                                <button type='submit' className="btn btn-dark login-button" onClick={() => submitAccountInfo()}>
                                    PROMENA PODATKA
                                </button>
                            </div>
                            <div className="col-sm-12 mt-2 p-3">
                                <button type='submit' className="btn btn-dark login-button" onClick={() => setModalContent("passwordChange")}>
                                    PROMENI SIFRU
                                </button>
                            </div>
                            <div className="col-sm-12 p-3 text-center">
                                <button type='button' className="btn login-button registration-button mt-2" onClick={() => signoutFunction()}>
                                    LOGOUT
                                </button>
                            </div>
                        </>
                    }
                    {modalContent == 'passwordChange' &&
                        <div className="col-sm-12 mt-2 p-3">
                            <button type='submit' className="btn btn-dark login-button" onClick={() => passwordChangeConfirm()}>
                                PROMENI SIFRU
                            </button>
                        </div>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default UserChange