import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';

const DeliveryPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="container">
            <Helmet>
                <title>Dostava</title>
                <meta name='description' content='Dostava igara do vaših vrata! Uživajte u bezbrižnom iskustvu kupovine društvenih igara uz našu pouzdanu uslugu dostave.
                Brza, sigurna i efikasna, naša dostava je osmišljena kako bi vam omogućila da se brzo prepustite radosti novih igara, udobno iz udobnosti svog doma.'></meta>
            </Helmet>
            <div className="row my-4">
                <h1>Isporuka</h1>
            </div>
            <div className="row">
                <p>
                    Isporuka vršimo na teritoriji Republike Srbije. Isporuka se vrši preko
                    kurirskih servisa Dexpress i Post Express. Pošta naplaćuje proviziju za uplatu novčanih
                    sredstava koje plaćate prilikom preuzimanja pošiljke shodno njihovom tekućem
                    cenovniku.
                </p>
                <p>
                    Isporuku i prodaju vršimo samo na teritoriji Srbije. Roba se dostavlja na adresu koju ste
                    naveli kod unosa Vaših podataka u procesu online kupovine.
                </p>
                <p>
                    Ukoliko ste se odlučili za plaćanje robe pouzećem, robu plaćate gotovinom kuriru na licu
                    mesta.<br />
                    Cena dostave robe (do 5kg) iznosi 350,00 dinara ukoliko vrednost porudžbine ne prelazi
                    iznos od 7.000,00 dinara. Za vrednosti porudžbina preko tog iznosa troškovi dostave se
                    ne naplaćuju.
                </p>
                <p className="mt-5">
                    Transport i bezbednost
                </p>
                <p>
                    Prozvodi se isporučuju na željenu adresu adekvatno upakovani i zaštićeni od oštećenja
                    u transportu. Svaki proizvod se nalazi u originalnoj ambalaži sa pripadajućim domaćim i
                    internacionalnim garancijama.<br />
                    U slučaju da Vaša porudžbina kasni ili da primite oštećeno pakovanje ili proizvod, Vaša
                    je obaveza je da nas odmah obavestite. Ukoliko to ne učinite istog dana kada ste
                    pošiljku preuzeli ne snosimo odgovornost za nastala oštećenja i štetu.
                </p>
                <p className="mt-5">
                    Termin isporuke
                </p>
                <p>
                    Očekivani rok isporuke raspoloživih proizvoda je jedan do četiri radna dana od trenutka
                    potvrde prudžbine. Isporuke se ne vrše vikendima i Državnim praznicima.
                    Za već ugovoreni rok isporuke, isporuka može biti odložena samo u slučaju
                    nepredviđenih okolnosti koje nastupe kod naših partnera. Small Brother d.o.o. će učiniti
                    sve da ih prevaziđe na najbolji mogući način za sve.
                </p>
                <p className="mt-5">
                    Isporuka na prodajnom mestu
                </p>
                <p>
                    Ukoliko kupac prilikom kreiranja porudžbine naznači da robu želi preuzeti lično, to može
                    učiniti u našem maloprodajnom objektu na adresi Prizrenska 4a, 11000 Beograd.
                </p>
            </div>
        </div>
    )
}

export default DeliveryPage