import React from 'react'
import { useSelector } from 'react-redux';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';

import CartItem from './CartItem';

const CartSmall = ({ handleClose, show }) => {
    const getCartInfo = useSelector((state) => state.cartInfo);

    return (
        <Offcanvas show={show} onHide={handleClose} placement='end' style={{ padding: "0 10px" }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Korpa</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {Object.keys(getCartInfo).length > 0 && getCartInfo.cartProducts.length > 0 ?
                    <>
                        {getCartInfo.cartProducts.map((item, key) => (
                            <CartItem item={item} key={key} />
                        ))}
                        <div className="row price-sum">
                            {/* <div className="price-sum-item">
                        Popust: <span>{getCartInfo.discountPrice} RSD</span>
                    </div> */}
                            <div className="price-sum-item" style={{ fontWeight: "700" }}>
                                UKUPNO: <span>{getCartInfo.paymentPrice} RSD</span>
                            </div>
                        </div>
                        <Link className="cart-link" to={"/cart"} onClick={handleClose}>
                            <div className="cart-button">
                                <p>Moja korpa</p>
                            </div>
                        </Link>
                    </>
                    :
                    <h3>Korpa je prazna!</h3>
                }
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default CartSmall