import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const PaginationMui = ({ currentPage, setCurrentPage }) => {
    const getPages = useSelector((state) => state.pages);
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

    const [page, setPage] = useState(currentPage ? parseInt(currentPage) + 1 : 1);

    const handleChange = (event, value) => {
        setCurrentPage(value - 1);
        setPage(value)

        if (value == 0) {
            searchParams.delete('str');
        } else {
            searchParams.set('str', value - 1);
        }

        navigate({ search: `?${searchParams.toString()}` })
    };

    useEffect(() => {
        setPage(currentPage ? parseInt(currentPage) + 1 : 1)
    }, [currentPage])

    return (
        <div className="pagination-custom mt-3">
            <Stack spacing={2}>
                <Pagination count={parseInt(getPages)} shape="rounded" page={page} onChange={handleChange} />
            </Stack>
        </div>
    )
}

export default PaginationMui