import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { uploadFile, crudBlogs, deleteBlogs } from '../redux/backCalls';

const BlogsModal = ({ showModal, modalContent, handleCloseModal, blog }) => {

    const dispatch = useDispatch()
    const [values, setValues] = useState({
        blogId: Object.keys(blog).length > 0 ? blog.blogId : 0,
        name: Object.keys(blog).length > 0 ? blog.name : "",
        banner: Object.keys(blog).length > 0 ? blog.banner : "",
        image: Object.keys(blog).length > 0 ? blog.image : "",
    })
    const [showMoreContent, setShowMoreContent] = useState(Object.keys(blog).length > 0 && blog.blogContents.length > 0 ? blog.blogContents.map(i => i.type) : [])
    const [blogContImg, setBlogContImg] = useState(Object.keys(blog).length > 0 && blog.blogContents.length > 0 ? blog.blogContents.map(v => v.type == 2 ? v.value : null) : [])
    const [textValues, setTextValues] = useState(Object.keys(blog).length > 0 && blog.blogContents.length > 0 ? blog.blogContents.map(v => v.type == 1 ? v.value : '') : []);

    const banPart = values.banner.split('/');
    const bannerName = banPart[banPart.length - 1];

    const imgPart = values.image.split('/');
    const imageName = imgPart[imgPart.length - 1];

    const onChangeFunction = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    const handleFileInput = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        const formData = new FormData();
        Object.values(e.target.files).map(i => {
            formData.append('file', i);
        })

        dispatch(uploadFile(formData))
            .then(res => {
                if(e.target.name != "value") {
                    setValues({ ...values, [e.target.name]: res.toString() })
                } else {
                    const newFileValues = [...blogContImg];
                    newFileValues[index] = res.toString();
                    setBlogContImg(newFileValues);
                }
            })
    }

    const resetFileValue = (e) => {
        e.target.value = null
    }

    const handleTextChange = (event, editor, index) => {
        const newTextValues = [...textValues];
        newTextValues[index] = editor.getData();
        setTextValues(newTextValues);
    };

    const showMore = (val) => {
        setShowMoreContent(prevValues => [...prevValues, val])
        setTextValues(prevValues => [...prevValues, '']);
        setBlogContImg(prevValues => [...prevValues, null]);
    }

    const showLess = (index) => {
        setShowMoreContent((prevValues) => prevValues.filter((value, k) => k !== index));
        setBlogContImg((prevValues) => prevValues.filter((value, k) => k !== index));
        setTextValues(prevValues => prevValues.filter((_, k) => k !== index));
    }

    const submitButton = (e) => {
        e.preventDefault();
        const objects = [];

        showMoreContent.forEach((element, key) => {
            let obj = new Object()
            if(element == 2) {
                obj.value = blogContImg.filter((i, k) => k == key)[0]
                obj.type = 2
            } else {
                obj.value = textValues.filter((i, k) => k == key)[0]
                obj.type = 1
            }
            objects.push(obj);
        });

        dispatch(crudBlogs(values, objects))
        handleCloseModal(false)
    }

    const deleteBlog = () => {
        dispatch(deleteBlogs(blog.blogId))
        handleCloseModal(false)
    }

    return (
        <Modal
            show={showModal}
            onHide={() => handleCloseModal(false)}
            keyboard={false}
            dialogClassName={modalContent == "delete" ? 'modal-md' : 'modal-lg'}
            aria-labelledby=""
            centered
            enforceFocus={false}
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="text-center">
                        {modalContent == "edit" ? "PROMENI BLOG" : modalContent == "delete" ? "OBRIŠI BLOG" : "KREIRAJ BLOG"}
                    </div>
                </Modal.Title>
                <FontAwesomeIcon icon={faXmark} onClick={() => handleCloseModal(false)} className="close-modal" />
            </Modal.Header>
            <Modal.Body>
                {modalContent == "delete" ?
                    <div className="text-center">
                        <p>Da li ste sigurni da želite da obrišete blog?</p>
                        <div className="row d-flex justify-content-center mt-2">
                            <button type='button' className="btn btn-sm btn-success col-5 m-2" onClick={() => deleteBlog()}>Da</button>
                            <button type='button' className="btn btn-sm btn-danger col-5 m-2" onClick={() => handleCloseModal(false)}>Ne</button>
                        </div>
                    </div>
                    :
                    <div className="row d-flex justify-content-center">
                        <form className="col-sm-12" noValidate onSubmit={submitButton}>
                            <Form.Group className="p-2">
                                <Form.Label>Naziv bloga:</Form.Label>
                                <Form.Control
                                    name='name'
                                    type='text'
                                    value={values['name']}
                                    onChange={(e) => onChangeFunction(e)}
                                />
                            </Form.Group>
                            <Form.Group className="p-2">
                                <Form.Label>Baner:</Form.Label>
                                <Form.Control
                                    name='banner'
                                    type='file'
                                    onChange={(e) => handleFileInput(e)}
                                    onClick={resetFileValue}
                                />
                            </Form.Group>
                            {values.banner != "" &&
                                <span className='m-2'>{bannerName}</span>
                            }
                            <Form.Group className="p-2">
                                <Form.Label>Slika:</Form.Label>
                                <Form.Control
                                    name='image'
                                    type='file'
                                    onChange={(e) => handleFileInput(e)}
                                    onClick={resetFileValue}
                                />
                            </Form.Group>
                            {values.image != "" &&
                                <span className='m-2'>{imageName}</span>
                            }
                            <p className='p-2 m-0 mt-2'>Sadržaj bloga:</p>
                            {showMoreContent.map((i, k) => {
                                const imgPart = blogContImg.length > 0 && blogContImg[k] != null && blogContImg[k].split('/');
                                const imageName = imgPart[imgPart.length - 1];
                                return <div className="d-flex ps-4 p-2" key={k}>
                                    {i == 1 ?
                                        <>
                                            <Form.Group className="col-10">
                                                <Form.Label>Tekst:</Form.Label>
                                                {/* <Form.Control
                                                    as="textarea"
                                                    rows={5}
                                                    id="text"
                                                    value={textValues[k] || ''}
                                                    onChange={(e) => handleTextChange(e, k)}
                                                /> */}
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data={textValues[k]}
                                                    onChange={(event, editor) => handleTextChange(event, editor, k)}
                                                />
                                            </Form.Group>
                                            <div className="col-2 d-flex justify-content-center align-items-center admin-minus-holder">
                                                <FontAwesomeIcon icon={faMinus} onClick={() => showLess(k)} className="admin-minus" />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <Form.Group className="col-10">
                                                <Form.Label>Slika:</Form.Label>
                                                <Form.Control
                                                    name='value'
                                                    type='file'
                                                    id="img"
                                                    className="mb-2"
                                                    onChange={(e) => handleFileInput(e, k)}
                                                />
                                                {blogContImg.length > 0 && blogContImg[k] != null &&
                                                    <span className='m-2'>Izabrana slika: {imageName}</span>
                                                }
                                            </Form.Group>
                                            <div className="col-2 d-flex justify-content-center align-items-center admin-minus-holder">
                                                <FontAwesomeIcon icon={faMinus} onClick={() => showLess(k)} className="admin-minus" />
                                            </div>
                                        </>
                                    }
                                </div>
                            })}
                            <div className='d-flex align-items-center p-2'>
                                <div className="p-2" style={{ cursor: "pointer" }} onClick={() => showMore(1)}>
                                    <FontAwesomeIcon icon={faPlus} className="me-2" />Dodaj tekst
                                </div>
                                <div className="p-2" style={{ cursor: "pointer" }} onClick={() => showMore(2)}>
                                    <FontAwesomeIcon icon={faPlus} className="me-2" />Dodaj sliku
                                </div>
                            </div>
                            <div className="mt-2 text-center">
                                <button type='submit' className="btn btn-sm btn-success col-5 m-2" onClick={(e) => submitButton(e)}>
                                    {modalContent == 'edit' ? "PROMENI" : modalContent == "create" && "KREIRAJ"}
                                </button>
                                <button type='button' className="btn btn-sm btn-danger col-5 m-2" onClick={() => handleCloseModal(false)}>ODUSTANI</button>
                            </div>
                        </form>
                    </div>
                }
            </Modal.Body>
        </Modal>
    )
}

export default BlogsModal