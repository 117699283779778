import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPercent } from '@fortawesome/free-solid-svg-icons'

const CatalogBadges = ({ item }) => {
    return (
        <div className="badges-holder">
            <div className={`badge-item ${item.isNew ? "new-badge" : item.isOnSale ? "sale-badge" : item.isSoon && "soon-badge"}`}>
                {item.isOnSale ?
                    <>
                        <FontAwesomeIcon icon={faPercent} className="percentage-icon" />AKCIJA! -{item.saleProcent} %
                    </>
                    : item.isNew ?
                        "NOVO!"
                        : item.isSoon &&
                        "USKORO!"
                }
            </div>
        </div>
    )
}

export default CatalogBadges