import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const CatalogSort = ({ sort, setSort, sortDirection, setSortDirection, setCurrentPage }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

    const onChangeFunction = (val) => {
        if (val.includes(",")) {
            let direction = val.split(",")[1]
            let type = val.split(",")[0]

            searchParams.set('sort', type);
            searchParams.set('sortDir', direction);
            setSort(type)
            setSortDirection(direction)
        } else {
            if (val == "isForPromotion") {
                searchParams.delete('sort');
            } else {
                searchParams.set('sort', val);
            }
            searchParams.delete('sortDir');
            setSort(val)
            setSortDirection("")
        }
        searchParams.delete('str');
        navigate({ search: `?${searchParams.toString()}` })

        setCurrentPage(0)
    }

    return (
        <div className="sort-holder">
            <span>Poređaj po:</span>
            <select className="form-select form-select-sm show-articles"
                value={sortDirection != "" ? sort + "," + sortDirection : sort}
                onChange={(e) => onChangeFunction(e.target.value)}
            >
                <option value={"isForPromotion"}>Osnovni prikaz</option>
                <option value={"productId"}>Najnovije</option>
                <option value={"price,ASC"}>Cena rastuće</option>
                <option value={"price,DESC"}>Cena opadajuće</option>
                <option value={"name,ASC"}>Naziv (A - Š)</option>
                <option value={"name,DESC"}>Naziv (Š - A)</option>
            </select>
        </div>
    )
}

export default CatalogSort