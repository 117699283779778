import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';

const FormValidation = (props) => {

    const {
        label,
        placeholder,
        value,
        onChange,
        validInputValue,
        errorMessage,
        setErrorsFunction,
        name,
        type,
        nameClass,
        options,
        disable,
        maxlength,
        modalContent
    } = props;

    const [validInput, setValidInput] = useState(0); //0-nista se ne desava, 1-greska, 2-sve ok
    // const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        setValidInput(validInputValue);
        // setErrorMsg(errorMessage);
    }, [validInputValue])

    useEffect(() => {
        if (modalContent) {
            setValidInput(0)
        }
    }, [modalContent])

    const handleFocus = () => {

        switch (type) {
            case "email":
                if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                    setValidInput(1);
                    // setErrorMsg("*E-mail adresa nije validna!")
                    setErrorsFunction(name, 1)
                } else {
                    setValidInput(2);
                    setErrorsFunction(name, 0)
                }
                break;
            case "text":
                if (!value || !value.replace(/\s/g, '').length) {
                    setValidInput(1);
                    // setErrorMsg("*Obavezno polje!")
                    setErrorsFunction(name, 1)
                } else {
                    setValidInput(2);
                    setErrorsFunction(name, 0)
                }
                break;
            case "password":
                if (!value || !value.replace(/\s/g, '').length) {
                    setValidInput(1);
                    // setErrorMsg("*Obavezno polje!")
                    setErrorsFunction(name, 1)
                } else {
                    setValidInput(2);
                    setErrorsFunction(name, 0)
                }
                break;
            // case "textarea":
            //     if (!value) {
            //         setValidInput(1);
            //         setErrorsFunc(name, 1);
            //     } else {
            //         setValidInput(2);
            //         timeoutFunction();
            //         setErrorsFunc(name, 0);
            //     }
            //     break;
            // case "select":
            //     if (!value) {
            //         setValidInput(1);
            //         setErrorsFunc(name, 1);
            //     } else {
            //         setValidInput(2);
            //         timeoutFunction();
            //         setErrorsFunc(name, 0);
            //     }
            //     break;
            case "number":
                if (!value) {
                    setValidInput(1);
                    // setErrorMsg("*Obavezno polje!")
                    setErrorsFunction(name, 1)
                } else if (name == "taxNumber" && value.length != 9) {
                    setValidInput(1);
                    // setErrorMsg("*Potrebno je uneti tačno 9 cifara!")
                    setErrorsFunction(name, 1)
                } else if (name == "company_id" && value.length != 8) {
                    setValidInput(1);
                    // setErrorMsg("*Potrebno je uneti tačno 8 cifara!")
                    setErrorsFunction(name, 1)
                } else {
                    setValidInput(2);
                    setErrorsFunction(name, 0)
                }
                break;
            default:
                if (value != '') {
                    setValidInput(2);
                }
                break;
        }
    };

    return (
        <div className="p-1">
            {label &&
                <label htmlFor={name} className={`form-label ${nameClass ? nameClass : ""}`}>{label}</label>
            }
            {type != "textarea" && type != "select" &&
                <>
                    <Form.Control
                        // size="sm"
                        type={type}
                        id={name}
                        name={name}
                        isInvalid={validInput == 1}
                        // isValid={validInput == 2}
                        value={value}
                        onChange={onChange}
                        onBlur={handleFocus}
                    />
                    {/* {validInput == 1 &&
                        <div className="invalid-feedback d-block">
                            {errorMsg}
                        </div>
                    } */}
                </>
            }
        </div>
    )
}

export default FormValidation