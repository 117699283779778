import React, { useEffect, useState } from 'react'
import CatalogProduct from './CatalogProduct';

const ProductDescription = ({ getProductInfo }) => {

    const [tab, setTab] = useState("description");

    const changeTab = (val) => {
        setTab(val)
    }
    return (
        <>
            <div className="row">
                <div className="col-sm-12 tabs-container">
                    <div className={`${tab == "description" ? "tab-links-active" : "tab-links-not-active"} tab-links`}>
                        <div className="tab-link" onClick={() => changeTab("description")}>
                            OPIS PROIZVODA
                        </div>
                    </div>
                    <div className={`${tab == "declaration" ? "tab-links-active" : "tab-links-not-active"} tab-links`}>
                        <div className="tab-link" onClick={() => changeTab("declaration")}>
                            DEKLARACIJA
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="product-page-desc">
                        {tab == "description" &&
                            <p dangerouslySetInnerHTML={{ __html: getProductInfo.description }} />
                        }
                        {tab == "declaration" &&
                            <p dangerouslySetInnerHTML={{ __html: getProductInfo.declaration }} />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductDescription